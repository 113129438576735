import { type ReactNode, createContext, type ReactElement } from 'react';

import { TabProvider } from '../Common/Tabs/TabProvider';
import { CommonList } from '../Common/Tabs/CommonList';
import { type Padding, type Font } from '../Common/Tabs/sharedTypes';

type Props<T extends string> = {
    activeTab: T;
    animate?: boolean;
    setActiveTab: (activeTab: T) => void;
    container?: 'nav' | 'div';
    underline?: boolean;
    indicatorPadding?: Padding;
    indicatorHeight?: 'none' | '1px' | '2px';
    activeFont?: Font;
    inactiveFont?: Font;
    contrast?: boolean;
    align?: 'center' | 'left';
    fullWidth?: boolean;
    overflowContainer?: boolean;
    children: ReactNode;
};

export const NavTabConfigContext = createContext<{
    activeFont?: Font;
    inactiveFont?: Font;
    contrast?: boolean;
}>({
    activeFont: 'mediumBodyHeavy',
    inactiveFont: 'mediumBody',
    contrast: false,
});

export const NavTabList = <T extends string>({
    animate = true,
    children,
    container = 'nav',
    activeTab,
    setActiveTab,
    indicatorPadding,
    indicatorHeight,
    underline,
    activeFont,
    inactiveFont,
    contrast,
    align = 'left',
    fullWidth,
    overflowContainer,
}: Props<T>): ReactElement => (
    <NavTabConfigContext.Provider value={{ activeFont, inactiveFont, contrast }}>
        <TabProvider activeTab={activeTab} setActiveTab={setActiveTab}>
            <CommonList
                fullWidth={fullWidth}
                container={container}
                underline={underline}
                indicatorPadding={indicatorPadding}
                indicatorHeight={indicatorHeight}
                animate={animate}
                align={align}
                overflowContainer={overflowContainer}
            >
                {children}
            </CommonList>
        </TabProvider>
    </NavTabConfigContext.Provider>
);
