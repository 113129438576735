// Flow types automatically removed

import { Component } from 'react';
import { IntlContext } from 'dibs-react-intl';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import HeaderRoot from './HeaderRoot';
import OverviewRoot from './OverviewRoot';
import { ProjectsRoot } from './ProjectsRoot';
import { FavoritesRoot } from './FavoritesRoot';
import { ProfileRootQuery } from './ProfileRootQuery';
import { logError } from '../helpers';
import { trackPageView } from '../shared/helpers/tracking';
import { PAGE_TYPE_OVERVIEW, PAGE_TYPE_PROJECTS, PAGE_TYPE_FAVORITES } from './constants';
import styles from './DesignProfileWrapper.scss';
export class DesignProfileWrapperComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.initialPage,
            shouldRenderOverview: props.initialPage === PAGE_TYPE_OVERVIEW,
            shouldRenderProjects: props.initialPage === PAGE_TYPE_PROJECTS,
        };
    }

    componentDidMount() {
        // After mounting, refetch to get all of the data immediately vs. on-demand for faster tab nav
        const outputVars = {
            firmUrlLabel: this.props.firmUrlLabel,
            isEditMode: this.props.isEditMode,
            shouldRenderOverview: true,
            shouldRenderProjects: true,
        };
        window.onpopstate = this.handlePopState;

        if (this.props.relay) {
            this.props.relay.refetch(outputVars, undefined, e => {
                if (e) {
                    logError(e);
                }

                this.setState({
                    shouldRenderOverview: true,
                    shouldRenderProjects: true,
                });
            });
        }

        trackPageView();
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    static contextType = IntlContext;
    onChangePage = pageType => {
        const intl = this.context;

        if (this.state.currentPage !== pageType) {
            this.setState({
                currentPage: pageType,
            });

            // edit mode should keep the same url, regardless of active tab, and should not track vpvs
            if (!this.props.isEditMode) {
                window.history.pushState(
                    {},
                    null,
                    handleLocaleUrl(
                        `/design-firms/${this.props.firmUrlLabel}/${
                            pageType !== PAGE_TYPE_OVERVIEW ? `${pageType}/` : ''
                        }`,
                        intl.locale
                    )
                );
                trackPageView();
            }
        }
    };
    getCurrentPageType = () => {
        const path = location.pathname;

        // regex used here is for getting last segment of pathname
        // it gets string value before or after (if pathname does not have ending "/") last "/"
        const pageType = (path.match(new RegExp('([^/]*)/*$')) || [])[1];

        switch (pageType) {
            case PAGE_TYPE_PROJECTS:
                return PAGE_TYPE_PROJECTS;

            case PAGE_TYPE_FAVORITES:
                return PAGE_TYPE_FAVORITES;

            default:
                return PAGE_TYPE_OVERVIEW;
        }
    };
    handlePopState = () => {
        const pageType = this.getCurrentPageType();
        this.setState({
            currentPage: pageType,
        });
    };

    render() {
        let pageElement = null; // TODO error display

        switch (this.state.currentPage) {
            case PAGE_TYPE_OVERVIEW: {
                pageElement = (
                    <OverviewRoot
                        adminHost={this.props.adminHost}
                        viewer={this.props.viewer}
                        isAdminMode={this.props.isAdminMode}
                        isEditMode={this.props.isEditMode}
                        onChangePage={this.onChangePage}
                    />
                );
                break;
            }

            case PAGE_TYPE_PROJECTS: {
                pageElement = (
                    <ProjectsRoot
                        adminHost={this.props.adminHost}
                        viewer={this.props.viewer}
                        isClient={this.props.isClient || false}
                        isAdminMode={this.props.isAdminMode}
                        isEditMode={this.props.isEditMode}
                    />
                );
                break;
            }

            case PAGE_TYPE_FAVORITES: {
                pageElement = (
                    <FavoritesRoot
                        viewer={this.props.viewer}
                        isEditMode={this.props.isEditMode}
                        firmUrlLabel={this.props.firmUrlLabel}
                        isMobile={this.props.isMobile}
                    />
                );
                break;
            }
        }

        return (
            <div>
                <HeaderRoot
                    viewer={this.props.viewer}
                    currentPage={this.state.currentPage}
                    isEditMode={this.props.isEditMode}
                    onChangePage={this.onChangePage}
                />
                <div className={styles.content}>{pageElement}</div>
            </div>
        );
    }
}
export const DesignProfileWrapper = createRefetchContainer(
    DesignProfileWrapperComponent,
    {
        viewer: graphql`
            fragment DesignProfileWrapper_viewer on Viewer {
                ...HeaderRoot_viewer
                ...OverviewRoot_viewer
                ...ProjectsRoot_viewer
            }
        `,
    },
    ProfileRootQuery
);
