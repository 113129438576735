// Flow types automatically removed

import { createRefetchContainer, graphql } from 'react-relay/legacy'; // Components

import Folder from '../Folder'; // Helpers

import get from 'lodash.get'; // Types

export function FolderWrapper(props) {
    const { primaryItemPk, viewer } = props;

    function doRefetch() {
        return new Promise(resolve => {
            props.relay.refetch(
                fragmentVariables => fragmentVariables,
                null,
                () => resolve()
            );
        });
    }

    const folderPortfolioItems = (get(viewer, 'folderItemMatches') || []).filter(
        folderItemMatch => {
            return primaryItemPk === get(folderItemMatch, `item.serviceId`);
        }
    );
    return (
        <Folder
            {...props}
            doRefetch={doRefetch}
            itemId={primaryItemPk}
            selectedItemIds={props.selectedItemIds}
            folderPortfolioItems={folderPortfolioItems}
        />
    );
}
export default createRefetchContainer(
    FolderWrapper,
    {
        viewer: graphql`
            fragment FolderWrapper_viewer on Viewer
            @argumentDefinitions(
                selectedItemIds: { type: "[String]", defaultValue: [] }
                shouldFillInFolder: { type: "Boolean", defaultValue: false }
                userIds: { type: "[String]", defaultValue: [] }
            ) {
                __typename
                folderItemMatches: portfolioItemMatches(
                    itemIds: $selectedItemIds
                    userIds: $userIds
                    portfolioTypes: [FOLDER]
                ) @include(if: $shouldFillInFolder) {
                    ...Folder_folderPortfolioItems
                    item {
                        serviceId
                    }
                }
            }
        `,
    },
    graphql`
        query FolderWrapperRefetchQuery(
            $selectedItemIds: [String]
            $shouldFillInFolder: Boolean!
            $userIds: [String]!
        ) {
            viewer {
                ...FolderWrapper_viewer
                    @arguments(
                        selectedItemIds: $selectedItemIds
                        shouldFillInFolder: $shouldFillInFolder
                        userIds: $userIds
                    )
            }
        }
    `
);
