import { logUnexpectedNullGraphQLError } from '../../helpers';

type Project = {
    editUrl?: string | null;
    internalEditUrl?: string | null;
} | null;

export function getProjectEditorUrl(
    project: Project,
    adminHost: string = '',
    isAdminMode: boolean = false
): string | null {
    let projectEditorUrl: string | null = null;

    if (isAdminMode) {
        const internalEditUrl = project?.internalEditUrl;
        if (internalEditUrl) {
            projectEditorUrl = adminHost + internalEditUrl;
        } else {
            logUnexpectedNullGraphQLError('project', 'internalEditUrl');
            return null;
        }
    } else {
        const liveEditUrl = project?.editUrl;
        if (liveEditUrl) {
            projectEditorUrl = liveEditUrl;
        } else {
            logUnexpectedNullGraphQLError('project', 'editUrl');
            return null;
        }
    }

    return projectEditorUrl;
}
