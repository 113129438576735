import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { DeleteConfirmationModal } from '../../../shared/DeleteConfirmationModal';

import styles from '../OverviewArticles.scss';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    articleName: string;
};

export const DeleteArticleConfirmationModal: FC<Props> = ({
    isOpen,
    onClose,
    onDelete,
    articleName,
}) => {
    return (
        <DeleteConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            onDelete={onDelete}
            title={
                <FormattedMessage
                    id="abt.designProfile.DeleteArticleConfirmationModal.title"
                    defaultMessage="Remove Article"
                />
            }
            message={
                <FormattedMessage
                    id="abt.designProfile.DeleteArticleConfirmationModal.message"
                    defaultMessage="Are you sure you want to remove the article {articleName}?"
                    values={{
                        articleName: <span className={styles.articleName}>{articleName}</span>,
                    }}
                />
            }
            confirmCtaText={
                <FormattedMessage
                    id="abt.designProfile.DeleteArticleConfirmationModal.confirmCtaText"
                    defaultMessage="Yes, Remove"
                />
            }
        />
    );
};
