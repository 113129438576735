// Flow types automatically removed

import { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { GridCol, GridRow } from 'dibs-elements/exports/Grid';
import { decode } from '../shared/helpers/multiline';
import { OverviewAbout } from './Overview/OverviewAbout';
import { OverviewAwards } from './Overview/OverviewAwards';
import { OverviewArticles } from './Overview/OverviewArticles';
import { OverviewWeblinks } from './Overview/OverviewWeblinks';
import OverviewInstagram from './Overview/OverviewInstagram';
import OverviewProjects from './Overview/OverviewProjects';
import PublishedModal from './Overview/PublishedModal/PublishedModal';
import { PUBLISHED_PROJECT_QUERY } from '../projectUpload/constants';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import styles from './OverviewRoot.scss';
export const OverviewRoot = ({
    viewer: viewerRef,
    isAdminMode,
    isEditMode,
    onChangePage,
    adminHost,
}) => {
    const viewer = useFragment(
        graphql`
            fragment OverviewRoot_viewer on Viewer {
                ...OverviewInstagram_viewer
                tradePortfolioInstagram: featureFlag(feature: "tradePortfolioInstagram")
                tradeFirms(urlLabel: $firmUrlLabel) @include(if: $shouldRenderOverview) {
                    ...OverviewAbout_tradeFirm
                    ...OverviewAwards_tradeFirm
                    ...OverviewArticles_tradeFirm
                    ...OverviewWeblinks_tradeFirm
                    ...OverviewInstagram_tradeFirm
                    ...OverviewProjects_tradeFirm
                    publicProfile {
                        about
                        articles {
                            webLink {
                                url
                            }
                        }
                        designProjects(
                            visibility: ["Y", "N"]
                            status: ["ACTIVE", "PENDING"]
                            orderBy: MODIFIED_DATE
                            ascDesc: DESC
                        ) @include(if: $isEditMode) {
                            url
                        }
                        liveDesignProjects: designProjectsCover(
                            orderBy: MODIFIED_DATE
                            ascDesc: DESC
                        ) @skip(if: $isEditMode) {
                            serviceId
                        }
                    }
                    instagram {
                        __id
                    }
                }
                ...OverviewAwards_viewer
            }
        `,
        viewerRef
    );
    const tradeFirm = viewer?.tradeFirms?.[0] || null;
    const hasInstagram = !!tradeFirm?.instagram && !!viewer?.tradePortfolioInstagram;
    const hasArticles = !!(tradeFirm?.publicProfile?.articles || []).length;
    const hasAbout = !!decode(tradeFirm?.publicProfile?.about);
    const [showModal, setShowModal] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const hasLiveDesignProjects = tradeFirm?.publicProfile?.liveDesignProjects?.length > 0;
    useEffect(() => {
        const publishedQuery = new URLSearchParams(window.location.search).get(
            PUBLISHED_PROJECT_QUERY
        );
        setShowModal(!!publishedQuery);
        setIsPublished(publishedQuery === 'Y');
        return () => {
            if (publishedQuery) {
                window.history.pushState(
                    null,
                    '',
                    handleLocaleUrl(window.location.pathname, GLOBAL_CLIENT_ONLY_LOCALE)
                );
            }
        };
    }, []);
    return (
        <>
            {(hasLiveDesignProjects || isEditMode) && (
                <div className={styles.rowWrapper}>
                    <OverviewProjects
                        adminHost={adminHost}
                        tradeFirm={tradeFirm}
                        isAdminMode={isAdminMode}
                        isEditMode={isEditMode}
                        onChangePage={onChangePage}
                    />
                </div>
            )}
            {(hasAbout || isEditMode) && (
                <div className={styles.rowWrapper}>
                    <GridRow>
                        <OverviewAbout tradeFirm={tradeFirm} isEditMode={isEditMode} />
                        <GridCol
                            desktopOptions={{
                                offset: 1,
                            }}
                            tabletLandscape={3}
                            mobile={12}
                        >
                            <OverviewAwards
                                tradeFirm={tradeFirm}
                                isEditMode={isEditMode}
                                viewer={viewer || null}
                            />
                            <OverviewWeblinks tradeFirm={tradeFirm} isEditMode={isEditMode} />
                        </GridCol>
                    </GridRow>
                </div>
            )}
            {(hasArticles || isEditMode) && (
                <div className={styles.rowWrapper}>
                    <OverviewArticles isEditMode={isEditMode} tradeFirm={tradeFirm} />
                </div>
            )}
            {(hasInstagram || isEditMode) && (
                <OverviewInstagram tradeFirm={tradeFirm} viewer={viewer} isEditMode={isEditMode} />
            )}
            <PublishedModal
                designProjectUrl={tradeFirm?.publicProfile?.designProjects?.[0]?.url || ''}
                onClose={() => setShowModal(false)}
                isOpen={showModal}
                isPublished={isPublished}
            />
        </>
    );
};
export default OverviewRoot;
