import { type FC, useState, useCallback, useEffect, type ReactNode } from 'react';
import { useFragment, graphql, useRelayEnvironment } from 'react-relay';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';
import imageUploader from '../helpers/imageUploader';

import profileMediaMutation from '../mutations/profileMedia';

import styles from './ProfileIcon.scss';

import { Spinner } from 'dibs-elements/exports/Spinner';

import {
    type ProfileIcon_tradeFirm$key,
    type ProfileIcon_tradeFirm$data as TradeFirm,
} from './__generated__/ProfileIcon_tradeFirm.graphql';

type Props = {
    isEditMode: boolean;
    tradeFirm: ProfileIcon_tradeFirm$key;
};

const IconOverlay: FC<{
    isEditMode: boolean;
    isSaving: boolean;
    hasError: boolean;
}> = ({ isEditMode, isSaving, hasError }) => {
    if (!isEditMode) {
        return null;
    } else if (isSaving) {
        return (
            <div className={styles.spinner}>
                <Spinner size="small" />
            </div>
        );
    } else if (hasError) {
        return (
            <div className={styles.overlay} data-tn="profile-icon-validation-error">
                <FormattedMessage
                    id="abt.designProfile.header.profileIcon.error"
                    defaultMessage="Minimum size 350px x 350px JPG"
                />
            </div>
        );
    }
    return null;
};

function getProfileIconPath(tradeFirm: TradeFirm): string | null {
    return tradeFirm?.publicProfile?.profilePhoto || null;
}

const ProfileIcon: FC<Props> = ({ tradeFirm: tradeFirmRef, isEditMode }) => {
    const environment = useRelayEnvironment();
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileIcon_tradeFirm on TradeFirmType {
                serviceId
                name
                publicProfile {
                    profilePhoto
                    splashPhotoId
                }
            }
        `,
        tradeFirmRef
    );
    const [isSaving, setSaving] = useState(false);
    const [hasError, setError] = useState(false);
    const [profilePath, setProfilePath] = useState(getProfileIconPath(tradeFirm));
    useEffect(() => {
        setProfilePath(getProfileIconPath(tradeFirm));
    }, [tradeFirm]);

    const onDrop = useCallback(
        async (acceptedFiles: Array<File> = []): Promise<void> => {
            setSaving(true);
            setError(false);
            const file = acceptedFiles[0];
            if (!file) {
                setError(true);
                setSaving(false);
                return;
            }
            const firmId = tradeFirm?.serviceId;

            if (!file || !firmId) {
                setError(true);
                setSaving(false);
                return;
            }
            const uploadResult = await imageUploader(file, firmId);
            if (uploadResult.hasError) {
                setError(true);
                setSaving(false);
                setProfilePath(getProfileIconPath(tradeFirm));
                return;
            }

            // update the preview after save, since it'll be disposed
            const _profilePath = uploadResult?.image?.path || '';

            setProfilePath(_profilePath);

            const profilePhotoId = uploadResult?.image?.id || '';

            if (!profilePhotoId) {
                throw new Error('No new ID returned for the profile photo');
            }
            try {
                await profileMediaMutation(environment, {
                    serviceId: firmId.toString(),
                    profilePhotoId: profilePhotoId.toString(),
                });
                setError(false);
                setSaving(false);
            } catch (err) {
                setError(true);
                setSaving(false);
            }
        },
        [tradeFirm, environment]
    );
    const name = tradeFirm?.name;
    let profilePhoto = (isSaving ? profilePath : getProfileIconPath(tradeFirm)) || '';

    if (profilePhoto && profilePhoto.length) {
        profilePhoto = `${profilePhoto}?width=300`; // displays nicer on hidpi screens
    }

    let letterMark: ReactNode = null;

    if (!profilePhoto && name?.[0]) {
        letterMark = <div className={styles.letterWrapper}>{name[0].toUpperCase()}</div>;
    }

    if (isEditMode) {
        const profilePhotoStyle = {
            backgroundImage: `url("${profilePhoto}")`,
        };
        const wrapper = classnames(styles.wrapper, styles.isEditMode, {
            [styles.hasError]: hasError,
            [styles.isSaving]: isSaving,
        });
        return (
            <Dropzone accept="image/jpeg" multiple={false} onDrop={onDrop}>
                {dropzoneProps => (
                    <div
                        {...dropzoneProps.getRootProps()}
                        className={wrapper}
                        style={profilePhotoStyle}
                        data-tn="profile-icon-dropzone"
                    >
                        {letterMark}
                        <input
                            {...dropzoneProps.getInputProps()}
                            data-tn="upload-profile-icon-input"
                            disabled={isSaving}
                        />
                        <IconOverlay
                            isEditMode={isEditMode}
                            isSaving={isSaving}
                            hasError={hasError}
                        />
                    </div>
                )}
            </Dropzone>
        );
    } else if (profilePhoto) {
        // read-only mode
        return (
            <div className={styles.wrapper}>
                <div className={styles.profilePhotoFrame}>
                    <img
                        src={profilePhoto}
                        className={styles.profilePhoto}
                        alt={name || 'Profile Icon'}
                    />
                </div>
            </div>
        );
    } else if (letterMark) {
        return <div className={styles.wrapper}>{letterMark}</div>;
    }
    // this case needed for TS; theoretically, no firm should be w/o a name
    return null;
};

export default ProfileIcon;
