import { graphql, type Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    type createDesignProjectMutation$data,
    type CreateDesignProjectMutationInput,
    type createDesignProjectMutation,
} from './__generated__/createDesignProjectMutation.graphql';

const mutation = graphql`
    mutation createDesignProjectMutation($input: CreateDesignProjectMutationInput!) {
        createDesignProject(input: $input) {
            project {
                internalEditUrl
                editUrl
            }
        }
    }
`;

export default async function (
    environment: Environment,
    input: CreateDesignProjectMutationInput
): Promise<createDesignProjectMutation$data> {
    return await commitMutation<createDesignProjectMutation>(environment, {
        mutation,
        variables: {
            input,
        },
    });
}
