// Flow types automatically removed

import getter from 'dibs-object-getter';
import { trackEcomEvent } from '../../shared/helpers/tracking';
import { trackingConstants, eventNameConstants } from 'dibs-tracking';
const { ECOM_PRODUCT_CLICK } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;
export const trackItemTile = ({
    id,
    position,
    dimension76,
    dimension83,
    price,
    brand,
    category,
    categoryCode,
    name,
    list,
    eventName,
}) => {
    const trackObj = {
        type: ECOM_PRODUCT_CLICK,
        products: {
            id,
            position,
            dimension76,
            dimension83,
            price,
            brand,
            category,
            categoryCode,
            name,
            list,
        },
        actionFieldList: list,
        eventName,
    };
    trackEcomEvent(trackObj);
};
export const createProductImpressionTracking = ({
    isTrade,
    listType = 'designer favorites',
    items = [],
    positionNum,
}) => {
    return items.map((item, index) => {
        const itemGetter = getter(item);
        const isNewAndMadToOrder = itemGetter.value('isNewAndMadeToOrder') ? 'y' : 'n';
        const isContemporary = itemGetter.value('isContemporary') ? 'y' : 'n';
        const isCustomizable = itemGetter.value('isCustomizable') ? 'y' : 'n';
        const id = itemGetter.value('serviceId') || 'no item pk';
        const name = itemGetter.value('title') || 'item missing title';
        const position = positionNum === undefined ? index : positionNum;
        const dimension83 = `contemporary=${isContemporary}|cust=${isCustomizable}|new_and_made_to_order=${isNewAndMadToOrder}`;
        const brand = itemGetter.at('seller').value('serviceId') || 'no seller id';
        const categoryGetter = itemGetter
            .at('classification')
            .at('categories')
            .at(0)
            .at('category');
        const category = categoryGetter.value('browseUrl') || 'no category';
        const categoryCode = categoryGetter.value('key') || 'no category code';
        const priceGetter = itemGetter.at('pricing');
        const salePrice = priceGetter.at('salePrice').value('amount');
        let price;
        let dimension76;

        if (salePrice) {
            price = salePrice;
            dimension76 = 'saturday sale'; // should change to `sale price`
        } else if (isTrade) {
            price = priceGetter.at('netPrice').value('amount');
            dimension76 = 'net price';
        } else {
            price = priceGetter.at('amount').value('amount');
            dimension76 = 'retail price';
        }

        if (!price) {
            price = 'price missing';
        }

        return {
            brand,
            category,
            categoryCode,
            dimension76,
            dimension83,
            id,
            name,
            position,
            price,
            list: listType,
        };
    });
};
