import { type FC, useState, useCallback } from 'react';
import { graphql, createRefetchContainer, type RelayRefetchProp } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import SV from 'server-vars';

import { Button } from 'dibs-elements/exports/Button';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { Link } from 'dibs-elements/exports/Link';

import Instagram from 'dibs-icons/exports/legacy/Instagram';

import { connectInstagram, type InstagramCallback } from '../helpers/connectInstagram';
import disconnectInstagram from '../mutations/disconnectInstagram';

import styles from './OverviewInstagram.scss';

import { type OverviewInstagram_tradeFirm$data } from './__generated__/OverviewInstagram_tradeFirm.graphql';
import { type OverviewInstagram_viewer$data } from './__generated__/OverviewInstagram_viewer.graphql';

import { type ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

export const MAX_PHOTO_DISPLAY = 9;

type InstagramFeed = NonNullable<
    NonNullable<OverviewInstagram_tradeFirm$data['instagram']>['feed']
>;
type InstagramFeedItem = NonNullable<ArrayElement<InstagramFeed>>;

const FeedItem: FC<{ item: InstagramFeedItem }> = ({ item }) => {
    const { imageUrl, link } = item;
    if (!imageUrl || !link) {
        return null;
    }

    return (
        <div className={`${styles.imageContainer} colXs4`} key={imageUrl}>
            <Link href={link} target="_blank">
                <img src={imageUrl} className={styles.image} alt="" />
            </Link>
        </div>
    );
};

const Feed: FC<{
    feed: InstagramFeed;
    isEditMode: boolean;
    isConnected: boolean;
}> = ({ feed, isEditMode, isConnected }) => {
    if (!feed.length) {
        return null;
    }

    if (isEditMode && !isConnected) {
        return null;
    }

    return (
        <div className={`${styles.feedWrapper} rowFlex`}>
            {feed
                .filter(filterNulls)
                .slice(0, MAX_PHOTO_DISPLAY)
                .map(feedItem => feedItem.link && <FeedItem item={feedItem} key={feedItem.link} />)}
        </div>
    );
};

const Connect: FC<{
    instagramOauthUrl: string | null;
    isConnected: boolean;
    isEditMode: boolean;
    relay: RelayRefetchProp;
}> = ({ instagramOauthUrl, isConnected, isEditMode, relay }) => {
    const [showConnectionError, setShowConnectionError] = useState(false);
    const igConnectionCallback: InstagramCallback = useCallback(
        (error: Error | string | null) => {
            if (error) {
                // eslint-disable-next-line no-console
                console.error('Caught error when connecting to Instagram:');
                // eslint-disable-next-line no-console
                console.error(error);
                setShowConnectionError(true);
            }
            relay.refetch(vars => vars, null, null, { force: true });
        },
        [relay]
    );

    if (isConnected || !isEditMode) {
        return null;
    }

    const connectInstagramHandler = (): void => {
        if (instagramOauthUrl) {
            setShowConnectionError(false);
            connectInstagram(instagramOauthUrl, igConnectionCallback);
        } else {
            setShowConnectionError(true);
        }
    };

    const onClickInstagramConnect = (): void => {
        connectInstagramHandler();
    };

    let renderConnectContent = (
        <div className={styles.description}>
            <FormattedMessage
                id="abt.designProfile.overview.instagram.cannotConnect"
                defaultMessage="Cannot connect to Instagram. Please try again later."
            />
        </div>
    );

    if (SV.get('isAdminMode')) {
        renderConnectContent = (
            <div className={styles.description}>
                <FormattedMessage
                    id="abt.designProfile.overview.instagram.cannotConnectAdmin"
                    defaultMessage="Internal admin users cannot connect Instagram accounts"
                />
            </div>
        );
    } else if (instagramOauthUrl) {
        renderConnectContent = (
            <Button
                dataTn="overview-instagram-connect"
                onClick={onClickInstagramConnect}
                className={styles.button}
            >
                <Instagram className={styles.buttonIcon} />
                <FormattedMessage
                    id="abt.designProfile.overview.instagram.connectButton"
                    defaultMessage="Connect to Instagram"
                />
            </Button>
        );
    }

    return (
        <div className="colSm12">
            <div className={styles.description}>
                <FormattedMessage
                    id="abt.designProfile.overview.instagram.connectDescription"
                    defaultMessage="Connect an instagram account to add photos to your profile."
                />
            </div>
            <div className={styles.buttonContainer}>{renderConnectContent}</div>
            {showConnectionError && (
                <div className={styles.description}>
                    <FormattedMessage
                        id="abt.designProfile.overview.instagram.connectError"
                        defaultMessage="There was an error connecting your account.  Please try again later."
                    />
                </div>
            )}
        </div>
    );
};

type DisconnectProps = {
    isEditMode: boolean;
    isConnected: boolean;
    username?: string | null;
    relay: RelayRefetchProp;
    firmId: string | null;
};

const Disconnect: FC<DisconnectProps> = ({ isEditMode, isConnected, username, relay, firmId }) => {
    const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [isDisconnectRunning, setDisconnectRunning] = useState(false);
    const [hasDisconnectError, setDisconnectError] = useState(false);

    const handleDisconnect = useCallback(async () => {
        if (!firmId) {
            return;
        }

        setDisconnectRunning(true);
        setDisconnectError(false);
        try {
            await disconnectInstagram(relay.environment, { firmId });
            relay.refetch(
                vars => vars,
                null,
                err => {
                    setDisconnectRunning(false);
                    if (err) {
                        return;
                    }
                    setDisconnectModalOpen(false);
                },
                { force: true }
            );
        } catch (err: $TSFixMe) {
            setDisconnectError(true);
            setDisconnectRunning(false);
            // eslint-disable-next-line no-console
            console.error(`Caught error when disconnecting from Instagram: ${err.message}`, err);
        }
    }, [relay, firmId]);

    const handleModalCloseTrigger = useCallback(() => {
        if (isDisconnectRunning) {
            return;
        }
        setDisconnectModalOpen(false);
    }, [isDisconnectRunning]);

    if (!isEditMode || !isConnected) {
        return null;
    }

    const rowClass = `rowFlex colSm12 ${styles.disconnectRow}`;

    return (
        <>
            <div className={rowClass}>
                {username && (
                    <FormattedMessage
                        id="abt.designProfile.overview.instagram.connectedTo"
                        defaultMessage="Connected to: {username}"
                        values={{ username }}
                    />
                )}
            </div>
            <div className={rowClass}>
                <Link onClick={() => setDisconnectModalOpen(true)}>
                    <FormattedMessage
                        id="abt.designProfile.overview.instagram.disconnectAccountHeader"
                        defaultMessage="Disconnect"
                    />
                </Link>
            </div>
            <ModalContainer
                isOpen={disconnectModalOpen}
                onClose={handleModalCloseTrigger}
                modalPosition="center"
            >
                <BarHeader
                    title={
                        <FormattedMessage
                            id="abt.designProfile.overview.instagram.disconnectModalHeader"
                            defaultMessage="Disconnect Instagram"
                        />
                    }
                />
                <ModalBody>
                    <p className={styles.disconnectModalCopy}>
                        <FormattedMessage
                            id="abt.designProfile.overview.instagram.disconnectModalBodyUsername"
                            defaultMessage="Are you sure you want to disconnect {username}?"
                            values={{
                                username: username || (
                                    <FormattedMessage
                                        id="abt.designProfile.overview.instagram.disconnectUsernameFallback"
                                        defaultMessage="this user"
                                    />
                                ),
                            }}
                        />
                    </p>
                    <p className={styles.disconnectModalCopy}>
                        <FormattedMessage
                            id="abt.designProfile.overview.instagram.disconnectModalBody"
                            defaultMessage="You'll have to connect to another account if you want to display an Instagram feed on your firm portfolio."
                        />
                    </p>
                    {hasDisconnectError && (
                        <p className={styles.disconnectModalCopy}>
                            <FormattedMessage
                                id="abt.designProfile.overview.instagram.disconnectModalError"
                                defaultMessage="There was an error disconnecting your Instagram account.  Please try again later."
                            />
                        </p>
                    )}
                    <div className={styles.disconnectButtonsWrapper}>
                        <div className={styles.disconnectButton}>
                            <Button
                                fullWidth
                                type="secondary"
                                dataTn="instagram-disconnect-cancel"
                                onClick={handleModalCloseTrigger}
                                disabled={isDisconnectRunning}
                            >
                                <FormattedMessage
                                    id="abt.designProfile.overview.instagram.disconnectModalCancel"
                                    defaultMessage="Cancel"
                                />
                            </Button>
                        </div>
                        <div className={styles.disconnectButton}>
                            <Button
                                fullWidth
                                dataTn="instagram-disconnect-o"
                                onClick={handleDisconnect}
                                disabled={isDisconnectRunning}
                            >
                                <FormattedMessage
                                    id="abt.designProfile.overview.instagram.disconnectModalO"
                                    defaultMessage="Yes, Disconnect"
                                />
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </ModalContainer>
        </>
    );
};

type Props = {
    viewer: OverviewInstagram_viewer$data;
    tradeFirm: OverviewInstagram_tradeFirm$data | null | undefined;
    isEditMode: boolean;
    relay: RelayRefetchProp;
};

const OverviewInstagram: FC<Props> = ({ viewer, isEditMode, tradeFirm, relay }) => {
    if (!viewer?.tradePortfolioInstagram) {
        return null;
    }
    const instagram = tradeFirm?.instagram;
    const feed = instagram?.feed || [];
    const username = instagram?.username;
    // the instagram feed is behind a 15-minute cache; to avoid caching issues after disconnecting,
    // use the uncached username lookup
    const isConnected = !!username;
    const instagramOauthUrl: string | null = SV.get('instagramOauthUrl') || null;

    if (!isEditMode && !feed.length) {
        return null;
    }

    return (
        <div className={styles.rowWrapper}>
            <div className={`colSm12 ${styles.title}`}>
                <FormattedMessage
                    id="abt.designProfile.overview.instagram.title"
                    defaultMessage="Instagram"
                />
            </div>
            <Disconnect
                isEditMode={isEditMode}
                isConnected={isConnected}
                username={username}
                relay={relay}
                firmId={tradeFirm?.serviceId || null}
            />
            <Feed feed={feed} isEditMode={isEditMode} isConnected={isConnected} />
            <Connect
                relay={relay}
                isConnected={isConnected}
                instagramOauthUrl={instagramOauthUrl}
                isEditMode={isEditMode}
            />
        </div>
    );
};

export default createRefetchContainer(
    OverviewInstagram,
    {
        tradeFirm: graphql`
            fragment OverviewInstagram_tradeFirm on TradeFirmType {
                serviceId
                instagram {
                    username @include(if: $isEditMode)
                    feed {
                        imageUrl
                        link
                    }
                }
            }
        `,
        viewer: graphql`
            fragment OverviewInstagram_viewer on Viewer {
                tradePortfolioInstagram: featureFlag(feature: "tradePortfolioInstagram")
            }
        `,
    },
    graphql`
        query OverviewInstagramRefetchQuery($firmUrlLabel: String!, $isEditMode: Boolean!) {
            viewer {
                tradeFirms(urlLabel: $firmUrlLabel) {
                    ...OverviewInstagram_tradeFirm
                }
            }
        }
    `
);
