/**
 * @generated SignedSource<<93c755003f3def2c1e1caa9496dd1187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewProjects_tradeFirm$data = {
  readonly name: string | null;
  readonly publicProfile: {
    readonly editModeDesignProjects?: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly status: string | null;
      readonly visibility: boolean | null;
      readonly " $fragmentSpreads": FragmentRefs<"EditDesignProjectTile_designProject">;
    } | null> | null;
    readonly liveDesignProjects?: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly status: string | null;
      readonly visibility: boolean | null;
      readonly " $fragmentSpreads": FragmentRefs<"DisplayDesignProjectTile_designProject">;
    } | null> | null;
    readonly urlLabel: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"NewProjectTile_tradeFirm">;
  readonly " $fragmentType": "OverviewProjects_tradeFirm";
};
export type OverviewProjects_tradeFirm$key = {
  readonly " $data"?: OverviewProjects_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewProjects_tradeFirm">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "ascDesc",
  "value": "DESC"
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "MODIFIED_DATE"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewProjects_tradeFirm",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewProjectTile_tradeFirm"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmPublicProfileType",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "condition": "isEditMode",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": "liveDesignProjects",
              "args": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "concreteType": "DesignProjectCover",
              "kind": "LinkedField",
              "name": "designProjectsCover",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DisplayDesignProjectTile_designProject"
                },
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": "designProjectsCover(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\")"
            }
          ]
        },
        {
          "condition": "isEditMode",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "editModeDesignProjects",
              "args": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "Literal",
                  "name": "status",
                  "value": [
                    "ACTIVE",
                    "PENDING"
                  ]
                },
                {
                  "kind": "Literal",
                  "name": "visibility",
                  "value": [
                    "Y",
                    "N"
                  ]
                }
              ],
              "concreteType": "designProject",
              "kind": "LinkedField",
              "name": "designProjects",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EditDesignProjectTile_designProject"
                },
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": "designProjects(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\",status:[\"ACTIVE\",\"PENDING\"],visibility:[\"Y\",\"N\"])"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};
})();

(node as any).hash = "7e8170486fd22243b3146e73dfe0c175";

export default node;
