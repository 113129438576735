// Flow types automatically removed

import { graphql, commitMutation } from 'react-relay/legacy'; // Relay name clash with dibs-portfolio-heart/src/Heart/ModifyPortfolioItems.js !!!

const mutation = graphql`
    mutation TradeSharedModifyPortfolioItemsMutation($input: ModifyPortfolioItemsInput!) {
        modifyPortfolioItems(input: $input) {
            removedItemIds
        }
    }
`;

function commit(environment, args) {
    const { action, id, itemId, userId, onCompleted, onError, portfolioType } = args;
    const input = {
        action,
        userId,
        portfolioType,
        itemIds: [itemId],
    };
    const variables = {
        input,
        userId,
        itemId,
    };
    const isFavorite = action === 'REMOVE' ? false : true;

    const updater = store => {
        const storeValueForId = id ? store.get(id) : null;

        if (storeValueForId) {
            storeValueForId.setValue(isFavorite, 'isFavorite', {
                userId,
            });
        }
    };

    commitMutation(environment, {
        mutation,
        variables,
        optimisticUpdater: updater,
        updater,
        onCompleted,
        onError,
    });
}

export default {
    commit,
};
