import { type FC, type ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { Button } from 'dibs-elements/exports/Button';

import styles from './DeleteConfirmationModal.scss';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    title: ReactNode;
    message: ReactNode;
    confirmCtaText?: ReactNode;
};

export const DeleteConfirmationModal: FC<Props> = ({
    isOpen,
    onClose,
    onDelete,
    title,
    message,
    confirmCtaText,
}) => {
    return (
        <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            closeOnEsc
            dataTn="delete-confirmation-modal"
        >
            <ModalCloseButton onClick={onClose} />
            <BarHeader title={title} />
            <ModalBody>
                <div className={styles.promptText}>
                    {message}
                    <FormattedMessage
                        id="abt.designProfile.DeleteConfirmationModal.promptTextLine"
                        defaultMessage="It cannot be undone."
                        children={txt => <div>{txt}</div>}
                    />
                </div>

                <div className={styles.buttonWrapper}>
                    <Button
                        className={styles.cancelButton}
                        type="secondary"
                        onClick={onClose}
                        dataTn="dismiss-delete"
                        fullWidth
                    >
                        <FormattedMessage
                            id="abt.designProfile.DeleteConfirmationModal.dismissCta"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        className={styles.deleteButton}
                        dataTn="confirm-delete"
                        onClick={onDelete}
                        fullWidth
                    >
                        {confirmCtaText || (
                            <FormattedMessage
                                id="abt.designProfile.DeleteConfirmationModal.confirmationCta"
                                defaultMessage="Yes, delete"
                            />
                        )}
                    </Button>
                </div>
            </ModalBody>
        </ModalContainer>
    );
};
