import { type FC, useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import { graphql, useFragment } from 'react-relay';
import classNames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

import { DesignProjectTileBgImage } from './DesignProjectTileBgImage';
import { DesignProjectTileDetails } from './DesignProjectTileDetails';
import { DesignProjectTileStatusBadge } from './DesignProjectTileStatusBadge';
import { DeleteProjectConfirmationModal } from './DeleteProjectConfirmationModal';
import { EditWrapperLayout } from '../../components/EditWrapperLayout/EditWrapperLayout';
import { redirectTopLocation } from '../../../shared/helpers/redirectTopLocation';

import styles from './EditDesignProjectTile.scss';

import { type EditDesignProjectTile_designProject$key } from './__generated__/EditDesignProjectTile_designProject.graphql';
import { DESIGN_PROJECT_STATUS_ACTIVE } from '../../constants';

export type Props = {
    adminHost: string;
    designProject: EditDesignProjectTile_designProject$key;
    serviceId: string;
    urlLabel: string;
    isAdminMode: boolean;
    isOverviewTile: boolean;
    connectDragSource?: ConnectDragSource;
    setDragTargetProjectId?: (id?: string) => void; // needed by EditDesignProjectTileDraggable
    unsetDragTargetProjectId?: (id?: string) => void; // needed by EditDesignProjectTileDraggable
};

const EditDesignProjectTile: FC<Props> = ({
    adminHost,
    designProject: designProjectRef,
    urlLabel,
    isOverviewTile,
    isAdminMode,
    connectDragSource,
}) => {
    const designProject = useFragment(
        graphql`
            fragment EditDesignProjectTile_designProject on designProject {
                ...DesignProjectTileStatusBadge_designProject
                ...DeleteProjectConfirmationModal_designProject
                serviceId
                addressDisplayString
                internalEditUrl
                editUrl
                name
                rootId
                url
                visibility
                status
                tradeCoverPhoto {
                    path
                }
            }
        `,
        designProjectRef
    );
    const [showDeleteProjectConfirmation, setShowDeleteProjectConfirmation] = useState(false);
    const isActive = designProject?.status === DESIGN_PROJECT_STATUS_ACTIVE;
    const isVisible = !!designProject?.visibility;
    const isLive = isVisible && isActive;
    const designProjectUrl = designProject?.url || '';

    if (!designProject) {
        return null;
    }

    const editUrl =
        (isAdminMode
            ? `${adminHost}${designProject?.internalEditUrl || ''}`
            : designProject?.editUrl) || '';

    const containerClass = connectDragSource
        ? styles.innerContainerDraggable
        : styles.innerContainer;

    const content = (
        <div
            className={classNames(containerClass, {
                [styles.large]: isOverviewTile,
            })}
            data-tn="edit-design-project-tile"
        >
            <EditWrapperLayout
                onDeleteClick={() => setShowDeleteProjectConfirmation(true)}
                onEditClick={() => redirectTopLocation(editUrl)}
                deleteDataTn="edit-design-project-delete-button"
                editDataTn="edit-design-project-edit-button"
                imageContent={
                    <div className={styles.imageContainer}>
                        <DesignProjectTileBgImage
                            coverPhotoPath={designProject?.tradeCoverPhoto?.path || ''}
                            size={isOverviewTile ? 'medium' : 'small'}
                        />
                        {isOverviewTile && (
                            <Link
                                href={isLive ? designProjectUrl : ''}
                                onClick={isLive ? undefined : () => redirectTopLocation(editUrl)}
                                target="_blank"
                                className={styles.imageOverlay}
                            >
                                <FormattedMessage
                                    id="abt.designProfile.EditDesignProjectTile.viewText"
                                    defaultMessage={`{isLive, select,
                                        true {View Live}
                                        other {View Project}
                                    }`}
                                    values={{ isLive }}
                                />
                            </Link>
                        )}
                    </div>
                }
                bottomContent={
                    <>
                        <DesignProjectTileDetails
                            addressDisplayString={designProject?.addressDisplayString}
                            name={designProject?.name}
                        />
                        <div className={styles.statusWrapper}>
                            <DesignProjectTileStatusBadge designProject={designProject} />
                        </div>
                    </>
                }
            />
            <DeleteProjectConfirmationModal
                isOpen={showDeleteProjectConfirmation}
                designProject={designProject}
                urlLabel={urlLabel}
                onClose={() => setShowDeleteProjectConfirmation(false)}
            />
        </div>
    );

    return connectDragSource ? (
        connectDragSource(content)
    ) : (
        <div
            className={classNames(styles.containerEditable, {
                [styles.large]: isOverviewTile,
            })}
        >
            {content}
        </div>
    );
};

export default EditDesignProjectTile;
