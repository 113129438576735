import { type FC, Component, type ReactNode, type KeyboardEvent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Link, type LinkProps } from 'dibs-elements/exports/Link';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';

import pagelinks from 'dibs-pagelinks';
import classnames from 'classnames';

import css from './Pagination.scss';

const root = typeof window !== 'undefined' ? window : global;

const initialState = {
    showPageInputStart: false,
    showPageInputEnd: false,
} as const;

type PaginationState = {
    showPageInputStart: boolean;
    showPageInputEnd: boolean;
};

type PaginationProps = {
    page: number;
    totalResults: number;
    numberOfPages: number;
    handlePaginate: (page: number) => void;
    href?: (page: number) => string;
    pageTilesToDisplay: number; // this value should always be even, or else a decimal value will be generated on mobile
};

type Page = {
    type: string;
    key: string;
    pageNumber: number;
    isSelected: boolean;
};

type GenerateHrefPayload = {
    pageNum: number;
    pageLinkNum?: number;
    href?: (pageNum: number) => string;
};

const generateHref = ({
    pageNum,
    pageLinkNum,
    href: hrefFn,
}: GenerateHrefPayload): string | undefined => {
    if (!hrefFn) {
        return undefined;
    }
    const [path, params] = hrefFn(pageNum).split('?');
    const searchParams = new URLSearchParams(params || '');
    const notInteger = typeof pageLinkNum !== 'undefined' && !Number.isInteger(pageLinkNum);
    if (notInteger) {
        searchParams.append('halfNavPage', 'true');
        searchParams.append('windowWidth', `${root?.innerWidth || 0}`);
        searchParams.append('generatedOn', encodeURIComponent(root?.location?.href));
    }
    const newParams = searchParams.toString();
    const showQM = newParams.length;
    return `${path}${showQM ? '?' : ''}${newParams}`;
};

const PreviousPageLink: FC = () => (
    <div className={css.arrowWrapper}>
        <div className={css.arrow}>
            <ArrowLeft />
        </div>
        <FormattedMessage id="abt.Pagination.previous" defaultMessage="Previous" />
    </div>
);

const NextPageLink: FC = () => (
    <div className={css.arrowWrapper}>
        <FormattedMessage id="abt.Pagination.next" defaultMessage="Next" />
        <div className={css.arrow}>
            <ArrowRight />
        </div>
    </div>
);

export class Pagination extends Component<PaginationProps, PaginationState> {
    static defaultProps = {
        pageTilesToDisplay: 6,
    };

    constructor(props: PaginationProps) {
        super(props);
        this.state = initialState;
    }

    getPageInputStateKey = (isStart: boolean): keyof PaginationState => {
        // there can be two page input toggles
        return isStart ? 'showPageInputStart' : 'showPageInputEnd';
    };

    togglePageInput = (isStart: boolean): void => {
        const stateKey = this.getPageInputStateKey(isStart);
        this.setState(prevState => {
            return { [stateKey]: !prevState[stateKey] } as Record<keyof PaginationState, boolean>;
        });
    };

    onPageInputKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
        const { currentTarget, key } = event;
        const numberOfPages = this.props.numberOfPages || 1;
        let pageInput = parseInt(currentTarget.value, 10);
        const isValidPage = pageInput && pageInput > 0;
        if (pageInput > numberOfPages) {
            pageInput = numberOfPages; // input too high, show last page
        }
        if (isValidPage && key === 'Enter') {
            this.props.handlePaginate(pageInput);
            this.setState(initialState);
        }
    };

    getPageLink = ({ isSelected, type, key, pageNumber }: Page): ReactNode => {
        const { handlePaginate, href, page } = this.props;

        const getLinkProps = (pageNum: number): LinkProps => {
            return {
                href: generateHref({
                    href,
                    pageLinkNum: pageNumber,
                    pageNum,
                }),
                onClick: e => {
                    e.preventDefault();
                    handlePaginate(pageNum);
                },
            };
        };

        let linkElement = null;
        let listClasses;

        switch (type) {
            case 'page':
                {
                    listClasses = classnames(css.paginationItem, {
                        [css.activeItem]: isSelected,
                    });
                    linkElement = (
                        <Link
                            {...getLinkProps(pageNumber)}
                            className={css.page}
                            dataTn={`pagination-page-${pageNumber}`}
                        >
                            {pageNumber}
                        </Link>
                    );
                }
                break;
            case 'back':
                {
                    listClasses = css.prevArrowItem;
                    linkElement = (
                        <Link
                            {...getLinkProps(page - 1)}
                            className={css.arrowLink}
                            dataTn="pagination-back"
                        >
                            <PreviousPageLink />
                        </Link>
                    );
                }
                break;
            case 'forward':
                listClasses = css.nextArrowItem;
                linkElement = (
                    <Link
                        {...getLinkProps(page + 1)}
                        className={css.arrowLink}
                        dataTn="pagination-next"
                    >
                        <NextPageLink />
                    </Link>
                );
                break;
            case 'ellipsis':
                {
                    const isStart = key === 'start-ellipsis';
                    const showPageInput = this.state[this.getPageInputStateKey(isStart)];
                    listClasses = css.ellipsisContainer;
                    linkElement = (
                        <>
                            <Link
                                onClick={() => this.togglePageInput(isStart)}
                                className={css.ellipsis}
                                dataTn={`pagination-ellipsis-${isStart ? 'start' : 'end'}`}
                            >
                                &hellip;
                            </Link>
                            {showPageInput && (
                                <div
                                    className={css.pageInputContainer}
                                    data-tn="pagination-ellipsis"
                                >
                                    <input
                                        className={css.pageInput}
                                        type="text"
                                        data-tn="ellipsis-page-input"
                                        placeholder="page"
                                        onKeyPress={this.onPageInputKeyPress}
                                    />
                                </div>
                            )}
                        </>
                    );
                }
                break;
        }
        return (
            linkElement && (
                <li key={key} className={listClasses} data-tn="pagination-item">
                    {linkElement}
                </li>
            )
        );
    };

    render(): ReactNode {
        const { numberOfPages, page, pageTilesToDisplay } = this.props;
        const pages: Page[] = pagelinks(numberOfPages, page, pageTilesToDisplay);

        return (
            <div className={css.paginationContainer} data-tn="pagination-wrapper">
                <ul className={css.pageList}>{pages.map(pageEl => this.getPageLink(pageEl))}</ul>
            </div>
        );
    }
}
