/**
 * @generated SignedSource<<af15832529cfbd9e904a493e7e0af989>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DesignProfileStatus = "ACTIVE" | "DELETED" | "NEW" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderRoot_viewer$data = {
  readonly tradeFirms: ReadonlyArray<{
    readonly profileStatus: DesignProfileStatus | null;
    readonly publicProfile: {
      readonly designProjectsCount: number | null;
      readonly profilePhotoId: string | null;
    } | null;
    readonly serviceId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderBreadcrumbs_tradeFirm" | "ProfileHeaderData_tradeFirm">;
  } | null> | null;
  readonly " $fragmentType": "HeaderRoot_viewer";
};
export type HeaderRoot_viewer$key = {
  readonly " $data"?: HeaderRoot_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderRoot_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "firmUrlLabel"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderRoot_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "urlLabel",
          "variableName": "firmUrlLabel"
        }
      ],
      "concreteType": "TradeFirmType",
      "kind": "LinkedField",
      "name": "tradeFirms",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileHeaderBreadcrumbs_tradeFirm"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileHeaderData_tradeFirm"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeFirmPublicProfileType",
          "kind": "LinkedField",
          "name": "publicProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePhotoId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "designProjectsCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "63ca61fc140e46b86cc001b5d7135035";

export default node;
