/**
 * @generated SignedSource<<19b5dbfcac35d02c1bc6d5e002ce68ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DisplayDesignProjectTile_designProject$data = {
  readonly addressDisplayString: string | null;
  readonly coverPhoto: string | null;
  readonly name: string | null;
  readonly rootId: number | null;
  readonly serviceId: string | null;
  readonly status: string | null;
  readonly url: string | null;
  readonly " $fragmentType": "DisplayDesignProjectTile_designProject";
};
export type DisplayDesignProjectTile_designProject$key = {
  readonly " $data"?: DisplayDesignProjectTile_designProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisplayDesignProjectTile_designProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisplayDesignProjectTile_designProject",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressDisplayString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "DesignProjectCover",
  "abstractKey": null
};

(node as any).hash = "0d439a5937768316b8e1c75451afb3da";

export default node;
