import { type FC, type FocusEvent, useState } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { Input } from 'dibs-elements/exports/Input';
import { Button } from 'dibs-elements/exports/Button';
import { Link } from 'dibs-elements/exports/Link';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { DeleteArticleConfirmationModal } from './DeleteArticleConfirmationModal';

import styles from '../OverviewArticles.scss';

import { type EditableArticleType } from '../OverviewArticles';

const MAX_TITLE_LENGTH = 100;

type Props = {
    article: EditableArticleType;
    isSaving: boolean;
    onChangeTitle: (e: FocusEvent<HTMLInputElement>) => void;
    onSave: () => void;
    onCancel: () => void;
    onDelete: () => void;
};

export const Edit: FC<Props> = ({
    article,
    onChangeTitle,
    onSave,
    onCancel,
    onDelete,
    isSaving,
}) => {
    const [title, setTitle] = useState(article?.title || '');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const hasValidTitle = title.length > 0 && title.length <= MAX_TITLE_LENGTH;

    return (
        <div key={`overview-article-editing`} className={`${styles.editingArticleWrapper} colXs12`}>
            <div className={styles.editingArticleContent}>
                {article.photoPath ? (
                    <div className={styles.articleImage}>
                        <img
                            src={article.photoPath}
                            className={styles.image}
                            alt={article.title || ''}
                        />
                    </div>
                ) : null}
                <div className={styles.articleInfo}>
                    <div className={styles.editElement}>
                        <Input
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            dataTn="article-title-edit"
                            onBlur={onChangeTitle}
                            maxLength={100}
                            hasError={!hasValidTitle}
                            showIcons={false}
                            rightDecorator={
                                hasValidTitle ? null : (
                                    <span className={styles.titleCounterError}>
                                        {title.length}/{MAX_TITLE_LENGTH}
                                    </span>
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={styles.editingButtonWrapper}>
                <Button
                    className={styles.saveCancelButton}
                    dataTn="article-button-cancel"
                    size="medium"
                    onClick={onCancel}
                    type="secondary"
                >
                    <FormattedMessage
                        id="abt.designProfile.overview.article.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    className={styles.saveCancelButton}
                    dataTn="article-button-save"
                    size="medium"
                    disabled={!hasValidTitle}
                    onClick={() => {
                        if (hasValidTitle) {
                            onSave();
                        }
                    }}
                >
                    <FormattedMessage
                        id="abt.designProfile.overview.article.save"
                        defaultMessage="Save"
                    />
                </Button>
            </div>
            <Link className={styles.deleteRow} onClick={() => setIsModalOpen(true)}>
                <FormattedMessage
                    id="abt.designProfile.overview.article.remove"
                    defaultMessage="Remove"
                />
            </Link>
            {isSaving ? (
                <div className={styles.savingIndicator}>
                    <Spinner size="tiny" />
                </div>
            ) : null}
            <DeleteArticleConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                articleName={article?.title || ''}
                onDelete={() => {
                    onDelete();
                    setIsModalOpen(false);
                }}
            />
        </div>
    );
};
