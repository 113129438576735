/**
 * @generated SignedSource<<4d0cf0776305be33be59781aa0626c26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileFollowButton_viewer$data = {
  readonly tradeFirms: ReadonlyArray<{
    readonly name: string | null;
    readonly publicProfile: {
      readonly urlLabel: string | null;
    } | null;
    readonly serviceId: string | null;
  } | null> | null;
  readonly user?: {
    readonly favorites: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly anchor: {
            readonly sourceId: string | null;
          } | null;
          readonly serviceId: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ProfileFollowButton_viewer";
};
export type ProfileFollowButton_viewer$key = {
  readonly " $data"?: ProfileFollowButton_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileFollowButton_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "firmUrlLabel"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileFollowButton_viewer",
  "selections": [
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "types",
                  "value": [
                    "DESIGNER"
                  ]
                }
              ],
              "concreteType": "favoritesConnection",
              "kind": "LinkedField",
              "name": "favorites",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "favoritesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Favorite",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Anchor",
                          "kind": "LinkedField",
                          "name": "anchor",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "sourceId",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "favorites(types:[\"DESIGNER\"])"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "urlLabel",
          "variableName": "firmUrlLabel"
        }
      ],
      "concreteType": "TradeFirmType",
      "kind": "LinkedField",
      "name": "tradeFirms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeFirmPublicProfileType",
          "kind": "LinkedField",
          "name": "publicProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "989f3d73f554e899bfd5f4e1181f018b";

export default node;
