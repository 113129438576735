import { type FC, type MouseEventHandler, useContext, type ReactNode } from 'react';
import classnames from 'classnames';

import { useTab } from '../Common/Tabs/useTab';
import styles from '../Common/Tabs/tab.scss';
import { Link } from '../Link';
import { NavTabConfigContext } from '../NavTabList';
import { type Spacing } from '../Common/Tabs/sharedTypes';

type Props = {
    href: string;
    name: string;
    onClick?: MouseEventHandler;
    noFollow?: boolean;
    dataTn?: string;
    tabSpacing?: Spacing;
    children: ReactNode;
};

export const NavTab: FC<Props> = ({
    children,
    href,
    onClick,
    name,
    noFollow,
    dataTn,
    tabSpacing = 'large',
}) => {
    const { tabCallbackRef, handleClick, isActive } = useTab(name);
    const {
        activeFont = 'mediumBodyHeavy',
        inactiveFont = 'mediumBody',
        contrast = false,
    } = useContext(NavTabConfigContext);
    const font = isActive ? activeFont : inactiveFont;
    return (
        <li className={classnames(styles.listItemWrapper, styles[tabSpacing])}>
            <Link
                className={classnames(styles.tab, styles[font], {
                    [styles.active]: isActive,
                    [styles.contrast]: contrast,
                })}
                linkRef={tabCallbackRef}
                buyerLinkType="linkNoDecoration"
                onClick={e => {
                    if (onClick) {
                        onClick(e);
                    }
                    handleClick();
                }}
                href={href}
                noFollow={noFollow}
                dataTn={`${dataTn}${isActive ? '-active' : ''}`}
            >
                {children}
            </Link>
        </li>
    );
};
