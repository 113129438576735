import { useState, useEffect, useCallback, type FC } from 'react';
import { useRelayEnvironment, useFragment, graphql } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';

import { Button } from 'dibs-elements/exports/Button';

import { followProfile } from '../mutations/followProfile';
import { followPage } from '../mutations/followPage';
import { trackDesignFeature } from 'dibs-interiors-photos/exports/trackingHelpers';

import { showLoginModal } from '../../authInterface';

import {
    type ProfileFollowButton_viewer$data as Viewer,
    type ProfileFollowButton_viewer$key,
} from './__generated__/ProfileFollowButton_viewer.graphql';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

type Props = {
    viewer: ProfileFollowButton_viewer$key;
    userId?: string;
    onLogin: () => void;
};

const getFollowId = (viewer: Viewer): string | null => {
    const firmId = viewer?.tradeFirms?.[0]?.serviceId;

    const userFollows = viewer?.user?.favorites?.edges || [];

    const anchorSourceIds = userFollows
        .filter(filterNulls)
        .map(userFollow => {
            const sourceId = userFollow?.node?.anchor?.sourceId;

            if (sourceId && firmId && sourceId === firmId) {
                return userFollow?.node?.serviceId;
            }
            return null;
        })
        .filter(filterNulls);

    return anchorSourceIds?.[0] || null;
};

const isUserFollowing = (viewer: Viewer): boolean => {
    return !!getFollowId(viewer);
};

const ProfileFollowButton: FC<Props> = ({ viewer: viewerRef, userId, onLogin }) => {
    const environment = useRelayEnvironment();
    const viewer = useFragment(
        graphql`
            fragment ProfileFollowButton_viewer on Viewer {
                user(userId: $userId) @include(if: $hasUserId) {
                    favorites(types: [DESIGNER]) {
                        edges {
                            node {
                                serviceId
                                anchor {
                                    sourceId
                                }
                            }
                        }
                    }
                }
                tradeFirms(urlLabel: $firmUrlLabel) {
                    serviceId
                    name
                    publicProfile {
                        urlLabel
                    }
                }
            }
        `,
        viewerRef
    );
    const [showSpinner, setShowSpinner] = useState(false);
    const [hasError, setError] = useState(false);
    const [isInLoginFlow, setIsInLoginFlow] = useState(false);
    const isFollowing = isUserFollowing(viewer);

    const callUnfollowMutation = async (): Promise<void> => {
        const favoriteId = getFollowId(viewer);
        const page = viewer?.tradeFirms?.[0]?.publicProfile?.urlLabel;

        if (favoriteId && page && userId) {
            await followPage(environment, {
                favoriteId,
                page,
                userId,
                type: 'DESIGNER',
                action: 'REMOVE',
            });
            setShowSpinner(false);
        }
    };

    const callFollowMutation = useCallback(async () => {
        const tradeFirm = viewer?.tradeFirms?.[0];
        const designerName = tradeFirm?.name || '';
        const designerId = tradeFirm?.serviceId || '';
        const urlLabel = tradeFirm?.publicProfile?.urlLabel;
        const errors: string[] = [];

        // istanbul ignore if
        if (!userId) {
            errors.push('User attempted to follow a profile without a userId present');
        }

        if (!urlLabel) {
            errors.push('Attempted to follow a profile that did not return a URL label');
        }

        if (!designerId) {
            errors.push('Attempted to follow a profile that did not return a service ID');
        }

        if (!designerName) {
            errors.push('Attempted to follow a profile that did not return a designer name');
        }

        if (errors.length) {
            setShowSpinner(false);
            return;
        }

        await followProfile(environment, {
            userId: userId || '', // fallback empty string shouldn't be hit, since it'll get caught in the userId check above
            page: `/design-firms/${urlLabel}/`,
            designerName,
            designerId,
        });
        setShowSpinner(false);
    }, [userId, viewer, environment]);

    const onClickUnfollow = async (): Promise<void> => {
        setShowSpinner(true);
        setError(false);
        try {
            await callUnfollowMutation();
            setError(false);
        } catch (err) {
            setError(true);
        }
        setShowSpinner(false);
    };

    const onClickFollow = useCallback(async (): Promise<void> => {
        setError(false);
        setShowSpinner(true);
        if (!userId) {
            setIsInLoginFlow(true);
            await showLoginModal();
            onLogin();
            setShowSpinner(false);
            setError(false);
        } else {
            try {
                await callFollowMutation();
                setError(false);
            } catch (err) {
                setError(true);
            }
            setShowSpinner(false);
        }
        trackDesignFeature({
            action: 'follow designer',
            label: 'profile',
        });
    }, [userId, callFollowMutation, onLogin]);

    useEffect(() => {
        if (userId && viewer && isInLoginFlow) {
            callFollowMutation();
            setIsInLoginFlow(false);
        }
    }, [callFollowMutation, isInLoginFlow, userId, viewer]);

    let content = (
        <FormattedMessage id="abt.designProfile.header.share.follow" defaultMessage="Follow" />
    );
    if (isFollowing) {
        content = (
            <FormattedMessage
                id="abt.designProfile.header.share.following"
                defaultMessage="Following"
            />
        );
    } else if (showSpinner) {
        content = (
            <FormattedMessage
                id="abt.designProfile.header.share.loading"
                defaultMessage="Loading..."
            />
        );
    }
    return (
        <Button
            onClick={isFollowing ? onClickUnfollow : onClickFollow}
            dataTn={`${isFollowing ? 'unfollow' : 'follow'}-button`}
            type="secondary"
            fullWidth
            disabled={showSpinner}
            isDangerousAction={hasError}
        >
            {content}
        </Button>
    );
};

export default ProfileFollowButton;
