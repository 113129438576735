import { type FC, useState, useCallback, type ChangeEventHandler } from 'react';
import { useRelayEnvironment, useFragment, graphql } from 'react-relay';
import { defineMessages, useIntl } from 'dibs-react-intl';
import { localStorage } from 'dibs-browser-storage';

import { Link } from 'dibs-elements/exports/Link';
import { Input } from 'dibs-elements/exports/Input';
import { Spinner } from 'dibs-elements/exports/Spinner';

import Tooltip from 'dibs-coachmark-tooltip';
import Email from 'dibs-icons/exports/legacy/Email';
import Pen from '../components/Pen';

import {
    HAS_SEEN_PROFILE_PROJECTS_TOOLTIP,
    HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP,
} from '../constants';
import { profileEmailMutation } from '../mutations/profileEmail';

import styles from './ProfileContactInfo.scss';

import { type ProfileContactInfo_tradeFirm$key } from './__generated__/ProfileContactInfo_tradeFirm.graphql';

const messages = defineMessages({
    tooltipTitle: {
        id: 'abt.designProfile.header.tooltipTitle',
        defaultMessage: 'Add Firm Details',
    },
    tooltipBody1: {
        id: 'abt.designProfile.header.tooltip',
        defaultMessage:
            'Make it easy for potential new clients to contact you by adding your firm location and email address.',
    },
    tooltipBody2: {
        id: 'abt.designProfile.header.tooltip2',
        defaultMessage: 'Note: Your email address will not appear on your Portfolio.',
    },
    placeholder: {
        id: 'abt.designProfile.header.addEmail',
        defaultMessage: 'Add Your Email',
    },
});

type Props = {
    tradeFirm: ProfileContactInfo_tradeFirm$key;
    isEditMode: boolean;
};

const ProfileContactInfo: FC<Props> = ({ tradeFirm: tradeFirmRef, isEditMode }) => {
    const intl = useIntl();
    const environment = useRelayEnvironment();
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileContactInfo_tradeFirm on TradeFirmType {
                serviceId
                publicProfile {
                    email
                }
            }
        `,
        tradeFirmRef
    );
    const [isEditing, setEditing] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [hasSaveError, setSaveError] = useState(false);
    const [email, setEmail] = useState(() => tradeFirm?.publicProfile?.email || '');
    const saveEmail: ChangeEventHandler<HTMLInputElement> = useCallback(
        async event => {
            const _email = event.target.value || '';
            setEmail(_email);
            setSaving(true);
            setSaveError(false);

            // Verify email
            if (!_email.includes('@') || _email.indexOf('.', _email.indexOf('@')) < 0) {
                setSaveError(true);
                setSaving(false);
                return;
            }

            const firmId = tradeFirm?.serviceId;

            if (firmId) {
                try {
                    await profileEmailMutation(environment, { firmId, email: _email });

                    setSaving(false);
                    setEditing(false);
                } catch (err) {
                    setSaving(false);
                    setSaveError(true);
                }
                return;
            }

            setSaving(false);
            setSaveError(true);
        },
        [environment, tradeFirm]
    );

    if (!isEditMode) {
        return null;
    }
    const hasSeenProjectsTooltip = !!localStorage.getItem(HAS_SEEN_PROFILE_PROJECTS_TOOLTIP);
    const hasSeenContactInfoTooltip = !!localStorage.getItem(HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP);
    const shouldShowTooltip =
        hasSeenProjectsTooltip && !hasSeenContactInfoTooltip && !email.trim().length;
    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <Email className={styles.iconWrapper} />
                {isEditing ? (
                    <Input
                        dataTn="header-email-edit"
                        hasError={hasSaveError}
                        disabled={isSaving}
                        onBlur={saveEmail}
                        defaultValue={email}
                        className={styles.inputWrapper}
                        type="email"
                        autoFocus
                        placeholder={intl.formatMessage(messages.placeholder)}
                        rightDecorator={isSaving && <Spinner size="tiny" />}
                    />
                ) : (
                    <>
                        {shouldShowTooltip && (
                            <Tooltip
                                onClose={() =>
                                    localStorage.setItem(
                                        HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP,
                                        true
                                    )
                                }
                                title={intl.formatMessage(messages.tooltipTitle)}
                                align="right"
                                direction="top"
                            >
                                <p>{intl.formatMessage(messages.tooltipBody1)}</p>
                                <p>&nbsp;</p>
                                <p>{intl.formatMessage(messages.tooltipBody2)}</p>
                            </Tooltip>
                        )}
                        <Link onClick={() => setEditing(true)} dataTn="contact-email">
                            {email || intl.formatMessage(messages.placeholder)}
                        </Link>
                    </>
                )}
            </div>
            <Link onClick={() => setEditing(true)}>
                <Pen />
            </Link>
        </div>
    );
};

export default ProfileContactInfo;
