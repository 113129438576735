import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql, type RelayProp } from 'react-relay/legacy';
import { DeleteConfirmationModal } from '../../../shared/DeleteConfirmationModal';
import { DESIGN_PROJECT_STATUS_ACTIVE } from '../../constants';
import logError from '../../../shared/helpers/logError';
import { deleteDesignProject } from '../../mutations/deleteDesignProject';

import styles from './DeleteProjectConfirmationModal.scss';

import { type DeleteProjectConfirmationModal_designProject$data } from './__generated__/DeleteProjectConfirmationModal_designProject.graphql';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    urlLabel: string;
    designProject: DeleteProjectConfirmationModal_designProject$data;
    relay: RelayProp;
};

const DeleteProjectConfirmationModalComponent: FC<Props> = ({
    isOpen,
    onClose,
    urlLabel,
    relay,
    designProject,
}) => {
    const message =
        designProject?.status === DESIGN_PROJECT_STATUS_ACTIVE ? (
            <FormattedMessage
                id="abt.designProfile.DeleteProjectConfirmationModal.activeProjectDeleteMessage"
                defaultMessage="Are you sure you want to delete {projectName}?"
                values={{
                    projectName: <span className={styles.projectName}>{designProject?.name}</span>,
                }}
            />
        ) : (
            <FormattedMessage
                id="abt.designProfile.DeleteProjectConfirmationModal.inActiveProjectDeleteMessage"
                defaultMessage="Are you sure you want to delete the draft project?"
            />
        );

    const deleteProject = async (): Promise<void> => {
        try {
            await deleteDesignProject(relay.environment, {
                projectId: designProject?.serviceId || '',
                urlLabel,
            });
        } catch (err: $TSFixMe) {
            logError(`Failed to delete project. Error: ${err.message}`);
        }
    };

    return (
        <DeleteConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            onDelete={deleteProject}
            title={
                <FormattedMessage
                    id="bt.designProfile.DeleteProjectConfirmationModal.title"
                    defaultMessage="Delete Project"
                />
            }
            message={message}
        />
    );
};

export const DeleteProjectConfirmationModal = createFragmentContainer(
    DeleteProjectConfirmationModalComponent,
    {
        designProject: graphql`
            fragment DeleteProjectConfirmationModal_designProject on designProject {
                serviceId
                name
                status
            }
        `,
    }
);
