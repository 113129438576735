/**
 * @generated SignedSource<<609ba3cfb0324bf5c5fb1be1a345433b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileIcon_tradeFirm$data = {
  readonly name: string | null;
  readonly publicProfile: {
    readonly profilePhoto: string | null;
    readonly splashPhotoId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "ProfileIcon_tradeFirm";
};
export type ProfileIcon_tradeFirm$key = {
  readonly " $data"?: ProfileIcon_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileIcon_tradeFirm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileIcon_tradeFirm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmPublicProfileType",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "splashPhotoId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};

(node as any).hash = "c6192c4ab979e1885f3df7961c85bc48";

export default node;
