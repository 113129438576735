import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { DESIGN_PROJECT_STATUS_ACTIVE } from '../../constants';
import classnames from 'classnames';

import styles from './DesignProjectTileStatusBadge.scss';

import { type DesignProjectTileStatusBadge_designProject$data } from './__generated__/DesignProjectTileStatusBadge_designProject.graphql';

type Props = {
    designProject: DesignProjectTileStatusBadge_designProject$data;
};

const DesignProjectTileStatusBadgeComponent: FC<Props> = ({ designProject }) => {
    const isDraft = designProject?.status !== DESIGN_PROJECT_STATUS_ACTIVE;
    const isNotVisible = !designProject?.visibility;

    let label;
    let status = '';
    if (isDraft) {
        status = 'draft';
        label = (
            <FormattedMessage
                id="abt.designProfile.DesignProjectTileStatusBadge.draftLabel"
                defaultMessage="draft"
            />
        );
    } else if (isNotVisible) {
        status = 'invisible';
        label = (
            <FormattedMessage
                id="abt.designProfile.DesignProjectTileStatusBadge.invisibleLabel"
                defaultMessage="invisible"
            />
        );
    } else {
        return null;
    }

    return (
        <div
            className={classnames(styles.badge, {
                [styles.isDraft]: isDraft,
                [styles.isNotVisible]: isNotVisible && !isDraft,
            })}
            data-tn={`project-${status}`}
        >
            {label}
        </div>
    );
};

export const DesignProjectTileStatusBadge = createFragmentContainer(
    DesignProjectTileStatusBadgeComponent,
    {
        designProject: graphql`
            fragment DesignProjectTileStatusBadge_designProject on designProject {
                status
                visibility
            }
        `,
    }
);
