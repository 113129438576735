import { noticeError } from './newrelic';
/**
 * Log an error to NewRelic (if available); otherwise, log to console.error.
 * @param {Error | string} error
 * @param [string] message Optional prefix to console log.
 */
function logError(error: string | Error, message: string = 'Exception caught:'): void {
    if (typeof error === 'string') {
        error = new Error(error);
    }

    noticeError(error, message);
}

export default logError;
