// Flow types automatically removed

import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage, defineMessages, IntlContext } from 'dibs-react-intl';
import getter from 'dibs-object-getter';
import RecognizedRibbon from 'dibs-icons/exports/legacy/RecognizedRibbon';
import TrashCan from 'dibs-icons/exports/legacy/TrashCan';
import { Button } from 'dibs-elements/exports/Button';
import { SingleSelect, SelectOption } from 'dibs-elements/exports/SingleSelect';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { profileAwardsMutation } from '../mutations/profileAwards';
import { logError } from '../../helpers';
import classnames from 'classnames';
import styles from './OverviewAwards.scss';
import { noticeError } from '../../shared/helpers/newrelic';
const messages = defineMessages({
    placeholderAward: {
        id: 'abt.overview.awards.placeholder',
        defaultMessage: 'Select an Award',
    },
});
export class OverviewAwardsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAwardId: null,
            isSaving: false,
            deleteInProgressAwardId: null,
            hasError: false,
            editingIndex: null,
            isAddingAward: false,
            awards: getter(this.props.tradeFirm).at('publicProfile').value('awards') || [],
        };
        this.saveAwards = this.saveAwards.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    saveAwards() {
        if (this.state.isSaving) {
            return;
        }

        const firmId = getter(this.props.tradeFirm).value('serviceId');
        let awardIds = this.state.awards.map(award => award && award.serviceId);
        let editingIndex;

        if (this.state.editingIndex === null || this.state.editingIndex <= -1) {
            return;
        } else {
            editingIndex = this.state.editingIndex;
        }

        awardIds[editingIndex] = this.state.selectedAwardId;
        awardIds = awardIds.filter(awardId => !!awardId);
        const newAward = (getter(this.props.viewer).value('designAwardsQuery') || []).find(
            award => {
                return award && award.serviceId === this.state.selectedAwardId;
            }
        );
        const newAwards = this.state.awards.slice();
        newAwards[editingIndex] = newAward;
        this.setState(
            {
                isSaving: true,
                awards: newAwards,
            },
            () => {
                if (this.props.relay && firmId) {
                    profileAwardsMutation(this.props.relay.environment, {
                        firmId,
                        awardIds,
                    })
                        .then(() => {
                            this.setState({
                                isSaving: false,
                                hasError: false,
                                selectedAwardId: null,
                                editingIndex: null,
                                isAddingAward: false,
                            });
                        })
                        .catch(e => {
                            noticeError(e);
                            logError(e, `Error on saving awards for firm ID ${firmId}`);
                            this.setState({
                                isSaving: false,
                                hasError: true,
                            });
                        });
                } else {
                    this.setState({
                        isSaving: false,
                        hasError: true,
                    });
                }
            }
        );
    }

    onDelete = awardId => () => {
        if (this.state.isSaving) {
            return;
        }

        const firmId = getter(this.props.tradeFirm).value('serviceId');
        this.setState({
            deleteInProgressAwardId: awardId,
        });
        const newAwards = this.state.awards.filter(award => award?.serviceId !== awardId);
        const newAwardIds = newAwards.map(award => award?.serviceId).filter(Boolean);

        if (this.props.relay && firmId) {
            profileAwardsMutation(this.props.relay.environment, {
                firmId,
                awardIds: newAwardIds,
            })
                .then(() => {
                    this.setState({
                        awards: newAwards,
                        deleteInProgressAwardId: null,
                        hasError: false,
                        selectedAwardId: null,
                        editingIndex: null,
                    });
                })
                .catch(e => {
                    logError(e, `Error on deleting award for firm ID ${firmId}`);
                    this.setState({
                        deleteInProgressAwardId: null,
                        hasError: true,
                    });
                });
        } else {
            this.setState({
                deleteInProgressAwardId: null,
                hasError: true,
            });
        }
    };
    addNewAward = () => {
        if (this.state.editingIndex !== null) {
            return;
        }

        const awards = [...this.state.awards, null];
        this.setState({
            awards,
            editingIndex: awards.length - 1,
            isAddingAward: true,
            selectedAwardId: null,
        });
    };
    onStartEdit = (index, awardId) => () => {
        if (this.state.editingIndex !== null) {
            return;
        }

        if (this.props.isEditMode && !this.state.editingIndex) {
            this.setState({
                editingIndex: index,
                selectedAwardId: awardId,
                isAddingAward: false,
            });
        }
    };
    onCancel = () => {
        let awards = this.state.awards;

        if (this.state.isAddingAward) {
            awards = awards.slice(0, -1);
        }

        this.setState({
            editingIndex: null,
            selectedAwardId: null,
            isAddingAward: false,
            awards,
        });
    };
    onSelectItem = option => {
        this.setState({
            selectedAwardId: option?.value,
        });
    };
    generateOptionsForDropdown = awardId => {
        const awards = getter(this.props.viewer).value('designAwardsQuery') || [];
        const options = awards
            .filter(award => {
                if (!award || !award.serviceId || award.status !== 'ACTIVE') {
                    return false;
                }

                // always allow the current award ID
                if (awardId === award.serviceId) {
                    return true;
                }

                // only unselected awards
                const isMatched = !!this.state.awards.find(
                    selected => selected && award.serviceId === selected.serviceId
                );
                return !isMatched;
            })
            .map(_award => ({
                value: (_award && _award.serviceId) || '',
                label: (_award && _award.name) || '',
            }))
            .filter(_award => !!_award.value);
        return options;
    };

    render() {
        const intl = this.context;
        const { isEditMode } = this.props;

        if (!isEditMode && !this.state.awards.length) {
            return null;
        }

        return (
            <div className={styles.wrapper}>
                <p className={styles.title}>
                    <FormattedMessage id="abt.overview.awards.title" defaultMessage="Recognition" />
                </p>
                {this.state.awards.map((award, index) => {
                    const awardId = getter(award).value('serviceId') || null;
                    const name = getter(award).value('name') || '';

                    if (!awardId && !isEditMode) {
                        return null;
                    }

                    if (index !== this.state.editingIndex || !this.props.isEditMode) {
                        const deleteInProgress = this.state.deleteInProgressAwardId === awardId;
                        return (
                            <div
                                className={styles.awardWrapper}
                                key={`overview-award-${index}-${awardId || 'nokey'}`}
                                data-tn="overview-award"
                            >
                                <div
                                    className={classnames(styles.award, {
                                        [styles.awardEdit]: this.props.isEditMode,
                                        [styles.deleteInProgress]: deleteInProgress,
                                    })}
                                    onClick={this.onStartEdit(index, awardId)}
                                >
                                    <RecognizedRibbon className={styles.ribbon} />
                                    {name}
                                </div>
                                {this.props.isEditMode && (
                                    <span
                                        className={styles.trashCanWrapper}
                                        onClick={this.onDelete(awardId)}
                                    >
                                        {deleteInProgress ? (
                                            <Spinner size="tiny" />
                                        ) : (
                                            <TrashCan className={styles.trashCan} />
                                        )}
                                    </span>
                                )}
                            </div>
                        );
                    }

                    return (
                        <div
                            className={styles.editWrapper}
                            key={`overview-award-${index}-${awardId || 'nokey'}`}
                        >
                            <SingleSelect
                                disabled={this.state.isSaving}
                                dataTn="overview-award-dropdown"
                                value={this.state.selectedAwardId || awardId || ''}
                                onChange={this.onSelectItem}
                                options={this.generateOptionsForDropdown(awardId)}
                                placeholder={intl.formatMessage(messages.placeholderAward)}
                                clearable
                                hasAnimatedPlaceholder
                            />
                            <div className={styles.buttonRow}>
                                <Button
                                    disabled={this.state.isSaving}
                                    className={styles.button}
                                    dataTn="overview-award-cancel"
                                    type="secondary"
                                    size="medium"
                                    onClick={this.onCancel}
                                >
                                    <FormattedMessage
                                        id="abt.overview.awards.cancel"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button
                                    disabled={this.state.isSaving || !this.state.selectedAwardId}
                                    dataTn="overview-award-submit"
                                    onClick={this.saveAwards}
                                    size="medium"
                                    className={styles.button}
                                >
                                    <FormattedMessage
                                        id="abt.overview.awards.submit"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </div>
                        </div>
                    );
                })}
                {isEditMode && !this.state.isAddingAward && this.state.editingIndex === null && (
                    <div
                        className={styles.addAward}
                        onClick={this.addNewAward}
                        data-tn="add-new-award"
                    >
                        {this.state.awards.length === 0 && (
                            <RecognizedRibbon className={styles.ribbon} />
                        )}
                        <FormattedMessage
                            id="abt.overview.awards.addNew"
                            defaultMessage="Add an Award"
                        />
                    </div>
                )}
            </div>
        );
    }
}
OverviewAwardsComponent.contextType = IntlContext;
export const OverviewAwards = createFragmentContainer(OverviewAwardsComponent, {
    tradeFirm: graphql`
        fragment OverviewAwards_tradeFirm on TradeFirmType {
            serviceId
            publicProfile {
                awards {
                    serviceId
                    name
                    status
                }
            }
        }
    `,
    viewer: graphql`
        fragment OverviewAwards_viewer on Viewer {
            designAwardsQuery @include(if: $isEditMode) {
                serviceId
                name
                status
            }
        }
    `,
});
