import { type FC } from 'react';
import { GridCol } from 'dibs-elements/exports/Grid';
import { Link } from 'dibs-elements/exports/Link';
import LazyImage from 'dibs-react-lazy-image';

import styles from '../OverviewArticles.scss';

import { type EditableArticleType } from '../OverviewArticles';

type Props = {
    article: EditableArticleType;
};

export const Display: FC<Props> = ({ article }) => {
    return (
        <GridCol desktop={4} mobile={10}>
            <Link
                href={article.url || ''}
                dataTn="design-project-overview-article-link"
                underline="none"
                className={styles.articleWrapper}
            >
                {article.photoPath ? (
                    <LazyImage offsetVertical={200}>
                        <img
                            className={styles.articleImage}
                            src={`${article.photoPath}?width=540`}
                            alt={article?.title || ''}
                        />
                    </LazyImage>
                ) : (
                    <div className={styles.articleImage} /> // show empty wrapper if no image
                )}
                <div className={styles.articleTitle}>{article.title}</div>
            </Link>
        </GridCol>
    );
};

Display.displayName = 'Display';
