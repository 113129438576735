export const PAGE_TYPE_OVERVIEW = 'overview';
export const PAGE_TYPE_PROJECTS = 'projects';
export const PAGE_TYPE_FAVORITES = 'favorites';
export const PAGE_TYPE_EDIT = 'edit';
export const PAGE_TYPE_INTERNAL = 'internal';

export type PageType =
    | typeof PAGE_TYPE_OVERVIEW
    | typeof PAGE_TYPE_PROJECTS
    | typeof PAGE_TYPE_FAVORITES;

export const WEBLINK_FIELD_TYPE_WEBSITE = 'website';
export const WEBLINK_FIELD_TYPE_FACEBOOK = 'facebook';
export const WEBLINK_FIELD_TYPE_PINTEREST = 'pinterest';
export const WEBLINK_FIELD_TYPE_INSTAGRAM = 'instagram';

export type WeblinkType =
    | typeof WEBLINK_FIELD_TYPE_WEBSITE
    | typeof WEBLINK_FIELD_TYPE_FACEBOOK
    | typeof WEBLINK_FIELD_TYPE_PINTEREST
    | typeof WEBLINK_FIELD_TYPE_INSTAGRAM;

export const PROFILE_STATUS_ACTIVE = 'ACTIVE';
export const PROFILE_STATUS_NEW = 'NEW';
export const PROFILE_STATUS_PENDING = 'PENDING';
export const PROFILE_STATUS_DELETED = 'DELETED';

export type ProfileStatus =
    | typeof PROFILE_STATUS_ACTIVE
    | typeof PROFILE_STATUS_PENDING
    | typeof PROFILE_STATUS_NEW
    | typeof PROFILE_STATUS_DELETED;

export const DESIGN_PROJECT_STATUS_ACTIVE = 'ACTIVE';

export const HAS_SEEN_PROFILE_PROJECTS_TOOLTIP = 'HAS_SEEN_PROFILE_PROJECTS_TOOLTIP' as const;
export const HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP =
    'HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP' as const;
export const HAS_SEEN_PROFILE_DESCRIPTION_TOOLTIP = 'HAS_SEEN_PROFILE_DESCRIPTION_TOOLTIP' as const;
