import SV from 'server-vars';
import {
    trackEcommerce,
    trackPageview,
    type TrackingObject,
    type EcomProduct,
    type trackingConstants,
    eventNameConstants,
} from 'dibs-tracking';

type pageViewProps = {
    url?: string;
    pageType?: string;
    pageSubType?: string;
};

export const trackPageView = (trackObj: pageViewProps = {}): void => {
    const initialTrackingValues = SV.get('tracking.initialDataLayerInfo') || null;
    const pageView: TrackingObject = {
        url: window.location.href,
        additional: {},
    };
    let hasAdditionalTrackingValues = false;
    if (trackObj) {
        if (trackObj.url) {
            pageView.url = trackObj.url;
        }
        if (trackObj.pageType && trackObj.pageSubType) {
            pageView.additional = {};
            pageView.additional.pageType = trackObj.pageType;
            pageView.additional.pageSubType = trackObj.pageSubType;
            hasAdditionalTrackingValues = true;
        } else {
            hasAdditionalTrackingValues = false;
        }
    }
    if (!hasAdditionalTrackingValues && initialTrackingValues) {
        const additional: TrackingObject['additional'] = {};
        if (initialTrackingValues.pageType) {
            additional.pageType = initialTrackingValues.pageType;
        }
        if (initialTrackingValues.pageSubType) {
            additional.pageSubType = initialTrackingValues.pageSubType;
        }
        if (Object.keys(additional).length) {
            pageView.additional = additional;
        }
    }
    trackPageview(pageView);
};

type trackEcomArgs = {
    type:
        | typeof trackingConstants.ECOM_PRODUCT_IMPRESSION
        | typeof trackingConstants.ECOM_PRODUCT_CLICK;
    products: EcomProduct[] | EcomProduct;
    actionFieldList?: string;
    eventName?:
        | typeof eventNameConstants.EVENT_SELECT_ITEM
        | typeof eventNameConstants.EVENT_VIEW_ITEM_LIST;
};

export const trackEcomEvent = ({
    type,
    products,
    actionFieldList,
    eventName,
}: trackEcomArgs): void => {
    const trackObj: TrackingObject = {
        products: Array.isArray(products) ? products : [products],
        type,
        actionField: {},
        eventName,
    };
    if (actionFieldList) {
        trackObj.actionField = {
            list: actionFieldList,
        };
        if (eventName === eventNameConstants.EVENT_SELECT_ITEM) {
            trackObj.actionField.itemId = Array.isArray(products) ? products?.[0]?.id : products.id;
        }
    }
    trackEcommerce(trackObj);
};
