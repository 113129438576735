import { useContext, useLayoutEffect, useRef, useCallback, useEffect, type RefObject } from 'react';
import { useSharedTab } from './useSharedTab';
import { ActiveTabContext, TabIdsContext } from './TabProvider';
import { TabIndicatorContext } from './CommonList';

const useServerSafeEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

type UseTab = (name: string) => {
    isActive: boolean;
    isSelected: boolean;
    tabId?: string;
    panelId?: string;
    tabCallbackRef: <T extends HTMLElement | null>(htmlElement: T) => void;
    tabRef: RefObject<HTMLElement | null>;
    handleClick: () => void;
};

export const useTab: UseTab = name => {
    const { panel, tab } = useSharedTab(name, 'tab');
    const { tabIds } = useContext(TabIdsContext) || {};
    const count = Object.keys(tabIds || {}).length;
    const setIndicatorPosition = useContext(TabIndicatorContext);
    const { activeTab, selectedTab, setActiveTab, setSelectedTab } =
        useContext(ActiveTabContext) || {};

    const isActive = activeTab === name;
    const wasActive = useRef(false);
    const isSelected = selectedTab === name;
    const wasSelected = useRef(isSelected);
    const countRef = useRef(count);
    const elementRef = useRef<HTMLElement | null>(null);

    // use layoutEffect bc active/selected requires changes to DOM. if they are
    // skipped or collapsed before tabCallbackRef executes, a tab will not receive
    // active/selected treatment
    useServerSafeEffect(() => {
        if (!isActive) {
            wasActive.current = false;
        }
        if (!isSelected && wasSelected.current) {
            wasSelected.current = false;
        }
    }, [isActive, isSelected]);

    useEffect(() => {
        countRef.current = count;
    }, [count]);

    const tabCallbackRef = useCallback(
        (htmlElement: HTMLElement | null) => {
            if (htmlElement) {
                elementRef.current = htmlElement;
                // move indicator if tab is newly active or the number of tabs has changed
                if (isActive && (!wasActive.current || count !== countRef.current)) {
                    wasActive.current = true;
                    setIndicatorPosition({
                        left: htmlElement.offsetLeft,
                        width: htmlElement.offsetWidth,
                    });
                }
                // focus newly selected tabs (to support keyboard control)
                if (isSelected && !wasSelected.current) {
                    htmlElement.focus();
                    wasSelected.current = true;
                }
            }
        },
        [isSelected, isActive, count, setIndicatorPosition]
    );

    useEffect(() => {
        const RESIZE_DEBOUNCE_TIME = 75;
        let timeoutId: NodeJS.Timeout;

        const resizeHandler = (): void => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setIndicatorPosition({
                    left: elementRef.current?.offsetLeft,
                    width: elementRef.current?.offsetWidth,
                });
            }, RESIZE_DEBOUNCE_TIME);
        };

        if (isActive && elementRef.current) {
            window.addEventListener('resize', resizeHandler);

            // cleanup: remove event listener on unmount or if isActive becomes false
            return () => window.removeEventListener('resize', resizeHandler);
        }

        return () => {};
    }, [isActive, setIndicatorPosition]);

    const handleClick = useCallback(() => {
        if (setActiveTab) {
            setActiveTab(name);
        }
        if (setSelectedTab) {
            setSelectedTab(name);
        }
    }, [setActiveTab, setSelectedTab, name]);

    return {
        isActive,
        isSelected,
        tabId: tab,
        panelId: panel,
        tabCallbackRef,
        handleClick,
        tabRef: elementRef,
    };
};
