/**
 * @generated SignedSource<<e05ff9a181222ece5531dbac4c4164f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardStatusType = "ACTIVE" | "DELETED" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OverviewAwards_viewer$data = {
  readonly designAwardsQuery?: ReadonlyArray<{
    readonly name: string | null;
    readonly serviceId: string | null;
    readonly status: AwardStatusType | null;
  } | null> | null;
  readonly " $fragmentType": "OverviewAwards_viewer";
};
export type OverviewAwards_viewer$key = {
  readonly " $data"?: OverviewAwards_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewAwards_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewAwards_viewer",
  "selections": [
    {
      "condition": "isEditMode",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AwardType",
          "kind": "LinkedField",
          "name": "designAwardsQuery",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "dc0a2aabeaf6726864e10aca1d4a3557";

export default node;
