function encode(plainString = '') {
    if (!plainString) {
        return '';
    }
    const array = plainString.split(/\n|\r\n/).filter(line => line.length > 0);
    return array.length > 0 ? JSON.stringify(array) : '';
}

function decode(jsonString) {
    try {
        return JSON.parse(jsonString).join('\n\r');
    } catch (err) {
        return jsonString || '';
    }
}

module.exports = {
    encode,
    decode,
};
