/**
 * @generated SignedSource<<98300a58494201b0cdf39c4de58dd1c3>>
 * @relayHash 4f5c2a182240be0fe57889a85f38d54e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/deleteDesignProjectMutation

import { ConcreteRequest } from 'relay-runtime';
export type DeleteDesignProjectMutationInput = {
  clientMutationId?: string | null;
  projectId: string;
};
export type deleteDesignProjectMutation$variables = {
  input: DeleteDesignProjectMutationInput;
};
export type deleteDesignProjectMutation$data = {
  readonly deleteDesignProjectMutation: {
    readonly projectId: string | null;
  } | null;
};
export type deleteDesignProjectMutation = {
  response: deleteDesignProjectMutation$data;
  variables: deleteDesignProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDesignProjectMutationPayload",
    "kind": "LinkedField",
    "name": "deleteDesignProjectMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteDesignProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteDesignProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/deleteDesignProjectMutation",
    "metadata": {},
    "name": "deleteDesignProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d71836c0fe3ec009bdc57b12e25c6fd6";

export default node;
