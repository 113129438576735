export function noticeError(error: Error, errorString?: string): void {
    // @ts-ignore need to look at pulling types from '@types/new-relic-browser'
    if (window.newrelic && typeof window.newrelic.noticeError === 'function') {
        // @ts-ignore need to look at pulling types from '@types/new-relic-browser'
        window.newrelic.noticeError(error);
    } else {
        console.error(
            'Tried to invoke newrelic.noticeError when it was not present.',
            errorString,
            error
        );
    }
}
