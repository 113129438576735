import { graphql, type Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    type DisconnectFirmInstagramMutationInput,
    type disconnectInstagramMutation$data,
    type disconnectInstagramMutation,
} from './__generated__/disconnectInstagramMutation.graphql';

const mutation = graphql`
    mutation disconnectInstagramMutation($input: DisconnectFirmInstagramMutationInput!) {
        disconnectFirmInstagramMutation(input: $input) {
            tradeFirm {
                instagram {
                    username
                    feed {
                        imageUrl
                        link
                    }
                }
            }
        }
    }
`;

export default async function (
    environment: Environment,
    input: DisconnectFirmInstagramMutationInput
): Promise<disconnectInstagramMutation$data> {
    return await commitMutation<disconnectInstagramMutation>(environment, {
        variables: { input },
        mutation,
    });
}
