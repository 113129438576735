import { graphql, type Environment } from 'react-relay';
import commitMutation from 'relay-commit-mutation-promise';
import {
    type FirmDesignProfileMediaMutationInput as Input,
    type profileMediaMutation$data as Response,
    type profileMediaMutation as Mutation,
} from './__generated__/profileMediaMutation.graphql';

const mutation = graphql`
    mutation profileMediaMutation($input: FirmDesignProfileMediaMutationInput!) {
        firmDesignProfileMediaMutation(input: $input) {
            tradeFirm {
                publicProfile {
                    profilePhoto
                    profilePhotoId
                    splashPhoto
                    splashPhotoId
                }
            }
        }
    }
`;

export async function profileMediaMutation(
    environment: Environment,
    input: Input
): Promise<Response> {
    return await commitMutation<Mutation>(environment, {
        mutation,
        variables: { input },
    });
}

export default profileMediaMutation;
