import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type GlobeProps = {
    className?: string;
};

const Globe: FunctionComponent<GlobeProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'globe'}
            viewBox="0 0 250 250"
        >
            <path d="M125 0C56.3 0 .3 55.9.3 124.7c0 68.7 55.9 124.7 124.7 124.7s124.7-55.9 124.7-124.7C249.7 55.9 193.7 0 125 0zM8.3 124.7C8.3 60.3 60.7 8 125 8s116.7 52.3 116.7 116.7c0 23.1-6.7 44.6-18.4 62.8-4-9-16.6-12.7-24.8-14.4-.1-.3-11.3-1.6-24-1.5-9 .1-9.6.9-19 1.5-1.9.1-6.9-1.6-16.3-2-2.5-.1-6.6-4.2-6.6-4.2s-2.6-2.8-5.2-6.1-5.2-2.5-5.2-5.7-3.5-7.4-7.8-9c-4.3-1.6-6.1 4.9-13 4.9s-5.1-10.2-1.7-19.6c2.6-7.4 14.7-2.5 14.7-2.5 3.7 1.4 6.1 3.3 10.4 2.5 5.7-1.1 6.9 5.7 6.9 5.7 6.1 16.4 8.7-2.5 8.7-2.5l.9-11.4s.9-4.9 2.6-9 2.6-4.1 6.9-9 2.6-5.7 6.1-10.6 14.7-4.9 14.7-4.9c13-1.6 2.6-13.1 2.6-13.1-4.3-4.1-18.2-9.8-18.2-9.8-11.2-1.8-8.6-10.8-8.6-10.8 0-5.7 12.1.8 12.1.8 18.2.8 7.8-10.6 7.8-10.6-5.5-4.1-12-6.5-16.4-7.7-3.1-.9-9-2.8-10.6-3.2-8.9-2.3-12 1.9-12 1.9-9.2 6.7-21.7 7.4-21.7 7.4-5.2 0-20 1.6-20 1.6-9.5 0-15.6 10.6-16.5 17.2s-6.9 27-6.9 27c-6.1 10.6-3.5 13.1-1.7 19.6 1.7 6.5 2.6 9 3.5 17.2.4 4.1 3.5 7.8 6.4 10.4 2.9 2.7 5.8 8.6 6.3 10.5 2.3 7.3 9.5 11.2 9.5 11.2l6.6 2.8c20.6 4.4 21.4 9.3 21.5 9.2 4.3 7.4 17.8 9.6 17.8 9.6 9.4 2.8 6.4 7.6 6.4 7.6-6.6 11.6-8.5 16.7-4.6 28.1 4.5 13 7.7 20 9.3 23.2-6.3 1-12.7 1.6-19.3 1.6C60.7 241.3 8.3 189 8.3 124.7zM135.2 65c3.5 9.8 3.5 12.3 3.5 12.3s-6.1-1.6-19.1-2.5c-13-.8-9.5-7.4-9.5-7.4l5.2-4.1s7.8-4.9 16.5-8.2c8.6-3.2 0 .1 3.4 9.9z" />
        </svg>
    );
};
export default Globe;
