// Flow types automatically removed

import { commitMutation, graphql } from 'react-relay/legacy';
const mutation = graphql`
    mutation profileAwardsMutation($input: FirmDesignProfileAwardsMutationInput!) {
        firmDesignProfileAwardsMutation(input: $input) {
            tradeFirm {
                publicProfile {
                    awards {
                        serviceId
                        name
                        status
                    }
                }
            }
        }
    }
`;
export const profileAwardsMutation = (environment, args) => {
    const { firmId, awardIds } = args;
    const input = {
        serviceId: firmId,
        awardIds,
    };
    return new Promise((resolve, reject) => {
        const onCompleted = (response, errors) => {
            if (errors && errors.length) {
                reject(errors);
            }

            resolve(response);
        };

        const onError = error => {
            reject([error]);
        };

        try {
            commitMutation(environment, {
                mutation,
                variables: {
                    input,
                },
                onCompleted,
                onError,
            });
        } catch (error) {
            reject([error]);
        }
    });
};
