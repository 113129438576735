import { useId, useEffect, useRef, useContext, useState } from 'react';
import { TabIdsContext, type TabId } from './TabProvider';
let tabWarningLogged = false;

export const useSharedTab = (name: string, type: 'tab' | 'panel'): TabId => {
    const htmlId = useId();
    const nameRef = useRef(name);
    const tabIdsContext = useContext(TabIdsContext);
    const { setTabIds, tabIds } = tabIdsContext || {};
    const [tabIdState, setTabIdState] = useState({ ...tabIds?.[name] });

    if (tabIdsContext === null && !tabWarningLogged) {
        // eslint-disable-next-line no-console
        console.warn('dibs-elements | useSharedTab | useSharedTab requires TabProvider');
        tabWarningLogged = true;
    }

    useEffect(() => {
        // register tab
        if (setTabIds && htmlId) {
            setTabIds((prev = {}) => {
                const newTabIds = {
                    ...prev,
                    [name]: { ...prev[name], [type]: htmlId },
                };
                return newTabIds;
            });
        }
        // deregister tab
        return () => {
            if (setTabIds) {
                setTabIds(prev => {
                    const newTabIds = { ...prev };
                    delete newTabIds[name];
                    return newTabIds;
                });
            }
        };
    }, [setTabIds, type, htmlId, name]);

    useEffect(() => {
        // monitor for changes to tabIds, update only on value change
        const newState = { ...tabIds?.[name] };
        if (
            tabIdState.panel !== newState.panel ||
            tabIdState.tab !== newState.tab ||
            name !== nameRef.current
        ) {
            setTabIdState(newState);
        }
        nameRef.current = name;
    }, [tabIds, name, tabIdState]);

    return tabIdState;
};
