import commitMutation from 'relay-commit-mutation-promise';
import { graphql, type Environment } from 'react-relay';
import {
    type profileAddressMutation$variables as Variables,
    type profileAddressMutation$data as Response,
    type profileAddressMutation as Mutation,
} from './__generated__/profileAddressMutation.graphql';

const mutation = graphql`
    mutation profileAddressMutation($input: FirmDesignProfileAddressMutationInput!) {
        firmDesignProfileAddressMutation(input: $input) {
            tradeFirm {
                publicProfile {
                    displayLocation
                    address {
                        city
                        state
                        country
                        zipCode
                    }
                }
            }
        }
    }
`;

export async function profileAddressMutation(
    environment: Environment,
    variables: Variables
): Promise<Response> {
    return await commitMutation<Mutation>(environment, {
        variables,
        mutation,
    });
}
