import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type GearFilledProps = {
    className?: string;
};

const GearFilled: FunctionComponent<GearFilledProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'gear-filled'}
            viewBox="0 0 250 250"
        >
            <path d="M101.8 4.6s23.5 49.9 51.5 4.5l1.5-3.1s2.7-6.5 8.7-4.6L190 14.2s6.7 2.9 3.1 10.2c0 0-8.1 16.8-2 25.5 0 0 7.7 16.8 26 15.3l9.2-2.1s10.3-3.6 13.8 4.1l9.2 22.2s3.3 8.5-4.4 11.3l-11.4 5.3s-15.8 10.2-11.2 27c0 0-.9 6.9 17.9 19.9l5.6 4.1s6.1 4.1 1 12.2l-9.7 19.4s-2.1 6.5-10.8 3.7l-15.7-4.1s-4.1-.3-6.5.4c-4.4 1.2-11.2 3.7-13.8 7.7 0 0-10.2 8.2-6.6 21.4l2.1 9.7s3 9.8-2 12.2l-23.9 8.7s-6.1 2.6-10.7-4.1l-10.7-16.3s-6.1-9.2-20.4-6.6c0 0-11.7 1.5-17.3 12.8l-7.3 10.1s-2.8 5.5-9.9 2.9l-23.7-12.6s-7.4-3.6-1.8-12.5c0 0 12.1-19.5-5.2-33.7 0 0-11.7-7.1-29.6-3 0 0-9.2 4.3-13.3-2.5L.3 158.3s-1.7-6.5 3.9-9.5c.5-.3 1-.5 1.7-.7 7.6-2.6 22.4-7.7 22.4-26.5 0 0 1-13.3-15.3-22.9l-6.6-4.1S-.7 90 3.3 80.8l9.5-19.9s2.8-7 11.4-3.6l14.3 3.5s25.5 2.5 28-18.4l-.4-11.3s-.8-5.4-1.4-8.4c-1-5-1-12.3 4-13.8L87 1.1S97.7-3 101.8 4.6zm22 60.4c-33.4.1-60.4 27.1-60.3 60.5.1 33.4 27.1 60.4 60.5 60.3s60.4-27.1 60.3-60.5S157.2 65 123.8 65z" />
        </svg>
    );
};
export default GearFilled;
