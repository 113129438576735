/**
 * @generated SignedSource<<ba925ecfb973f84697c754ee7d901bcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FolderWrapper_viewer$data = {
  readonly __typename: "Viewer";
  readonly folderItemMatches?: ReadonlyArray<{
    readonly item: {
      readonly serviceId: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"Folder_folderPortfolioItems">;
  } | null> | null;
  readonly " $fragmentType": "FolderWrapper_viewer";
};
export type FolderWrapper_viewer$key = {
  readonly " $data"?: FolderWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FolderWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "selectedItemIds"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldFillInFolder"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FolderWrapper_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "condition": "shouldFillInFolder",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "folderItemMatches",
          "args": [
            {
              "kind": "Variable",
              "name": "itemIds",
              "variableName": "selectedItemIds"
            },
            {
              "kind": "Literal",
              "name": "portfolioTypes",
              "value": [
                "FOLDER"
              ]
            },
            {
              "kind": "Variable",
              "name": "userIds",
              "variableName": "userIds"
            }
          ],
          "concreteType": "PortfolioItemV2",
          "kind": "LinkedField",
          "name": "portfolioItemMatches",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Folder_folderPortfolioItems"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "4501c5db1444387d57d54585de9323cb";

export default node;
