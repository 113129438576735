/**
 * @generated SignedSource<<373a326df6388fc6f649235a561a2b0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileHeaderData_tradeFirm$data = {
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileContactInfo_tradeFirm" | "ProfileFollowContact_tradeFirm" | "ProfileIcon_tradeFirm" | "ProfileLocation_tradeFirm" | "ProfileTabs_tradeFirm" | "ProgressStatus_tradeFirm">;
  readonly " $fragmentType": "ProfileHeaderData_tradeFirm";
};
export type ProfileHeaderData_tradeFirm$key = {
  readonly " $data"?: ProfileHeaderData_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderData_tradeFirm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileHeaderData_tradeFirm",
  "selections": [
    {
      "condition": "isEditMode",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileFollowContact_tradeFirm"
        }
      ]
    },
    {
      "condition": "isEditMode",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgressStatus_tradeFirm"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileLocation_tradeFirm"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileTabs_tradeFirm"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileContactInfo_tradeFirm"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileIcon_tradeFirm"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};

(node as any).hash = "b865fda8e4bc519f88bbbd35e7159ece";

export default node;
