import { type ReactNode, type FC } from 'react';
import Pen from 'dibs-icons/exports/legacy/Pen';
import TrashCanIcon from 'dibs-icons/exports/legacy/TrashCan';
import { Link } from 'dibs-elements/exports/Link';

import styles from './EditWrapperLayout.scss';

interface Props {
    imageContent: ReactNode;
    bottomContent: ReactNode;
    onEditClick: () => void;
    onDeleteClick: () => void;
    editDataTn: string;
    deleteDataTn: string;
}

export const EditWrapperLayout: FC<Props> = ({
    imageContent,
    bottomContent,
    onDeleteClick,
    onEditClick,
    deleteDataTn,
    editDataTn,
}) => (
    <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>{imageContent}</div>
        <div className={styles.bottomWrapper}>
            <div className={styles.content}>{bottomContent}</div>
            <div className={styles.iconsContainer}>
                <Link
                    ariaLabel="Edit"
                    dataTn={editDataTn}
                    className={styles.iconWrapper}
                    onClick={onEditClick}
                >
                    <Pen className={styles.icon} />
                </Link>
                <Link
                    ariaLabel="Delete"
                    dataTn={deleteDataTn}
                    className={styles.iconWrapper}
                    onClick={onDeleteClick}
                >
                    <TrashCanIcon className={styles.icon} />
                </Link>
            </div>
        </div>
    </div>
);
