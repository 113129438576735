/**
 * @generated SignedSource<<3294f21078aaea7749d10add520ef9cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewArticles_tradeFirm$data = {
  readonly name: string | null;
  readonly publicProfile: {
    readonly articles: ReadonlyArray<{
      readonly photo: {
        readonly path: string | null;
        readonly serviceId: string | null;
      } | null;
      readonly title: string | null;
      readonly webLink: {
        readonly url: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "OverviewArticles_tradeFirm";
};
export type OverviewArticles_tradeFirm$key = {
  readonly " $data"?: OverviewArticles_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewArticles_tradeFirm">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewArticles_tradeFirm",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmPublicProfileType",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DesignFirmArticleType",
          "kind": "LinkedField",
          "name": "articles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Photo",
              "kind": "LinkedField",
              "name": "photo",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebLink",
              "kind": "LinkedField",
              "name": "webLink",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};
})();

(node as any).hash = "1b809327f58feacfa634ad696c3b70b1";

export default node;
