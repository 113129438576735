/**
 * @generated SignedSource<<812cd13dea5da4ab98fd987271710415>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoritesWrapper_viewer$data = {
  readonly tradeFirmsDisplayMode?: ReadonlyArray<{
    readonly publicProfile: {
      readonly portfoliosInflated: ReadonlyArray<{
        readonly items: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly item: {
                readonly classification: {
                  readonly categories: ReadonlyArray<{
                    readonly category: {
                      readonly browseUrl: string | null;
                      readonly key: string | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly ecommerceTrackingParams: any | null;
                readonly firstPhotoWebPath: string | null;
                readonly isContemporary: boolean | null;
                readonly isCustomizable: boolean | null;
                readonly isNewAndMadeToOrder: boolean | null;
                readonly pricing: {
                  readonly amount: {
                    readonly amount: number | null;
                  } | null;
                  readonly netPrice: {
                    readonly amount: number | null;
                  } | null;
                  readonly salePrice: {
                    readonly amount: number | null;
                  } | null;
                } | null;
                readonly seller: {
                  readonly serviceId: string | null;
                } | null;
                readonly serviceId: string | null;
                readonly title: string | null;
                readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_item" | "ItemTile_item">;
              } | null;
            } | null;
          } | null> | null;
          readonly numberOfPages: number | null;
          readonly totalResults: number | null;
        } | null;
        readonly name: string | null;
      } | null> | null;
    } | null;
    readonly serviceId: string | null;
  } | null> | null;
  readonly tradeFirmsEditMode?: ReadonlyArray<{
    readonly members: ReadonlyArray<{
      readonly userId: string | null;
    } | null> | null;
    readonly publicProfile: {
      readonly portfoliosInflated: ReadonlyArray<{
        readonly items: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly item: {
                readonly classification: {
                  readonly categories: ReadonlyArray<{
                    readonly category: {
                      readonly browseUrl: string | null;
                      readonly key: string | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly ecommerceTrackingParams: any | null;
                readonly firstPhotoWebPath: string | null;
                readonly isContemporary: boolean | null;
                readonly isCustomizable: boolean | null;
                readonly isNewAndMadeToOrder: boolean | null;
                readonly pricing: {
                  readonly amount: {
                    readonly amount: number | null;
                  } | null;
                  readonly netPrice: {
                    readonly amount: number | null;
                  } | null;
                  readonly salePrice: {
                    readonly amount: number | null;
                  } | null;
                } | null;
                readonly seller: {
                  readonly serviceId: string | null;
                } | null;
                readonly serviceId: string | null;
                readonly title: string | null;
                readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_item" | "ItemTile_item">;
              } | null;
            } | null;
          } | null> | null;
          readonly numberOfPages: number | null;
          readonly totalResults: number | null;
        } | null;
        readonly name: string | null;
      } | null> | null;
    } | null;
    readonly serviceId: string | null;
  } | null> | null;
  readonly " $fragmentType": "FavoritesWrapper_viewer";
};
export type FavoritesWrapper_viewer$key = {
  readonly " $data"?: FavoritesWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesWrapper_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "urlLabel",
    "variableName": "firmUrlLabel"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TradeFirmPublicProfileType",
  "kind": "LinkedField",
  "name": "publicProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PortfolioV2",
      "kind": "LinkedField",
      "name": "portfoliosInflated",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 60
            },
            {
              "kind": "Variable",
              "name": "page",
              "variableName": "favoritesPage"
            }
          ],
          "concreteType": "PortfolioItemsConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfPages",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResults",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfolioItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioItemV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Item",
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "FavoritesItemProvider_item"
                        },
                        {
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "isTrade",
                              "variableName": "isTrade"
                            }
                          ],
                          "kind": "FragmentSpread",
                          "name": "ItemTile_item"
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "size",
                              "value": "thumb"
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "firstPhotoWebPath",
                          "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Seller",
                          "kind": "LinkedField",
                          "name": "seller",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isContemporary",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isCustomizable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isNewAndMadeToOrder",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ItemClassification",
                          "kind": "LinkedField",
                          "name": "classification",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ItemCategory",
                              "kind": "LinkedField",
                              "name": "categories",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Category",
                                  "kind": "LinkedField",
                                  "name": "category",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "key",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "browseUrl",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ecommerceTrackingParams",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ItemPricing",
                          "kind": "LinkedField",
                          "name": "pricing",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "amount",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "netPrice",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "salePrice",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "favoritesPage"
    },
    {
      "kind": "RootArgument",
      "name": "firmUrlLabel"
    },
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    },
    {
      "kind": "RootArgument",
      "name": "isTrade"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FavoritesWrapper_viewer",
  "selections": [
    {
      "condition": "isEditMode",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "tradeFirmsDisplayMode",
          "args": (v0/*: any*/),
          "concreteType": "TradeFirmType",
          "kind": "LinkedField",
          "name": "tradeFirms",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isEditMode",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "tradeFirmsEditMode",
          "args": (v0/*: any*/),
          "concreteType": "TradeFirmType",
          "kind": "LinkedField",
          "name": "tradeFirms",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "TradeFirmMemberType",
              "kind": "LinkedField",
              "name": "members",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "c13fda0cddd848275191bca2a6621302";

export default node;
