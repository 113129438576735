import { fetchQuery_DEPRECATED as fetchQuery, graphql, type Environment } from 'react-relay/legacy';
import { getUserToken } from 'dibs-cookie-jar';
import { type EditableArticleType } from '../Overview/OverviewArticles';
import { type articleScraperQuery } from './__generated__/articleScraperQuery.graphql';

const uploadImage = async (firmId: string, imageUrl: string): Promise<Response | null> => {
    try {
        const token = getUserToken(document.cookie);
        const result = await fetch(`/soa/media-1/2/photo/TRADE_ARTICLE/url?userToken=${token}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                pathSegment: '/trade',
                photoData: {
                    apiType: 'photo',
                    owner: {
                        apiType: 'anchor',
                        sourceId: firmId,
                        sourceType: 'FIRM',
                    },
                    status: 'ACTIVE',
                },
                url: imageUrl,
            }),
        });
        return result;
    } catch (e) {
        return null; // fail silently
    }
};

const scrapeArticleQuery = graphql`
    query articleScraperQuery($url: String!) {
        viewer {
            scrapePortfolioArticle(url: $url) {
                title
                image
                canonicalUrl
            }
        }
    }
`;

export const scrapeArticle = async (
    path: string,
    firmId: string,
    environment: Environment
): Promise<{ article: EditableArticleType | null; error: Error | null }> => {
    try {
        const scrapeResponse = await fetchQuery<articleScraperQuery>(
            environment,
            scrapeArticleQuery,
            {
                url: path,
            }
        );
        const scrapeResult = scrapeResponse?.viewer?.scrapePortfolioArticle;

        if (!scrapeResult) {
            return {
                article: null,
                error: new Error('Unable to retrieve article summary.'),
            };
        }

        let imageResult;
        if (scrapeResult.image) {
            const imageResponse = await uploadImage(firmId, scrapeResult.image);
            imageResult = imageResponse ? await imageResponse.json() : null;
        }
        return {
            article: {
                title: scrapeResult?.title || '',
                url: scrapeResult?.canonicalUrl || '',
                photoId: imageResult?.result?.id || '',
                photoPath: imageResult?.result?.path || '',
            },
            error: null,
        };
    } catch (e: $TSFixMe) {
        return {
            article: null,
            error: e,
        };
    }
};
