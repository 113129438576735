import { type FC, useCallback, useState } from 'react';
import { useFragment, graphql, useRelayEnvironment } from 'react-relay';

import { defineMessages, useIntl } from 'dibs-react-intl';

import { profileAddressMutation } from '../mutations/profileAddress';

import { Link } from 'dibs-elements/exports/Link';

import LocationPin from 'dibs-icons/exports/legacy/LocationPin';

import LocationInputAutocomplete, {
    type AddressDetails,
} from '../../shared/LocationInputAutocomplete';
import Pen from '../components/Pen';

import styles from './ProfileLocation.scss';

import { type ProfileLocation_tradeFirm$key } from './__generated__/ProfileLocation_tradeFirm.graphql';

const messages = defineMessages({
    placeholder: {
        id: 'abt.designProfile.header.location.placeholder',
        defaultMessage: "Enter your firm's location",
    },
    errorMessage: {
        id: 'abt.designProfile.header.locationError',
        defaultMessage: 'Could not save location',
    },
});

const ProfileLocationComponent: FC<{
    isEditMode: boolean;
    tradeFirm: ProfileLocation_tradeFirm$key;
}> = ({ tradeFirm: tradeFirmRef, isEditMode }) => {
    const [isEditing, setEditing] = useState(false);
    const [{ isSaving, errorMessage }, setSaveStatus] = useState<{
        isSaving: boolean;
        errorMessage: string;
    }>({ isSaving: false, errorMessage: '' });
    const environment = useRelayEnvironment();
    const intl = useIntl();
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileLocation_tradeFirm on TradeFirmType {
                serviceId
                publicProfile {
                    displayLocation
                }
            }
        `,
        tradeFirmRef
    );

    const serviceId = tradeFirm?.serviceId || '';
    const [displayValue, setDisplayValue] = useState(
        tradeFirm?.publicProfile?.displayLocation || ''
    );

    const onDetailsReceived = useCallback(
        async (details: AddressDetails) => {
            setSaveStatus({ isSaving: true, errorMessage: '' });
            const addressFromDetails = {
                city: details?.city,
                state: details?.stateOrRegion,
                zipCode: details?.zipCode,
                country: details?.country,
            };
            const input = {
                serviceId: serviceId,
                ...addressFromDetails,
            };
            try {
                const result = await profileAddressMutation(environment, { input });
                setDisplayValue(
                    result?.firmDesignProfileAddressMutation?.tradeFirm?.publicProfile
                        ?.displayLocation || ''
                );
                setSaveStatus({ isSaving: false, errorMessage: '' });
            } catch (err) {
                setSaveStatus({
                    isSaving: false,
                    errorMessage: intl.formatMessage(messages.errorMessage),
                });
            }
            setEditing(false);
        },
        [serviceId, intl, environment]
    );

    let content = (
        <span data-tn="location-display" className={styles.labelText}>
            {displayValue}
        </span>
    );

    if (isEditMode && isEditing) {
        content = (
            <div className={styles.inputWrapper}>
                <LocationInputAutocomplete
                    value={displayValue}
                    placeholder={intl.formatMessage(messages.placeholder)}
                    onChange={setDisplayValue}
                    onDetailsReceived={onDetailsReceived}
                    disabled={isSaving}
                    errorMessage={errorMessage}
                    hasAnimatedPlaceholder={isSaving}
                />
            </div>
        );
    } else if (isEditMode) {
        content = (
            <Link
                dataTn="location-display"
                onClick={() => setEditing(true)}
                className={styles.labelText}
            >
                {displayValue || intl.formatMessage(messages.placeholder)}
            </Link>
        );
    }
    return (
        <div className={styles.container} data-tn="profile-location">
            <div className={styles.content}>
                <LocationPin className={styles.pin} />
                {content}
            </div>
            {isEditMode && (
                <Link onClick={() => setEditing(!isEditing)}>
                    <Pen />
                </Link>
            )}
        </div>
    );
};

export default ProfileLocationComponent;
