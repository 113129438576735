import commitMutation from 'relay-commit-mutation-promise';
import { graphql, type Environment } from 'react-relay';
import {
    type FavoriteDesignProfileInput as Input,
    type followProfileMutation$data as Response,
    type followProfileMutation as Mutation,
} from './__generated__/followProfileMutation.graphql';

const taggedQuery = graphql`
    mutation followProfileMutation($input: FavoriteDesignProfileInput!) {
        favoriteDesignProfile(input: $input) {
            user {
                favorites(types: [DESIGNER]) {
                    edges {
                        node {
                            serviceId
                            anchor {
                                sourceId
                            }
                        }
                    }
                }
            }
        }
    }
`;

export async function followProfile(environment: Environment, input: Input): Promise<Response> {
    return await commitMutation<Mutation>(environment, {
        mutation: taggedQuery,
        variables: { input },
    });
}
