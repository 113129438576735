import { Component, lazy, Suspense, type ReactElement } from 'react';
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { getBuyerId } from 'dibs-cookie-jar';
import { Button } from 'dibs-elements/exports/Button';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { addLoginHandlers } from 'dibs-buyer-layout/exports/loginHandlers';
import {
    trackContactDesignerClick,
    trackContactDesignerSubmit,
} from 'dibs-interiors-photos/exports/trackingHelpers';

import { type RelayProp } from 'react-relay/legacy';
import { type ContactDesignerModal_tradeFirm$data } from './__generated__/ContactDesignerModal_tradeFirm.graphql';
import { type ContactDesignerModalQuery } from './__generated__/ContactDesignerModalQuery.graphql';

type Props = {
    relay: RelayProp;
    tradeFirm: ContactDesignerModal_tradeFirm$data;
};

type State = {
    isOpen: boolean;
    isLoading: boolean;
    buyerId: string | null;
};

const ShareModal = lazy(
    () =>
        import(
            /* webpackChunkName: "dibsSendToFriendModal" */
            'dibs-send-to-friend-modal'
        )
);

class ContactDesignerModalComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            isOpen: false,
            buyerId: null,
        };
    }

    async componentDidMount(): Promise<void> {
        const buyerId = getBuyerId(document.cookie);

        this.setState({ buyerId }, () => {
            addLoginHandlers(() => {
                this.setState({ buyerId: getBuyerId(document.cookie) });
            });
        });

        const params = new URLSearchParams(document.location.search);
        const modal = params.get('modal');
        if (modal === 'contactFirm') {
            this.setState({ isOpen: true });
        }
    }

    onClickContactButton = async (): Promise<void> => {
        if (this.state.buyerId) {
            this.setState({ isOpen: true });
            trackContactDesignerClick({ label: 'profile' });
            return;
        }
        const { userId } = await authModalLoader.show({
            action: authModalLoader.constants.LOGIN,
            flow: authModalLoader.constants.DEFAULT_FLOW,
        });
        if (userId) {
            this.setState({ buyerId: userId, isOpen: true });
        }
    };

    handleCloseModal = (): void => {
        this.setState({ isOpen: false });
    };

    render(): ReactElement {
        const buyerId: string = this.state.buyerId || '';
        const hasBuyerId: boolean = !!buyerId;

        return (
            <Button type="primaryAlt" onClick={this.onClickContactButton} fullWidth>
                <FormattedMessage id="abt.profile.header.contact" defaultMessage="Contact" />
                {this.props.relay && (
                    <QueryRenderer<ContactDesignerModalQuery>
                        environment={this.props.relay.environment}
                        variables={{ buyerId, hasBuyerId }}
                        query={graphql`
                            query ContactDesignerModalQuery(
                                $buyerId: String!
                                $hasBuyerId: Boolean!
                            ) {
                                viewer {
                                    user(userId: $buyerId) @include(if: $hasBuyerId) {
                                        profile {
                                            email
                                        }
                                    }
                                }
                            }
                        `}
                        render={({ props }) => {
                            const buyerEmail = props?.viewer?.user?.profile?.email || '';
                            if (!buyerEmail) {
                                return null;
                            }

                            return (
                                <Suspense fallback={null}>
                                    <ShareModal
                                        relay={this.props.relay}
                                        closeModal={this.handleCloseModal}
                                        modalOpen={this.state.isOpen}
                                        senderEmail={buyerEmail}
                                        recipientEmails={[
                                            this.props.tradeFirm?.publicProfile?.email,
                                        ]}
                                        onShareSuccess={() =>
                                            trackContactDesignerSubmit({ label: 'profile' })
                                        }
                                        type="DESIGN_PROFILE"
                                    />
                                </Suspense>
                            );
                        }}
                    />
                )}
            </Button>
        );
    }
}

export const ContactDesignerModal = createFragmentContainer(ContactDesignerModalComponent, {
    tradeFirm: graphql`
        fragment ContactDesignerModal_tradeFirm on TradeFirmType {
            publicProfile {
                email
            }
        }
    `,
});
