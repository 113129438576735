import { type FC, Fragment, type ReactNode, useState } from 'react';
import { createFragmentContainer, graphql, type RelayProp } from 'react-relay/legacy';
import { GridRow } from 'dibs-elements/exports/Grid';
import { FormattedMessage, useIntl } from 'dibs-react-intl';
import classNames from 'classnames';
import { Button } from 'dibs-elements/exports/Button';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

import { scrapeArticle } from '../helpers/articleScraper';
import logError from '../../shared/helpers/logError';
import { Scrape } from './Articles/Scrape';
import { Edit } from './Articles/Edit';
import { EditOverview } from './Articles/EditOverview';

import { Display } from './Articles/Display';
import { profileArticlesMutation } from '../mutations/profileArticles';

import {
    useArticlesReducer,
    ARTICLE_SAVE_START,
    ARTICLE_SAVE_SUCCESS,
    ARTICLE_SAVE_ERROR,
    ARTICLE_EDIT_START,
    ARTICLE_EDIT_CANCEL,
    ARTICLE_SCRAPE_SUCCESS,
    ARTICLE_EDIT_NEW,
} from './helpers/useArticlesReducer';

import styles from './OverviewArticles.scss';

import { type OverviewArticles_tradeFirm$data as TradeFirmType } from './__generated__/OverviewArticles_tradeFirm.graphql';

export type EditableArticleType = {
    photoPath?: string;
    photoId?: string;
    title?: string;
    url?: string;
};

type Props = {
    isEditMode: boolean;
    tradeFirm: TradeFirmType | null;
    relay: RelayProp;
};

const SHOW_MAX_SCRAPED_ARTICLES = 3;

export const OverviewArticlesComponent: FC<Props> = ({ relay, tradeFirm, isEditMode }) => {
    const intl = useIntl();
    const { compState, dispatch } = useArticlesReducer();

    const firmId = tradeFirm?.serviceId || '';
    const articlesRelay = tradeFirm?.publicProfile?.articles || [];
    const tradeFirmName = tradeFirm?.name;

    const [articles, setArticles] = useState<EditableArticleType[]>(() =>
        articlesRelay.map(a => ({
            photoPath: a?.photo?.path || '',
            photoId: a?.photo?.serviceId || '',
            title: a?.title || '',
            url: a?.webLink?.url || '',
        }))
    );

    if (!articles.length && !isEditMode) {
        return null;
    }

    const saveArticles = async (payload: {
        articles: EditableArticleType[];
        firmId: string;
    }): Promise<void> => {
        dispatch({ type: ARTICLE_SAVE_START });

        try {
            await profileArticlesMutation(relay.environment, {
                articles: payload.articles.map(a => {
                    const { photoPath, photoId, ...otherProps } = a; // eslint-disable-line @typescript-eslint/no-unused-vars
                    // coercing photoId into a string from an int, to avoid failed save mutations
                    return { ...otherProps, photoId: photoId + '' };
                }),
                serviceId: firmId,
            });
            dispatch({ type: ARTICLE_SAVE_SUCCESS });
        } catch (e: $TSFixMe) {
            logError(e);
            dispatch({ type: ARTICLE_SAVE_ERROR });
        }
    };

    const handleEditStart = (articleIndex: number): void => {
        if (isEditMode && compState.editingIndex === -1 && !compState.editingNewArticle) {
            dispatch({ type: ARTICLE_EDIT_START, editingIndex: articleIndex });
        }
    };

    const handleEditCancel = (): void => {
        dispatch({ type: ARTICLE_EDIT_CANCEL });
    };

    const handleFieldChange = (
        index: number,
        value: string,
        fieldName: keyof EditableArticleType
    ): void => {
        if (isEditMode) {
            const newArticles = [...articles];
            newArticles[index][fieldName] = value;
            setArticles(newArticles);
        }
    };

    const handleDelete = async (index: number): Promise<void> => {
        const newArticles = [...articles.slice(0, index), ...articles.slice(index + 1)];
        await saveArticles({ articles: newArticles, firmId });
        setArticles(newArticles);
    };

    const openScraper = (): void => {
        if (isEditMode && compState.editingIndex === -1) {
            dispatch({ type: ARTICLE_EDIT_NEW });
        }
    };

    const handleScrape = async (path: string): Promise<void> => {
        dispatch({ type: ARTICLE_SAVE_START });

        const { article, error } = await scrapeArticle(path, firmId, relay.environment);

        if (article) {
            const newArticles = [...articles, article];
            setArticles(newArticles);
            dispatch({ type: ARTICLE_SCRAPE_SUCCESS, editingIndex: newArticles.length - 1 });
        } else if (error) {
            logError(error);
            dispatch({ type: ARTICLE_SAVE_ERROR });
        }
    };

    const renderScraper = (): ReactNode => {
        if (compState.editingNewArticle) {
            return (
                <Scrape
                    onCancel={handleEditCancel}
                    onChange={handleScrape}
                    isSaving={compState.saving}
                    placeholder={intl.formatMessage({
                        id: 'abt.designProfile.overview.articles.scraper.placeholder',
                        defaultMessage: 'Enter Article URL',
                    })}
                />
            );
        } else if (compState.editingIndex === -1) {
            return (
                <div className={styles.addArticleButtonWrapper}>
                    <Button
                        onClick={openScraper}
                        dataTn="add-article"
                        fullWidth
                        className={styles.addArticleButton}
                    >
                        <FormattedMessage
                            id="abt.designProfile.overview.articles.scraper.addNew"
                            defaultMessage="Add an article"
                        />
                    </Button>
                </div>
            );
        }

        return null;
    };

    const showScraper = isEditMode && articles.length < SHOW_MAX_SCRAPED_ARTICLES;

    return (
        <>
            <HeadingLevel>
                {Heading => (
                    <Heading
                        className={classNames(styles.title, {
                            [styles.editTitle]: isEditMode,
                        })}
                    >
                        <FormattedMessage
                            id="abt.designProfile.overview.articles.title"
                            defaultMessage="Articles Featuring {tradeFirmName}"
                            values={{ tradeFirmName }}
                        />
                    </Heading>
                )}
            </HeadingLevel>
            {isEditMode && (
                <div className={styles.placeholder} data-tn="edit-placeholder">
                    <FormattedMessage
                        id="abt.designProfile.overview.articles.placeholder"
                        defaultMessage="Have you been featured in Introspective or another publication? Let us know here."
                    />
                </div>
            )}
            {!!articles.length && !compState.editingNewArticle && (
                <div
                    className={classNames(styles.articlesWrapper, {
                        [styles.display]: !isEditMode,
                    })}
                    data-tn="article-list"
                >
                    <GridRow>
                        {articles.map((article, index) => {
                            if (!article.url && !isEditMode) {
                                return null;
                            }
                            let articleTile: ReactNode;
                            if (isEditMode && compState.editingIndex === index) {
                                articleTile = (
                                    <Edit
                                        article={article}
                                        isSaving={compState.saving}
                                        onChangeTitle={e =>
                                            handleFieldChange(index, e.target.value, 'title')
                                        }
                                        onSave={() => saveArticles({ articles, firmId })}
                                        onCancel={handleEditCancel}
                                        onDelete={() => handleDelete(index)}
                                    />
                                );
                            } else if (isEditMode && compState.editingIndex === -1) {
                                articleTile = (
                                    <EditOverview
                                        article={article}
                                        onEdit={() => handleEditStart(index)}
                                        onDelete={() => handleDelete(index)}
                                    />
                                );
                            } else if (!isEditMode) {
                                articleTile = <Display article={article} />;
                            }

                            return (
                                <Fragment key={`overview-article-${index}`}>{articleTile}</Fragment>
                            );
                        })}
                    </GridRow>
                </div>
            )}
            {showScraper && renderScraper()}
        </>
    );
};

export const OverviewArticles = createFragmentContainer(OverviewArticlesComponent, {
    tradeFirm: graphql`
        fragment OverviewArticles_tradeFirm on TradeFirmType {
            serviceId
            name
            publicProfile {
                articles {
                    photo {
                        serviceId
                        path
                    }
                    title
                    webLink {
                        url
                    }
                }
            }
        }
    `,
});
