// Flow types automatically removed

import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
/**
 * Returns a function that contains a scope to the ref of a new tab.
 * Allows the ability to async load a page after the tab has already
 * been opened
 */

export function prepareNewTab(initialLocation = 'about:blank') {
    const windowRef = window.open(
        handleLocaleUrl(initialLocation, GLOBAL_CLIENT_ONLY_LOCALE),
        '_blank'
    );
    return function openNewTab(nextLocation) {
        windowRef.location.href = handleLocaleUrl(nextLocation, GLOBAL_CLIENT_ONLY_LOCALE);
    };
}
