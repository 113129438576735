// Flow types automatically removed

import { commitMutation, graphql } from 'react-relay/legacy';
const mutation = graphql`
    mutation profileWeblinksMutation($input: FirmDesignProfileWeblinksMutationInput!) {
        firmDesignProfileWeblinksMutation(input: $input) {
            tradeFirm {
                publicProfile {
                    webLinks {
                        website
                        facebook
                        instagram
                        pinterest
                    }
                }
            }
        }
    }
`;
export const profileWeblinksMutation = (environment, args) => {
    const { firmId, facebook, instagram, website, pinterest } = args;
    const input = {
        serviceId: firmId,
        facebook,
        instagram,
        website,
        pinterest,
    };
    return new Promise((resolve, reject) => {
        const onCompleted = (response, errors) => {
            if (errors && errors.length) {
                reject(errors);
            }

            resolve(response);
        };

        const onError = error => {
            reject([error]);
        };

        try {
            commitMutation(environment, {
                mutation,
                variables: {
                    input,
                },
                onCompleted,
                onError,
            });
        } catch (error) {
            reject([error]);
        }
    });
};
