/**
 * @generated SignedSource<<06eb23dd677516298a142815806c62b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DesignProfileStatus = "ACTIVE" | "DELETED" | "NEW" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectsRoot_viewer$data = {
  readonly designFirm?: ReadonlyArray<{
    readonly profileStatus: DesignProfileStatus | null;
    readonly publicProfile: {
      readonly editModeDesignProjects?: ReadonlyArray<{
        readonly serviceId: string | null;
        readonly status: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"EditDesignProjectTile_designProject">;
      } | null> | null;
      readonly liveDesignProjects?: ReadonlyArray<{
        readonly serviceId: string | null;
        readonly status: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"DisplayDesignProjectTile_designProject">;
      } | null> | null;
      readonly urlLabel: string | null;
    } | null;
    readonly serviceId: string | null;
  } | null> | null;
  readonly " $fragmentType": "ProjectsRoot_viewer";
};
export type ProjectsRoot_viewer$key = {
  readonly " $data"?: ProjectsRoot_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsRoot_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "ascDesc",
  "value": "DESC"
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "MODIFIED_DATE"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "firmUrlLabel"
    },
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    },
    {
      "kind": "RootArgument",
      "name": "shouldRenderProjects"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsRoot_viewer",
  "selections": [
    {
      "condition": "shouldRenderProjects",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "designFirm",
          "args": [
            {
              "kind": "Literal",
              "name": "pageSize",
              "value": 1
            },
            {
              "kind": "Variable",
              "name": "urlLabel",
              "variableName": "firmUrlLabel"
            }
          ],
          "concreteType": "TradeFirmType",
          "kind": "LinkedField",
          "name": "tradeFirms",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profileStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TradeFirmPublicProfileType",
              "kind": "LinkedField",
              "name": "publicProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlLabel",
                  "storageKey": null
                },
                {
                  "condition": "isEditMode",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "alias": "liveDesignProjects",
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "concreteType": "DesignProjectCover",
                      "kind": "LinkedField",
                      "name": "designProjectsCover",
                      "plural": true,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "DisplayDesignProjectTile_designProject"
                        },
                        (v0/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": "designProjectsCover(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\")"
                    }
                  ]
                },
                {
                  "condition": "isEditMode",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "editModeDesignProjects",
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "status",
                          "value": [
                            "ACTIVE",
                            "PENDING"
                          ]
                        },
                        {
                          "kind": "Literal",
                          "name": "visibility",
                          "value": [
                            "Y",
                            "N"
                          ]
                        }
                      ],
                      "concreteType": "designProject",
                      "kind": "LinkedField",
                      "name": "designProjects",
                      "plural": true,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "EditDesignProjectTile_designProject"
                        },
                        (v0/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": "designProjects(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\",status:[\"ACTIVE\",\"PENDING\"],visibility:[\"Y\",\"N\"])"
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "94c23911b48fa09dbafc35443132c37e";

export default node;
