import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { useFragment, graphql } from 'react-relay';

import { NavTabList } from 'dibs-elements/exports/NavTabList';
import { NavTab } from 'dibs-elements/exports/NavTab';
import { PAGE_TYPE_FAVORITES, PAGE_TYPE_PROJECTS, PAGE_TYPE_OVERVIEW } from '../constants';

import { type PageType } from '../constants';
import { type ProfileTabs_tradeFirm$key } from './__generated__/ProfileTabs_tradeFirm.graphql';

type Props = {
    onChangeTab: (pageType: PageType) => void;
    currentTab: PageType;
    isEditMode: boolean;
    tradeFirm: ProfileTabs_tradeFirm$key;
};

const ProfileTabs: FC<Props> = ({
    isEditMode,
    currentTab,
    onChangeTab,
    tradeFirm: tradeFirmRef,
}) => {
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileTabs_tradeFirm on TradeFirmType {
                publicProfile {
                    urlLabel
                    designProjectsCount
                    portfolios {
                        itemCount
                    }
                }
            }
        `,
        tradeFirmRef
    );
    const projectCount = tradeFirm?.publicProfile?.designProjectsCount || 0;
    const favoriteItemCount = tradeFirm?.publicProfile?.portfolios?.[0]?.itemCount || 0;
    const urlLabel = tradeFirm?.publicProfile?.urlLabel || '';
    const dataTn = 'profile-tab';

    return (
        <NavTabList
            activeTab={currentTab}
            setActiveTab={(newTab: string) => onChangeTab(newTab as PageType)}
            align="center"
            underline={false}
        >
            <NavTab
                name={PAGE_TYPE_OVERVIEW}
                href={`/design-firms/${urlLabel}/`}
                onClick={e => e.preventDefault()}
                dataTn={`${dataTn}-${PAGE_TYPE_OVERVIEW}`}
            >
                <FormattedMessage
                    id="abt.designProfile.header.tab.overview"
                    defaultMessage="Profile"
                />
            </NavTab>
            {projectCount || isEditMode ? (
                <NavTab
                    name={PAGE_TYPE_PROJECTS}
                    href={`/design-firms/${urlLabel}/`}
                    noFollow
                    onClick={e => e.preventDefault()}
                    dataTn={`${dataTn}-${PAGE_TYPE_PROJECTS}`}
                >
                    <FormattedMessage
                        id="abt.designProfile.header.tab.projects"
                        defaultMessage="Projects"
                    />
                </NavTab>
            ) : null}
            {favoriteItemCount || isEditMode ? (
                <NavTab
                    name={PAGE_TYPE_FAVORITES}
                    href={`/design-firms/${urlLabel}/favorites/`}
                    noFollow
                    onClick={e => e.preventDefault()}
                    dataTn={`${dataTn}-${PAGE_TYPE_FAVORITES}`}
                >
                    <FormattedMessage
                        id="abt.designProfile.header.tab.favorites"
                        defaultMessage="Favorites"
                    />
                </NavTab>
            ) : null}
        </NavTabList>
    );
};

export default ProfileTabs;
