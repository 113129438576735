import { useReducer, type Dispatch } from 'react';

export const ARTICLE_SAVE_START = 'ARTICLE_SAVE_START';
export const ARTICLE_SAVE_SUCCESS = 'ARTICLE_SAVE_SUCCESS';
export const ARTICLE_SAVE_ERROR = 'ARTICLE_SAVE_ERROR';
export const ARTICLE_EDIT_START = 'ARTICLE_EDIT_START';
export const ARTICLE_EDIT_CANCEL = 'ARTICLE_EDIT_CANCEL';
export const ARTICLE_SCRAPE_SUCCESS = 'ARTICLE_SCRAPE_SUCCESS';
export const ARTICLE_EDIT_NEW = 'ARTICLE_EDIT_NEW';

type Action =
    | { type: typeof ARTICLE_SAVE_START }
    | { type: typeof ARTICLE_SAVE_SUCCESS }
    | { type: typeof ARTICLE_SAVE_ERROR }
    | { type: typeof ARTICLE_EDIT_START; editingIndex: number }
    | { type: typeof ARTICLE_EDIT_CANCEL }
    | { type: typeof ARTICLE_SCRAPE_SUCCESS; editingIndex: number }
    | { type: typeof ARTICLE_EDIT_NEW };

type State = {
    saving: boolean;
    editingIndex: number;
    editingNewArticle: boolean;
};

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ARTICLE_SAVE_START:
            return {
                ...state,
                saving: true,
            };
        case ARTICLE_SAVE_SUCCESS:
            return { ...state, saving: false, editingIndex: -1 };
        case ARTICLE_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                editingIndex: -1,
            };
        case ARTICLE_EDIT_START:
            return {
                ...state,
                editingIndex: action.editingIndex,
            };
        case ARTICLE_EDIT_CANCEL:
            return {
                ...state,
                editingIndex: -1,
                editingNewArticle: false,
            };
        case ARTICLE_SCRAPE_SUCCESS:
            return {
                ...state,
                saving: false,
                editingNewArticle: false,
                editingIndex: action.editingIndex,
            };
        case ARTICLE_EDIT_NEW:
            return {
                ...state,
                editingNewArticle: true,
            };
        default:
            return state;
    }
}

const initialState: State = {
    saving: false,
    editingIndex: -1,
    editingNewArticle: false,
};

type ReducerReturnType = {
    compState: State;
    dispatch: Dispatch<Action>;
};

export function useArticlesReducer(): ReducerReturnType {
    const [compState, dispatch] = useReducer(reducer, initialState);
    return {
        compState,
        dispatch,
    };
}
