// Flow types automatically removed

import { Component, Fragment } from 'react';
import classNames from 'classnames';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import getter from 'dibs-object-getter';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { profileAboutMutation } from '../mutations/profileAbout';
import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import { TextArea } from 'dibs-elements/exports/TextArea';
import { GridCol } from 'dibs-elements/exports/Grid';
import Pen from 'dibs-icons/exports/legacy/Pen';
import { logError } from '../../helpers';
import Tooltip from 'dibs-coachmark-tooltip';
import { localStorage } from 'dibs-browser-storage';
import {
    HAS_SEEN_PROFILE_PROJECTS_TOOLTIP,
    HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP,
    HAS_SEEN_PROFILE_DESCRIPTION_TOOLTIP,
} from '../constants';
import styles from './OverviewAbout.scss';
const MAX_DESCRIPTION_LENGTH = 2500;
const TEXT_AREA_ROWS = 14;
const ReadMore = (
    <FormattedMessage defaultMessage="Read More +" id="abt.designProfile.About.read_more" />
);
const ReadLess = (
    <FormattedMessage defaultMessage="Read Less -" id="abt.designProfile.About.read_less" />
);
const ABOUT_MAX_LENGTH = 700;

const createParagraphKey = (index, paragraph, prefix) => {
    const key = paragraph;

    if (key) {
        return key;
    }

    // even index 0 should be accounted for
    if (prefix && index > -1) {
        // empty line
        return `${prefix}-${index}`;
    }

    return index;
};

const createShowHideData = display => {
    let charactersLeft = ABOUT_MAX_LENGTH;
    const shown = [];
    const hidden = [];
    display.forEach((paragraph, index) => {
        if (paragraph) {
            if (index === 0) {
                // always push the first paragraph
                shown.push(paragraph);
            } else if (charactersLeft > 0 && paragraph.length <= charactersLeft) {
                shown.push(paragraph);
            } else {
                hidden.push(paragraph);
            }

            charactersLeft = charactersLeft - paragraph.length;
        }
    });
    return {
        shown,
        hidden,
    };
};

const ReadOnlyAbout = ({ isExpanded, cta, onClick, shown, hidden }) => {
    let wrapperClass = '';

    if (isExpanded) {
        wrapperClass = styles.aboutExpanded;
    }

    return (
        <Fragment>
            {shown.map((para, index) => {
                const key = createParagraphKey(index, para, 'about-shown');
                return (
                    <p key={key} className={styles.paragraph}>
                        {para}
                    </p>
                );
            })}
            <ExpandingArea
                dataTn="design-firm-about"
                showArrow={false}
                showOverflow
                expanded={isExpanded}
                wrapperClass={wrapperClass}
            >
                {hidden.map((para, index) => {
                    const key = createParagraphKey(index, para, 'about-hidden');
                    return (
                        <p key={key} className={styles.paragraph}>
                            {para}
                        </p>
                    );
                })}
            </ExpandingArea>
            {hidden.length > 0 && (
                <Link onClick={onClick} dataTn="design-firm-about-cta">
                    {cta}
                </Link>
            )}
        </Fragment>
    );
};

const EditAbout = ({ isEditing, isEditMode, isSaving, copy, editingOnBlur, editModeOnClick }) => {
    if (isEditing) {
        return (
            <div className={styles.wrapper}>
                <TextArea
                    name="overview-about-edit"
                    dataTn="overview-about-edit-data-tn"
                    onBlur={editingOnBlur}
                    value={copy.join('\n')}
                    autoFocus
                    disabled={isSaving}
                    rows={TEXT_AREA_ROWS}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                />
            </div>
        );
    } else if (isEditMode) {
        copy = copy.filter(Boolean);
        return (
            <Fragment>
                <div
                    className={classNames(styles.wrapper, styles.edit)}
                    onClick={editModeOnClick}
                    onKeyPress={editModeOnClick}
                    role="textbox"
                    tabIndex={0}
                >
                    {copy && !copy.length ? (
                        <div>
                            <FormattedMessage
                                id="abt.overview.about.placeholder"
                                defaultMessage="Provide a description of your firm here."
                            />
                        </div>
                    ) : (
                        copy.map((para, index) => {
                            const key = createParagraphKey(index, para, 'about-copy');
                            return (
                                <p key={key} className={styles.paragraph}>
                                    {para}
                                </p>
                            );
                        })
                    )}
                </div>
                <Pen className={styles.pen} />
            </Fragment>
        );
    }

    return null;
};

export class OverviewAboutComponent extends Component {
    constructor(props) {
        super(props);
        const hasSeenProjectsTooltip = !!localStorage.getItem(HAS_SEEN_PROFILE_PROJECTS_TOOLTIP);
        const hasSeenContactInfoTooltip = !!localStorage.getItem(
            HAS_SEEN_PROFILE_CONTACT_INFO_TOOLTIP
        );
        const hasSeenDescriptionTooltip = !!localStorage.getItem(
            HAS_SEEN_PROFILE_DESCRIPTION_TOOLTIP
        );
        const about = getter(this.props.tradeFirm).at('publicProfile').value('about') || '';
        const showTooltip =
            hasSeenProjectsTooltip &&
            hasSeenContactInfoTooltip &&
            !hasSeenDescriptionTooltip &&
            !about;
        this.state = {
            expandCollapseLabel: ReadMore,
            isExpanded: false,
            isEditing: false,
            isSaving: false,
            hasError: false,
            showTooltip,
            about,
        };
        this.onAboutExpandCollapseClick = this.onAboutExpandCollapseClick.bind(this);
        this.onFinishEditing = this.onFinishEditing.bind(this);
    }

    onStartEditing = () => {
        if (this.props.isEditMode) {
            this.setState({
                isEditing: true,
            });
        }
    };

    onFinishEditing(event) {
        if (!this.props.isEditMode) {
            return;
        }
        /*
         * The `about` text is passed to the backend as a JSON-encoded string, so take the
         * output from the text field, split it on any line breaks, and then sent that assemblage
         * to the BE
         *
         * eg: ["This is paragraph 1.","This is paragraph 2."]
         */

        const about = JSON.stringify((event.currentTarget.value || '').split(/\r|\n/));
        const firmId = getter(this.props.tradeFirm).value('serviceId');
        this.setState({
            isSaving: true,
        });

        if (this.props.relay && firmId) {
            profileAboutMutation(this.props.relay.environment, {
                firmId,
                about,
            })
                .then(() => {
                    this.setState({
                        isEditing: false,
                        hasError: false,
                        isSaving: false,
                    });
                })
                .catch(e => {
                    logError(e, 'Caught exception when saving about text');
                    this.setState({
                        isSaving: false,
                        hasError: true,
                    });
                    return;
                });
        }
    }

    onAboutExpandCollapseClick(event) {
        event.preventDefault();
        const { isExpanded } = this.state; // change expanded state before
        // determining which cta/label to use

        const newExpandedState = !isExpanded;
        const label = newExpandedState ? ReadLess : ReadMore;
        this.setState({
            isExpanded: newExpandedState,
            expandCollapseLabel: label,
        });
    }

    render() {
        const about = getter(this.props.tradeFirm).at('publicProfile').value('about') || '';

        // This string may or may not be JSON-encoded (see the comment above in onFinishEditing)
        let display = [about];
        let readOnlyOutput = {};

        try {
            const lastIndex = about.length - 1;

            if (about.indexOf('[') === 0 && about.indexOf(']') === lastIndex) {
                display = JSON.parse(about);
            }

            if (!this.props.isEditMode) {
                readOnlyOutput = createShowHideData(display);
            }
        } catch (e) {
            // Swallow errors on failing to parse -- some strings are still flat strings
            // without JSON encoding.
            // eslint-disable-next-line no-console
            console.warn(e);
        }

        display = display.filter(Boolean);
        const editClass =
            this.props.isEditMode && !this.state.isEditing ? styles.editContainer : '';
        const tradeFirmName = this.props.tradeFirm?.name;
        return (
            <GridCol
                tabletLandscape={8}
                mobile={12}
                mobileOptions={{
                    verticalAlign: 'stretch',
                }}
            >
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.title}>
                            <FormattedMessage
                                id="abt.designProfile.overview.about.title"
                                defaultMessage={`{isEditMode, select,
                                    true {About Your Firm}
                                    other {About {tradeFirmName}}
                                }`}
                                values={{
                                    isEditMode: this.props.isEditMode,
                                    tradeFirmName,
                                }}
                            />
                        </Heading>
                    )}
                </HeadingLevel>
                <div
                    className={editClass}
                    data-tn="about-container"
                    onClick={this.onStartEditing}
                    onKeyPress={this.onStartEditing}
                    role="button"
                    tabIndex={0}
                >
                    {this.state.showTooltip && (
                        <Tooltip
                            onClose={() => {
                                localStorage.setItem(HAS_SEEN_PROFILE_DESCRIPTION_TOOLTIP, true);
                                this.setState({
                                    showTooltip: false,
                                });
                            }}
                            title={
                                <FormattedMessage
                                    id="abt.designProfile.overview.about.tooltipTitle"
                                    defaultMessage="Tell Your Story"
                                />
                            }
                            align="right"
                            direction="top"
                        >
                            <span className={styles.tooltipBody}>
                                <FormattedMessage
                                    id="abt.designProfile.overview.about.tooltip"
                                    defaultMessage="Compose a description of your firm - include details like your design philosophy, specialty, how you got started and your process."
                                />
                            </span>
                        </Tooltip>
                    )}
                    {this.state.isEditing || this.props.isEditMode ? (
                        <EditAbout
                            isEditing={this.state.isEditing}
                            isEditMode={this.props.isEditMode}
                            isSaving={this.state.isSaving}
                            editingOnBlur={this.onFinishEditing}
                            editModeOnClick={this.onStartEditing}
                            copy={display}
                        />
                    ) : (
                        <div className={styles.wrapper}>
                            <ReadOnlyAbout
                                isExpanded={this.state.isExpanded}
                                onClick={this.onAboutExpandCollapseClick}
                                cta={this.state.expandCollapseLabel}
                                {...readOnlyOutput}
                            />
                        </div>
                    )}
                </div>
            </GridCol>
        );
    }
}
export const OverviewAbout = createFragmentContainer(OverviewAboutComponent, {
    tradeFirm: graphql`
        fragment OverviewAbout_tradeFirm on TradeFirmType {
            serviceId
            name
            publicProfile {
                about
            }
        }
    `,
});
