import { type FC, useState } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Input } from 'dibs-elements/exports/Input';
import { Button } from 'dibs-elements/exports/Button';
import { Spinner } from 'dibs-elements/exports/Spinner';

import styles from '../OverviewArticles.scss';

type Props = {
    onChange: (target: string) => void;
    onCancel: () => void;
    placeholder: string;
    isSaving: boolean;
};

export const Scrape: FC<Props> = ({ onChange, onCancel, placeholder, isSaving }) => {
    const [scrapeTarget, setScrapeTarget] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    return (
        <div className={styles.scraperEditWrapper}>
            <div className={styles.scraperInput}>
                <Input
                    dataTn="overview-article-scrape-url"
                    onBlur={e => setScrapeTarget(e.target.value)}
                    onChange={e => setIsButtonDisabled(!e.target.value)}
                    placeholder={placeholder}
                />
            </div>
            <div className={styles.saveCancelButtons}>
                <Button
                    className={styles.saveCancelButton}
                    dataTn="article-scraper-cancel"
                    onClick={onCancel}
                    size="medium"
                    type="secondary"
                >
                    <FormattedMessage
                        id="abt.designProfile.overview.articles.scraper.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    dataTn="article-scraper-save"
                    size="medium"
                    onClick={() => onChange(scrapeTarget)}
                    className={styles.saveCancelButton}
                    disabled={isButtonDisabled}
                >
                    <FormattedMessage
                        id="abt.designProfile.overview.articles.scraper.next"
                        defaultMessage="Next"
                    />
                </Button>
            </div>
            {isSaving && (
                <div className={styles.savingIndicator}>
                    <Spinner size="tiny" />
                </div>
            )}
        </div>
    );
};
