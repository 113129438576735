import { type FC, useState } from 'react';
import { GridCol } from 'dibs-elements/exports/Grid';
import LazyImage from 'dibs-react-lazy-image';
import classNames from 'classnames';

import { EditWrapperLayout } from '../../components/EditWrapperLayout/EditWrapperLayout';
import { type EditableArticleType } from '../OverviewArticles';
import { DeleteArticleConfirmationModal } from './DeleteArticleConfirmationModal';

import styles from '../OverviewArticles.scss';

interface Props {
    article: EditableArticleType;
    onDelete: () => void;
    onEdit: () => void;
}

export const EditOverview: FC<Props> = ({ article, onEdit, onDelete }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
            <GridCol desktop={4} mobile={12} mobileOptions={{ verticalAlign: 'stretch' }}>
                <EditWrapperLayout
                    onDeleteClick={() => setIsModalOpen(true)}
                    onEditClick={onEdit}
                    deleteDataTn="edit-article-delete-button"
                    editDataTn="edit-article-edit-button"
                    imageContent={
                        <LazyImage offsetVertical={200}>
                            <img
                                className={styles.articleImage}
                                src={article?.photoPath ? `${article.photoPath}?width=540` : ''}
                                alt={article?.title || ''}
                            />
                        </LazyImage>
                    }
                    bottomContent={
                        <div className={classNames(styles.articleTitle, styles.edit)}>
                            {article?.title || ''}
                        </div>
                    }
                />
            </GridCol>
            <DeleteArticleConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                articleName={article?.title || ''}
                onDelete={() => {
                    onDelete();
                    setIsModalOpen(false);
                }}
            />
        </>
    );
};
