// Flow types automatically removed

import { Component } from 'react';
import { QueryRenderer, graphql } from 'react-relay/legacy';
import { getUserType } from 'dibs-cookie-jar';
import { FavoritesWrapper } from './components/FavoritesWrapper';
import { Error, Loader } from '../shared';
import { getEnvironment } from '../relayModernEnvironment';

const getIsTrade = () => getUserType(document.cookie) === 'trade';

export class FavoritesRoot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMounted: false,
            isTrade: false,
        };
    }

    componentDidMount() {
        this.setState({
            hasMounted: true,
            isTrade: getIsTrade(),
        });
    }

    shouldComponentUpdate() {
        return !this.state.hasMounted;
    }

    render() {
        const { hasMounted, isTrade } = this.state;
        const { firmUrlLabel, isEditMode } = this.props;

        if (!hasMounted) {
            return <Loader />;
        }

        return (
            <QueryRenderer
                environment={getEnvironment()}
                variables={{
                    firmUrlLabel,
                    isEditMode,
                    isTrade,
                    favoritesPage: 1,
                }}
                query={graphql`
                    query FavoritesRootQuery(
                        $firmUrlLabel: String!
                        $isEditMode: Boolean!
                        $isTrade: Boolean!
                        $favoritesPage: Int!
                    ) {
                        viewer {
                            ...FavoritesWrapper_viewer
                        }
                    }
                `}
                render={({ error, props }) => {
                    if (error) {
                        return <Error />;
                    } else if (!props) {
                        return <Loader />;
                    } else {
                        return (
                            <FavoritesWrapper
                                {...props}
                                isEditMode={isEditMode}
                                isTrade={isTrade}
                                isMobile={this.props.isMobile}
                                firePageview={this.props.firePageview}
                            />
                        );
                    }
                }}
            />
        );
    }
}
