import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';

import styles from './ProfileHeaderBreadcrumbs.scss';

import { type ProfileHeaderBreadcrumbs_tradeFirm$key } from './__generated__/ProfileHeaderBreadcrumbs_tradeFirm.graphql';

const ProfileHeaderBreadcrumbs: FC<{
    tradeFirm: ProfileHeaderBreadcrumbs_tradeFirm$key;
}> = ({ tradeFirm: tradeFirmRef }) => {
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileHeaderBreadcrumbs_tradeFirm on TradeFirmType {
                name
            }
        `,
        tradeFirmRef
    );
    const name = tradeFirm?.name;

    return (
        <div className={styles.wrapper}>
            <Link
                className={styles.breadcrumbItem}
                href="/design-firms/"
                dataTn="profile-root-breadcrumb"
            >
                <FormattedMessage
                    id="abt.designProfile.header.rootLabel"
                    defaultMessage="Design Firms"
                />
            </Link>
            <span className={styles.breadcrumbItem}>/</span>
            <span className={styles.breadcrumbItem}>{name}</span>
        </div>
    );
};

export default ProfileHeaderBreadcrumbs;
