import { type FC } from 'react';
import classNames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import { graphql, useFragment } from 'react-relay';

import { DesignProjectTileBgImage } from './DesignProjectTileBgImage';
import { DesignProjectTileDetails } from './DesignProjectTileDetails';
import { DESIGN_PROJECT_STATUS_ACTIVE } from '../../constants';

import styles from './DisplayDesignProjectTile.scss';

import { type DisplayDesignProjectTile_designProject$key } from './__generated__/DisplayDesignProjectTile_designProject.graphql';

type Props = {
    designProject: DisplayDesignProjectTile_designProject$key;
    isOverviewTile?: boolean;
};

const DisplayDesignProjectTile: FC<Props> = ({
    designProject: designProjectRef,
    isOverviewTile,
}) => {
    const designProject = useFragment(
        graphql`
            fragment DisplayDesignProjectTile_designProject on DesignProjectCover {
                serviceId
                addressDisplayString
                name
                rootId
                status
                coverPhoto
                url
            }
        `,
        designProjectRef
    );

    if (!designProject || designProject.status !== DESIGN_PROJECT_STATUS_ACTIVE) {
        return null;
    }

    return (
        <div
            className={classNames(`${styles.container} colXs4 colMd4 colXl3`, {
                [styles.large]: isOverviewTile,
            })}
        >
            <Link
                className={classNames(styles.innerContainer, {
                    [styles.large]: isOverviewTile,
                })}
                href={designProject.url || ''}
                dataTn="design-project-tile-project-link"
            >
                <DesignProjectTileBgImage
                    coverPhotoPath={designProject.coverPhoto}
                    size={isOverviewTile ? 'large' : 'small'}
                />
                {isOverviewTile ? (
                    <h1 className={styles.title}>{designProject.name}</h1>
                ) : (
                    <div className={styles.detailsWrapper}>
                        <DesignProjectTileDetails
                            addressDisplayString={designProject.addressDisplayString}
                            name={designProject.name}
                        />
                    </div>
                )}
            </Link>
        </div>
    );
};

export default DisplayDesignProjectTile;
