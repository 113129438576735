type YesNoType = 'yes' | 'no';

type WindowOptions = {
    [key: string]: YesNoType | number;
    scrollbars: YesNoType;
    resizable: YesNoType;
    toolbar: YesNoType;
    height: number;
    width: number;
    top: number;
    left: number;
};

export type InstagramCallback = (errorMessage: Error | string | null) => void;
export interface InstagramCallbackWindow extends WindowProxy {
    instagramCallback?: InstagramCallback;
}

export function connectInstagram(
    targetUrl: string,
    callback?: InstagramCallback
): WindowProxy | null {
    const width = 665;
    const height = 500;

    const windowOptions: WindowOptions = {
        scrollbars: 'yes',
        resizable: 'no',
        toolbar: 'no',
        height: height,
        width: width,
        top: 0,
        left: 0,
    };

    const screenHeight = screen.height;
    const screenWidth = screen.width;

    const halfScreenWidth = screenWidth / 2;
    const halfWidth = width / 2;
    windowOptions.left = Math.round(halfScreenWidth - halfWidth);

    if (screenHeight > height) {
        const halfScreenHeight = screenHeight / 2;
        const halfHeight = height / 2;

        windowOptions.top = Math.round(halfScreenHeight - halfHeight);
    }

    const windowString = Object.keys(windowOptions)
        .reduce((prev: string[], curr) => {
            prev.push(`${curr}=${windowOptions[curr]}`);
            return prev;
        }, [])
        .join(',');

    /**
     * Disabled `intl-links` because it is not a dibs buyer side url.
     */
    // eslint-disable-next-line ferrum/intl-links
    const connectWindow: InstagramCallbackWindow | null = window.open(
        targetUrl,
        'instagram',
        windowString
    );

    if (callback && connectWindow) {
        const currentWindow: InstagramCallbackWindow = window;
        currentWindow.instagramCallback = callback;
    }

    return connectWindow;
}
