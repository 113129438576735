import { createContext, type FC, useContext, type ReactNode } from 'react';

export const ServerVarsContext = createContext<ServerVarsContextProviderProps>({});

export type ServerVarsContextProviderProps = {
    isDesktop?: boolean;
    isMobile?: boolean;
    isTablet?: boolean;
    originalUrl?: string;
};

export const ServerVarsContextProvider: FC<
    ServerVarsContextProviderProps & { children: ReactNode }
> = ({ children, isDesktop, isMobile, isTablet, originalUrl }) => (
    <ServerVarsContext.Provider
        value={{
            isDesktop,
            isMobile,
            isTablet,
            originalUrl,
        }}
    >
        {children}
    </ServerVarsContext.Provider>
);

export function useServerVars(): ServerVarsContextProviderProps {
    return useContext(ServerVarsContext);
}
