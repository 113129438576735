import { type FC, useState, useRef, useEffect, Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { getBuyerId } from 'dibs-cookie-jar';
import { FormattedMessage } from 'dibs-react-intl';

import { Button } from 'dibs-elements/exports/Button';

import ProfileFollowButton from './ProfileFollowButton';
import { type ProfileFollowRendererQuery } from './__generated__/ProfileFollowRendererQuery.graphql';

import styles from './ProfileFollowRenderer.scss';

type Props = {
    firmUrlLabel: string;
};

export const ProfileFollowSpinner: FC = () => (
    <Button disabled dataTn="disabled-button" fullWidth>
        <FormattedMessage
            id="abt.designProfile.header.follow.loading"
            defaultMessage="Loading..."
        />
    </Button>
);

export const ProfileFollowRendererLL: FC<Props> = ({ firmUrlLabel }) => {
    const [userId, setUserId] = useState('');
    const hasMountedRef = useRef(false);

    useEffect(() => {
        setUserId(getBuyerId(document.cookie));
        hasMountedRef.current = true;
    }, []);

    const variables = {
        firmUrlLabel,
        userId: userId || '',
        hasUserId: !!userId,
    };

    const onLogin = (): void => setUserId(getBuyerId(document.cookie) || '');

    const data = useLazyLoadQuery<ProfileFollowRendererQuery>(
        graphql`
            query ProfileFollowRendererQuery(
                $firmUrlLabel: String!
                $userId: String!
                $hasUserId: Boolean!
            ) {
                viewer {
                    ...ProfileFollowButton_viewer
                }
            }
        `,
        variables
    );
    if (!hasMountedRef.current) {
        return (
            <div className={styles.wrapper}>
                <ProfileFollowSpinner />
            </div>
        );
    }

    return (
        <Suspense
            fallback={
                <div className={styles.wrapper}>
                    <ProfileFollowSpinner />
                </div>
            }
        >
            <div className={styles.wrapper}>
                <ProfileFollowButton viewer={data.viewer} userId={userId} onLogin={onLogin} />
            </div>
        </Suspense>
    );
};

export default ProfileFollowRendererLL;
