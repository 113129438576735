// Flow types automatically removed

import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import getter from 'dibs-object-getter';
import classNames from 'classnames';
import { OverlaySpinner } from 'dibs-elements/exports/OverlaySpinner';
import { Button } from 'dibs-elements/exports/Button';
import EmptyProjectIcon from 'dibs-icons/exports/legacy/EmptyProject';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { Loader } from '../shared';
import { logError, logUnexpectedNullGraphQLError } from '../helpers';
import { getProjectEditorUrl } from './helpers/getProjectEditorUrl';
import DisplayDesignProjectTile from './components/DesignProjectTile/DisplayDesignProjectTile';
import { prepareNewTab } from '../shared/helpers/asyncOpenNewTab';
import createDesignProject from './mutations/createDesignProject';
import { PROFILE_STATUS_ACTIVE } from './constants';
import styles from './ProjectsRoot.scss';
export class ProjectsRootComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EditProjectsContainer: null,
            isLoadingEditProjectsContainer: false,
            isLoadingNewProject: false,
        };
    }

    componentDidMount() {
        if (this.props.isEditMode) {
            this.loadEditProjectsContainer();
        }
    }

    componentDidUpdate() {
        if (
            this.props.isEditMode &&
            !this.state.EditProjectsContainer &&
            !this.state.isLoadingEditProjectsContainer
        ) {
            this.loadEditProjectsContainer();
        }
    }

    loadEditProjectsContainer = () => {
        this.setState(
            {
                isLoadingEditProjectsContainer: true,
            },
            () => {
                import(
                    /* webpackChunkName: "asyncEditProjectsContainer" */
                    './components/EditProjectsContainer'
                ).then(mod => {
                    this.setState({
                        EditProjectsContainer: mod.EditProjectsContainer,
                        isLoadingEditProjectsContainer: false,
                    });
                });
            }
        );
    };
    createDesignProject = () => {
        const { isAdminMode, relay, adminHost } = this.props;
        const firmId = this.getFirmId();

        if (!firmId) {
            logUnexpectedNullGraphQLError('tradeFirm', 'serviceId');
            return;
        }

        if (relay) {
            let openProjectEditor; // TRADE-5194 - new window/tab or no new window/tab

            if (isAdminMode) {
                // only allow admin view to open in a new tab/window
                openProjectEditor = prepareNewTab();
            } else {
                // do not open a new tab/window when
                // using the buyer-side editor
                this.setState({
                    isLoadingNewProject: true,
                });
            }

            try {
                createDesignProject(relay.environment, {
                    firmId: parseInt(firmId),
                }).then(response => {
                    const projectEditorUrl = getProjectEditorUrl(
                        response?.createDesignProject?.project,
                        adminHost,
                        isAdminMode
                    );

                    if (projectEditorUrl) {
                        if (isAdminMode) {
                            openProjectEditor(projectEditorUrl);
                        } else {
                            window.location.href = handleLocaleUrl(
                                projectEditorUrl,
                                GLOBAL_CLIENT_ONLY_LOCALE
                            );
                        }
                    }
                });
            } catch (err) {
                if (!isAdminMode) {
                    this.setState({
                        isLoadingNewProject: false,
                    });
                }
            }
        }
    };
    getFirm = () => {
        return getter(this.props.viewer).at('designFirm').value(0);
    };
    getFirmId = () => {
        const designFirm = this.getFirm();
        return getter(designFirm).value('serviceId') || '';
    };

    render() {
        const { adminHost, isAdminMode, isEditMode, relay } = this.props;
        const { EditProjectsContainer } = this.state;
        const designFirm = this.getFirm();
        const profileStatus = getter(designFirm).value('profileStatus'); // this should not happen

        if (!isEditMode && profileStatus && profileStatus !== PROFILE_STATUS_ACTIVE) {
            logError(
                `Cannot view live design projects of firm with profileStatus of ${profileStatus}`
            );
            return null;
        }

        const designProjects = getter(designFirm)
            .at('publicProfile')
            .value(isEditMode ? 'editModeDesignProjects' : 'liveDesignProjects');
        const loadingNode = (
            <div className={styles.noProjectsContainer}>
                <Loader />
            </div>
        );

        if (!designProjects) {
            return loadingNode;
        } // there should be no falsy projects, this is just to satisfy flow

        const nonNullDesignProjects = (designProjects || []).filter(Boolean);

        if (isEditMode && nonNullDesignProjects.length === 0) {
            return (
                <div className={styles.noProjectsContainer} data-tn="no-projects-container">
                    <EmptyProjectIcon className={styles.emptyProjectIconContainer} />
                    <div className={styles.startAddingProjectsHeader}>
                        <FormattedMessage
                            id="abt.designProfile.ProjectsRoot.startAddingProjectsHeader"
                            defaultMessage="Start Adding Projects"
                        />
                    </div>
                    <div className={styles.startAddingProjectsSubheader}>
                        <FormattedMessage
                            id="abt.designProfile.ProjectsRoot.startAddingProjectsSubheader"
                            defaultMessage="Upload photos to your first project."
                        />
                    </div>
                    <Button onClick={this.createDesignProject} dataTn="start-adding-project-button">
                        <FormattedMessage
                            id="abt.designProfile.ProjectsRoot.startAddingProjectsCta"
                            defaultMessage="Add a Project"
                        />
                    </Button>
                </div>
            );
        }

        if (isEditMode) {
            return (
                <div className={styles.projectsContainer}>
                    {EditProjectsContainer ? (
                        <>
                            <OverlaySpinner isOpen={this.state.isLoadingNewProject} />
                            <EditProjectsContainer
                                adminHost={adminHost}
                                createDesignProject={this.createDesignProject}
                                firmId={this.getFirmId()}
                                isAdminMode={isAdminMode}
                                isClient={this.props.isClient}
                                projects={nonNullDesignProjects}
                                relay={relay}
                                urlLabel={designFirm?.publicProfile?.urlLabel}
                            />
                        </>
                    ) : (
                        loadingNode
                    )}
                </div>
            );
        }

        return (
            <div className={classNames(styles.projectsContainer, styles.display)}>
                {nonNullDesignProjects.map(project => (
                    <DisplayDesignProjectTile
                        key={getter(project).value('serviceId')}
                        designProject={project}
                    />
                ))}
            </div>
        );
    }
}
export const ProjectsRoot = createFragmentContainer(ProjectsRootComponent, {
    viewer: graphql`
        fragment ProjectsRoot_viewer on Viewer {
            designFirm: tradeFirms(urlLabel: $firmUrlLabel, pageSize: 1)
                @include(if: $shouldRenderProjects) {
                serviceId
                profileStatus
                publicProfile {
                    urlLabel
                    liveDesignProjects: designProjectsCover(orderBy: MODIFIED_DATE, ascDesc: DESC)
                        @skip(if: $isEditMode) {
                        ...DisplayDesignProjectTile_designProject
                        serviceId
                        status
                    }
                    # make sure to update args in deleteDesignProjectMutation updater
                    editModeDesignProjects: designProjects(
                        visibility: ["Y", "N"]
                        status: ["ACTIVE", "PENDING"]
                        orderBy: MODIFIED_DATE
                        ascDesc: DESC
                    ) @include(if: $isEditMode) {
                        ...EditDesignProjectTile_designProject
                        serviceId
                        status
                    }
                }
            }
        }
    `,
});
