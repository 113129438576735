import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type EmptyProjectProps = {
    className?: string;
};

const EmptyProject: FunctionComponent<EmptyProjectProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'empty-project'}
            viewBox="0 0 250 250"
        >
            <polygon points="175,80 175,90 201,90 201,141 130,141 130,151 201,151 201,240 111,240 111,215 101,215 101,240 51,240 51,172 101,172 101,191 111,191 111,145 101,145 101,162 51,162 51,90 101,90 101,114 111,114 111,90 148,90 148,80 41,80 41,250 211,250 211,80" />
            <polygon points="119,18.9 119,66 130,66 130,18.5 151,36 158,27.5 124.6,-0.4 92.1,27.3 99.2,35.7" />
        </svg>
    );
};
export default EmptyProject;
