import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type EmailProps = {
    className?: string;
};

const Email: FunctionComponent<EmailProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'email'}
            viewBox="0 0 250 250"
        >
            <path d="M0 224V26h250v198H0zm239-11V37h-8.4L126.3 160.9 19.6 37H11v176h228zm-112.9-68.7L216.4 37H34l92.1 107.3z" />
        </svg>
    );
};
export default Email;
