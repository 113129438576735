import { type FC } from 'react';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { Link } from 'dibs-elements/exports/Link';
import { Button } from 'dibs-elements/exports/Button';
import { FormattedMessage } from 'dibs-react-intl';
import CircleCheck from 'dibs-icons/exports/legacy/CircleCheck';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

import styles from './PublishedModal.scss';

type Props = {
    designProjectUrl: string;
    isOpen: boolean;
    isPublished: boolean;
    onClose: () => void;
};

const PublishedModal: FC<Props> = ({ designProjectUrl, onClose, isOpen, isPublished }) => {
    return (
        <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            modalPosition="center"
            closeOnEsc
            dataTn="published-project-modal"
        >
            <div className={styles.headerWrapper}>
                <CircleCheck className={styles.icon} />
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.title}>
                            <FormattedMessage
                                id="abt.designProfile.published.modal.title"
                                defaultMessage={`{isPublished, select,
                                    true {Project Published}
                                    other {Project Unpublished}
                                }`}
                                values={{ isPublished }}
                            />
                        </Heading>
                    )}
                </HeadingLevel>
            </div>

            <ModalBody>
                <p className={styles.bodyText}>
                    <FormattedMessage
                        id="abt.designProfile.published.modal.body"
                        defaultMessage={`{isPublished, select,
                            true {Your project is now live on 1stDibs.}
                            other {Your project is no longer visible on 1stDibs.}
                        }`}
                        values={{ isPublished }}
                    />
                </p>
                <div className={styles.buttonsWrapper}>
                    {isPublished && (
                        <Button
                            className={styles.button}
                            dataTn="published-project-view-live-link"
                            href={designProjectUrl}
                            opensInNewWindow
                        >
                            <FormattedMessage
                                id="abt.designProfile.published.modal.viewLive"
                                defaultMessage="View Live Project"
                            />
                        </Button>
                    )}
                    <Link dataTn="published-project-modal-return-link" onClick={onClose}>
                        <FormattedMessage
                            id="abt.designProfile.published.modal.continue"
                            defaultMessage="Continue Editing Portfolio"
                        />
                    </Link>
                </div>
            </ModalBody>
        </ModalContainer>
    );
};

export default PublishedModal;
