import { getBuyerToken } from 'dibs-cookie-jar';

export const PROFILE_IMAGE_TYPE = 'TRADE_PROFILE' as const;
export const SPLASH_IMAGE_TYPE = 'TRADE_SPLASH' as const;

type ProfileImage = typeof PROFILE_IMAGE_TYPE | typeof SPLASH_IMAGE_TYPE;

const getImagePath = (
    userToken: string | undefined,
    photoType: ProfileImage = 'TRADE_PROFILE'
): string => `/soa/media-1/2/photo/${photoType}?userToken=${userToken || ''}`;

type ImageType = {
    apiType: 'photo';
    dimension: {
        apiType: 'dimension';
        width: number;
        height: number;
    };
    id: string;
    mediaType: 'PHOTO';
    owner: {
        apiType: 'anchor';
        sourceId: string;
        sourceType: string;
    };
    path: 'string';
    status: 'ACTIVE';
    type: {
        apiType: 'photoType';
        id: number;
        name: ProfileImage;
    };
};

export type ImageUploadType = { hasError?: boolean; image?: ImageType; status?: number };

export async function upload(
    file: File,
    firmId: string,
    photoType: ProfileImage = PROFILE_IMAGE_TYPE
): Promise<ImageUploadType> {
    const { userToken } = getBuyerToken(document.cookie);
    const mediaJson = {
        apiType: 'photo',
        status: 'ACTIVE',
        owner: {
            apiType: 'anchor',
            sourceId: firmId,
            sourceType: 'FIRM',
        },
    };

    const formData = new FormData();
    formData.append('files', file);
    formData.append('pathSegment', '/trade');
    formData.append('mediaJson', JSON.stringify(mediaJson));

    try {
        const response = await fetch(getImagePath(userToken, photoType), {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            return { hasError: true, status: response.status };
        }
        const json = await response.json();
        const { result } = json;
        return { image: result };
    } catch (err) {
        return { hasError: true };
    }
}
export default upload;
