// Flow types automatically removed

import {
    WEBLINK_FIELD_TYPE_FACEBOOK,
    WEBLINK_FIELD_TYPE_WEBSITE,
    WEBLINK_FIELD_TYPE_INSTAGRAM,
    WEBLINK_FIELD_TYPE_PINTEREST,
} from '../constants';
export const _handleWeblink = path => {
    const prefixMatch = path.match(/^(https?)/);
    const prefix = prefixMatch && prefixMatch[0] ? '' : 'http://';
    return `${prefix}${path}`;
};
export const _handleSocialLink = (path, fieldName) => {
    let prefix = '';

    switch (fieldName) {
        case WEBLINK_FIELD_TYPE_FACEBOOK:
            if (path.indexOf('facebook.com') < 0) {
                prefix = 'https://www.facebook.com/';
            }

            break;

        case WEBLINK_FIELD_TYPE_PINTEREST:
            if (path.indexOf('pinterest.com') < 0) {
                prefix = 'https://www.pinterest.com/';
            }

            break;

        case WEBLINK_FIELD_TYPE_INSTAGRAM:
            if (path.indexOf('instagram.com') < 0) {
                prefix = 'https://www.instagram.com/';
            }

            break;
    }

    return `${prefix}${path}`;
};
export const getWebPath = (path, fieldName) => {
    if (!path || !path.length) {
        return null;
    }

    if (fieldName === WEBLINK_FIELD_TYPE_WEBSITE) {
        return _handleWeblink(path);
    }

    return _handleSocialLink(path, fieldName);
};
