/**
 * @generated SignedSource<<62928a4a261a5121cfd7fa6770a2d45f>>
 * @relayHash f88ba95894b1f560834fe455991f1ffd
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/createDesignProjectMutation

import { ConcreteRequest } from 'relay-runtime';
export type CreateDesignProjectMutationInput = {
  clientMutationId?: string | null;
  firmId: number;
};
export type createDesignProjectMutation$variables = {
  input: CreateDesignProjectMutationInput;
};
export type createDesignProjectMutation$data = {
  readonly createDesignProject: {
    readonly project: {
      readonly editUrl: string | null;
      readonly internalEditUrl: string | null;
    } | null;
  } | null;
};
export type createDesignProjectMutation = {
  response: createDesignProjectMutation$data;
  variables: createDesignProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalEditUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createDesignProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDesignProjectMutationPayload",
        "kind": "LinkedField",
        "name": "createDesignProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "designProject",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createDesignProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDesignProjectMutationPayload",
        "kind": "LinkedField",
        "name": "createDesignProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "designProject",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/createDesignProjectMutation",
    "metadata": {},
    "name": "createDesignProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e2600b22615448d9f3f0c8fd64b50023";

export default node;
