/**
 * @generated SignedSource<<ad5114bb21c092c97d2df4b9cc21d266>>
 * @relayHash 9735a0390f65c553148c04b2d2a6b0ed
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/disconnectInstagramMutation

import { ConcreteRequest } from 'relay-runtime';
export type DisconnectFirmInstagramMutationInput = {
  clientMutationId?: string | null;
  firmId: string;
};
export type disconnectInstagramMutation$variables = {
  input: DisconnectFirmInstagramMutationInput;
};
export type disconnectInstagramMutation$data = {
  readonly disconnectFirmInstagramMutation: {
    readonly tradeFirm: {
      readonly instagram: {
        readonly feed: ReadonlyArray<{
          readonly imageUrl: string | null;
          readonly link: string | null;
        } | null> | null;
        readonly username: string | null;
      } | null;
    } | null;
  } | null;
};
export type disconnectInstagramMutation = {
  response: disconnectInstagramMutation$data;
  variables: disconnectInstagramMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "disconnectInstagramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisconnectFirmInstagramMutationPayload",
        "kind": "LinkedField",
        "name": "disconnectFirmInstagramMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InstagramFeedType",
                "kind": "LinkedField",
                "name": "instagram",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstagramFeedItemType",
                    "kind": "LinkedField",
                    "name": "feed",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "disconnectInstagramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisconnectFirmInstagramMutationPayload",
        "kind": "LinkedField",
        "name": "disconnectFirmInstagramMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InstagramFeedType",
                "kind": "LinkedField",
                "name": "instagram",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstagramFeedItemType",
                    "kind": "LinkedField",
                    "name": "feed",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/disconnectInstagramMutation",
    "metadata": {},
    "name": "disconnectInstagramMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7e6e157736c1b69b440a75470b1f67ca";

export default node;
