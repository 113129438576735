import { type RecordSourceSelectorProxy } from 'relay-runtime';

const desgnProjectsQueryArgs = {
    visibility: ['Y', 'N'],
    status: ['ACTIVE', 'PENDING'],
    orderBy: 'MODIFIED_DATE',
    ascDesc: 'DESC',
};

// delete project from relay store
export const deleteDesignProjectUpdater = (
    store: RecordSourceSelectorProxy,
    args: { urlLabel: string; projectId: string }
): void => {
    const viewer = store.getRoot().getLinkedRecord('viewer');
    if (viewer) {
        const tradeFirms = viewer.getLinkedRecords('tradeFirms', { urlLabel: args.urlLabel }) || [];
        const publicProfile = tradeFirms[0]?.getLinkedRecord('publicProfile');

        if (publicProfile) {
            const designProjects =
                publicProfile.getLinkedRecords('designProjects', desgnProjectsQueryArgs) || [];
            const updatedProjects = designProjects.filter(
                project => project.getValue('serviceId') !== args.projectId
            );
            publicProfile.setLinkedRecords(
                updatedProjects,
                'designProjects',
                desgnProjectsQueryArgs
            );
        }
    }
};
