/**
 * @generated SignedSource<<5e93f9f322006f9b2dfd57be09ff04f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewRoot_viewer$data = {
  readonly tradeFirms?: ReadonlyArray<{
    readonly instagram: {
      readonly __id: string;
    } | null;
    readonly publicProfile: {
      readonly about: string | null;
      readonly articles: ReadonlyArray<{
        readonly webLink: {
          readonly url: string | null;
        } | null;
      } | null> | null;
      readonly designProjects?: ReadonlyArray<{
        readonly url: string | null;
      } | null> | null;
      readonly liveDesignProjects?: ReadonlyArray<{
        readonly serviceId: string | null;
      } | null> | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"OverviewAbout_tradeFirm" | "OverviewArticles_tradeFirm" | "OverviewAwards_tradeFirm" | "OverviewInstagram_tradeFirm" | "OverviewProjects_tradeFirm" | "OverviewWeblinks_tradeFirm">;
  } | null> | null;
  readonly tradePortfolioInstagram: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewAwards_viewer" | "OverviewInstagram_viewer">;
  readonly " $fragmentType": "OverviewRoot_viewer";
};
export type OverviewRoot_viewer$key = {
  readonly " $data"?: OverviewRoot_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewRoot_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "ascDesc",
  "value": "DESC"
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "MODIFIED_DATE"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "firmUrlLabel"
    },
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    },
    {
      "kind": "RootArgument",
      "name": "shouldRenderOverview"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewRoot_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewInstagram_viewer"
    },
    {
      "alias": "tradePortfolioInstagram",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "tradePortfolioInstagram"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"tradePortfolioInstagram\")"
    },
    {
      "condition": "shouldRenderOverview",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "urlLabel",
              "variableName": "firmUrlLabel"
            }
          ],
          "concreteType": "TradeFirmType",
          "kind": "LinkedField",
          "name": "tradeFirms",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OverviewAbout_tradeFirm"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OverviewAwards_tradeFirm"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OverviewArticles_tradeFirm"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OverviewWeblinks_tradeFirm"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OverviewInstagram_tradeFirm"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OverviewProjects_tradeFirm"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TradeFirmPublicProfileType",
              "kind": "LinkedField",
              "name": "publicProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "about",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DesignFirmArticleType",
                  "kind": "LinkedField",
                  "name": "articles",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebLink",
                      "kind": "LinkedField",
                      "name": "webLink",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "isEditMode",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "status",
                          "value": [
                            "ACTIVE",
                            "PENDING"
                          ]
                        },
                        {
                          "kind": "Literal",
                          "name": "visibility",
                          "value": [
                            "Y",
                            "N"
                          ]
                        }
                      ],
                      "concreteType": "designProject",
                      "kind": "LinkedField",
                      "name": "designProjects",
                      "plural": true,
                      "selections": (v0/*: any*/),
                      "storageKey": "designProjects(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\",status:[\"ACTIVE\",\"PENDING\"],visibility:[\"Y\",\"N\"])"
                    }
                  ]
                },
                {
                  "condition": "isEditMode",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "alias": "liveDesignProjects",
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "concreteType": "DesignProjectCover",
                      "kind": "LinkedField",
                      "name": "designProjectsCover",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "serviceId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": "designProjectsCover(ascDesc:\"DESC\",orderBy:\"MODIFIED_DATE\")"
                    }
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramFeedType",
              "kind": "LinkedField",
              "name": "instagram",
              "plural": false,
              "selections": [
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewAwards_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "ee18ac8fb6c781714e9bc47dbf070bf5";

export default node;
