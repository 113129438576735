/**
 * @generated SignedSource<<a176ef7dabd0c64a325849185a6510cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDesignProjectTile_designProject$data = {
  readonly addressDisplayString: string | null;
  readonly editUrl: string | null;
  readonly internalEditUrl: string | null;
  readonly name: string | null;
  readonly rootId: number | null;
  readonly serviceId: string | null;
  readonly status: string | null;
  readonly tradeCoverPhoto: {
    readonly path: string | null;
  } | null;
  readonly url: string | null;
  readonly visibility: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteProjectConfirmationModal_designProject" | "DesignProjectTileStatusBadge_designProject">;
  readonly " $fragmentType": "EditDesignProjectTile_designProject";
};
export type EditDesignProjectTile_designProject$key = {
  readonly " $data"?: EditDesignProjectTile_designProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDesignProjectTile_designProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDesignProjectTile_designProject",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesignProjectTileStatusBadge_designProject"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteProjectConfirmationModal_designProject"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressDisplayString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalEditUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradePhoto",
      "kind": "LinkedField",
      "name": "tradeCoverPhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "designProject",
  "abstractKey": null
};

(node as any).hash = "d93d0031d9ce9e8b66f8930bab2c9562";

export default node;
