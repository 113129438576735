import { type FC, type ReactNode, useMemo } from 'react';
import { graphql, useRelayEnvironment, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { GridCol, GridRow } from 'dibs-elements/exports/Grid';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import createDesignProjectMutation from '../mutations/createDesignProject';
import DisplayDesignProjectTile from '../components/DesignProjectTile/DisplayDesignProjectTile';
import EditDesignProjectTile from '../components/DesignProjectTile/EditDesignProjectTile';
import { NewProjectTile } from '../components/DesignProjectTile/NewProjectTile';
import { logUnexpectedNullGraphQLError } from '../../helpers';
import { getProjectEditorUrl } from '../helpers/getProjectEditorUrl';
import { type PageType, PAGE_TYPE_PROJECTS } from '../constants';
import { useServerVars } from '../../shared/ServerVarsContext';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import styles from './OverviewProjects.scss';

import { type OverviewProjects_tradeFirm$key } from './__generated__/OverviewProjects_tradeFirm.graphql';

type Props = {
    adminHost: string;
    tradeFirm: OverviewProjects_tradeFirm$key | null | undefined;
    onChangePage: (pageType: PageType) => void;
    isAdminMode: boolean;
    isEditMode: boolean;
};

const MAX_OVERVIEW_EDIT_TILES = 3;

const OverviewProjects: FC<Props> = ({
    adminHost,
    isEditMode,
    isAdminMode,
    onChangePage,
    tradeFirm: tradeFirmRef,
}) => {
    const environment = useRelayEnvironment();
    const { isDesktop, isMobile } = useServerVars();

    const tradeFirm = useFragment(
        graphql`
            fragment OverviewProjects_tradeFirm on TradeFirmType {
                ...NewProjectTile_tradeFirm
                serviceId
                name
                publicProfile {
                    urlLabel
                    liveDesignProjects: designProjectsCover(orderBy: MODIFIED_DATE, ascDesc: DESC)
                        @skip(if: $isEditMode) {
                        ...DisplayDesignProjectTile_designProject
                        serviceId
                        status
                        visibility
                    }
                    # make sure to update args in deleteDesignProjectMutation updater
                    editModeDesignProjects: designProjects(
                        visibility: ["Y", "N"]
                        status: ["ACTIVE", "PENDING"]
                        orderBy: MODIFIED_DATE
                        ascDesc: DESC
                    ) @include(if: $isEditMode) {
                        ...EditDesignProjectTile_designProject
                        serviceId
                        status
                        visibility
                    }
                }
            }
        `,
        tradeFirmRef
    );

    const tradeFirmName = tradeFirm?.name;

    const createDesignProject = async (): Promise<void> => {
        const firmId = tradeFirm?.serviceId;
        const windowRef = isAdminMode ? window.open('about:blank', '_blank') : null;

        if (!firmId) {
            logUnexpectedNullGraphQLError('tradeFirm', 'serviceId');
            return;
        }

        const response = await createDesignProjectMutation(environment, {
            firmId: parseInt(firmId),
        });
        const project = response?.createDesignProject?.project;
        const projectEditorUrl = getProjectEditorUrl(project || null, adminHost, isAdminMode);

        if (projectEditorUrl && isAdminMode && windowRef) {
            windowRef.location.href = handleLocaleUrl(projectEditorUrl, GLOBAL_CLIENT_ONLY_LOCALE);
        } else if (isAdminMode && windowRef) {
            windowRef.close();
        } else if (projectEditorUrl) {
            window.location.assign(handleLocaleUrl(projectEditorUrl, GLOBAL_CLIENT_ONLY_LOCALE));
        }
    };

    const memoizedTiles: ReactNode | ReactNode[] = useMemo(() => {
        const editProjects =
            tradeFirm?.publicProfile?.editModeDesignProjects?.filter(filterNulls) || [];
        const liveProject = (tradeFirm?.publicProfile?.liveDesignProjects || []).filter(
            filterNulls
        )?.[0];
        let tiles: ReactNode | ReactNode[];

        if (isEditMode && !!editProjects.length) {
            tiles = editProjects.slice(0, MAX_OVERVIEW_EDIT_TILES).map((project, index) => (
                <GridCol
                    mobileOptions={{ verticalAlign: 'stretch' }}
                    desktop={4}
                    key={`overview-project-tile-${project?.serviceId || index}`}
                >
                    <EditDesignProjectTile
                        adminHost={adminHost}
                        isAdminMode={isAdminMode}
                        isOverviewTile
                        designProject={project}
                        serviceId={project?.serviceId || ''}
                        urlLabel={tradeFirm?.publicProfile?.urlLabel || ''}
                    />
                </GridCol>
            ));
        } else if (!isEditMode && !!liveProject) {
            tiles = <DisplayDesignProjectTile isOverviewTile designProject={liveProject} />;
        }

        return tiles;
    }, [tradeFirm, isAdminMode, isEditMode, adminHost]);

    if (!memoizedTiles && !isEditMode) {
        return null;
    }

    const viewAllCta = (
        <Link
            dataTn="view-all-projects"
            className={styles.viewAllLink}
            onClick={() => {
                onChangePage(PAGE_TYPE_PROJECTS);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
        >
            <FormattedMessage
                id="abt.designProfile.overview.projects.viewAll"
                defaultMessage="View All Projects"
            />
        </Link>
    );

    return (
        <>
            <div className={styles.topWrapper}>
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.title}>
                            <FormattedMessage
                                id="abt.designProfile.overview.projects.title"
                                defaultMessage={`{isEditMode, select,
                                    true {Your Latest Projects}
                                    other {Latest Project by {tradeFirmName}}
                                }`}
                                values={{ isEditMode, tradeFirmName }}
                            />
                        </Heading>
                    )}
                </HeadingLevel>
                {memoizedTiles && !isMobile ? viewAllCta : null}
            </div>
            {memoizedTiles && (
                <div className={styles.tileWrapper}>
                    <GridRow>{memoizedTiles}</GridRow>
                    {isMobile && viewAllCta}
                </div>
            )}
            {isEditMode && isDesktop && (
                <NewProjectTile
                    createDesignProject={createDesignProject}
                    size={memoizedTiles ? 'medium' : 'large'}
                    tradeFirm={tradeFirm}
                />
            )}
        </>
    );
};

export default OverviewProjects;
