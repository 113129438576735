import commitMutation from 'relay-commit-mutation-promise';
import { graphql, type Environment } from 'react-relay';
import {
    type profileEmailMutation$data as Response,
    type profileEmailMutation as Mutation,
} from './__generated__/profileEmailMutation.graphql';

const mutation = graphql`
    mutation profileEmailMutation($input: DesignProfileBasicMutationInput!) {
        designProfileBasicMutation(input: $input) {
            tradeFirm {
                publicProfile {
                    email
                }
            }
        }
    }
`;

type ProfileEmailMutationArgs = {
    firmId: string;
    email: string;
};

export async function profileEmailMutation(
    environment: Environment,
    { firmId, email }: ProfileEmailMutationArgs
): Promise<Response> {
    const variables = {
        input: {
            serviceId: firmId,
            email,
        },
    };

    return await commitMutation<Mutation>(environment, {
        mutation,
        variables,
    });
}
