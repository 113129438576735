import {
    type ReactElement,
    useMemo,
    useState,
    createContext,
    type SetStateAction,
    type Dispatch,
    type ReactNode,
    type Context,
} from 'react';

export type TabId = { tab?: string; panel?: string };

export type TabIds = { [name: string]: TabId };

type Props<T extends string> = {
    activeTab: T;
    setActiveTab: (activeTab: T) => void;
    children: ReactNode;
};

type ActiveTabContextType = {
    activeTab: string;
    setActiveTab: (activeTab: string) => void;
    selectedTab?: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
};
type TabIdsContext = {
    tabIds: TabIds;
    setTabIds: Dispatch<SetStateAction<TabIds>>;
};

export const ActiveTabContext = createContext<ActiveTabContextType | null>(null);
export const TabIdsContext = createContext<TabIdsContext | null>(null);
const _ActiveTabContext = ActiveTabContext as Context<unknown>;
const _TabIdsContext = TabIdsContext as Context<unknown>;

export const TabProvider = <T extends string>({
    activeTab,
    setActiveTab,
    children,
}: Props<T>): ReactElement => {
    // keep tabpanel/tab ids in array so each tabpanel and tab can link the index of
    // their partner. array is parallel to tabpanel/tablist order.
    const [tabIds, setTabIds] = useState<TabIds>({});

    const [selectedTab, setSelectedTab] = useState(activeTab);

    // memoize bc variables are bundled in objects
    // const tabsContext = useMemo(() => ({ setTabIds, tabIds }), [tabIds]);
    const tabsContext = { setTabIds, tabIds };
    const activeTabContext = useMemo(
        () => ({ selectedTab, setSelectedTab, activeTab, setActiveTab }),
        [activeTab, setActiveTab, selectedTab, setSelectedTab]
    );
    return (
        <_TabIdsContext.Provider value={tabsContext}>
            <_ActiveTabContext.Provider value={activeTabContext}>
                {children}
            </_ActiveTabContext.Provider>
        </_TabIdsContext.Provider>
    );
};
