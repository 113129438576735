import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import logError from './shared/helpers/logError';

export const authModalFlows = authModalLoader.constants;

type Actions = typeof authModalFlows.LOGIN | typeof authModalFlows.REGISTER;
type Flows =
    | typeof authModalFlows.DESIGNER_CONNECTION_MODAL_FLOW
    | typeof authModalFlows.TRADE_APPLICATION_FLOW
    | typeof authModalFlows.SAVE_ITEM_FLOW;

type WrapperOptions = {
    action: Actions;
    flow?: Flows;
};

type Options = {
    email?: string;
    fireEntryGa?: boolean;
    label: string;
    item?: ObjectType;
    onSuccess?: ({ userId }: { userId: string }) => void;
};

type ModalResponse = { userId: string; err?: Error | null };

const triggerAuthModal =
    ({ action, flow }: WrapperOptions) =>
    (options?: Options): Promise<ModalResponse> => {
        const {
            email,
            fireEntryGa = true,
            label,
            item,
            onSuccess,
            ...additionalOptions
        } = options || {};

        return authModalLoader
            .show({
                action,
                fireEntryGa,
                flow,
                email,
                ga: {
                    label,
                },
                additionalProps: {
                    item,
                },
                ...additionalOptions,
            })
            .then((res: ModalResponse) => {
                if (!res.err && typeof onSuccess === 'function') {
                    onSuccess({ userId: res.userId });
                }
            })
            .catch((e: Error) => {
                logError(`Authentication error: ${e.message}`);
            });
    };

export const showLoginModal = triggerAuthModal({
    action: authModalFlows.LOGIN,
});
export const showRegisterModal = triggerAuthModal({
    action: authModalFlows.REGISTER,
});
export const designerConnectionLogin = triggerAuthModal({
    action: authModalFlows.LOGIN,
    flow: authModalFlows.DESIGNER_CONNECTION_MODAL_FLOW,
});
export const designerConnectionRegistration = triggerAuthModal({
    action: authModalFlows.REGISTER,
    flow: authModalFlows.DESIGNER_CONNECTION_MODAL_FLOW,
});
export const tradeAppLogin = triggerAuthModal({
    action: authModalFlows.LOGIN,
    flow: authModalFlows.TRADE_APPLICATION_FLOW,
});
export const tradeAppRegistration = triggerAuthModal({
    action: authModalFlows.REGISTER,
    flow: authModalFlows.TRADE_APPLICATION_FLOW,
});
export const favoriteItemLogin = triggerAuthModal({
    action: authModalFlows.LOGIN,
    flow: authModalFlows.SAVE_ITEM_FLOW,
});
