/**
 * @generated SignedSource<<41fb1d7d4c43b63054ed3ef62635cff2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewWeblinks_tradeFirm$data = {
  readonly publicProfile: {
    readonly webLinks: {
      readonly facebook: string | null;
      readonly instagram: string | null;
      readonly pinterest: string | null;
      readonly website: string | null;
    } | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "OverviewWeblinks_tradeFirm";
};
export type OverviewWeblinks_tradeFirm$key = {
  readonly " $data"?: OverviewWeblinks_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewWeblinks_tradeFirm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewWeblinks_tradeFirm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmPublicProfileType",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebLinkType",
          "kind": "LinkedField",
          "name": "webLinks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "facebook",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instagram",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pinterest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};

(node as any).hash = "928d8af0a8f7214ec1de02e5af71c4c2";

export default node;
