import {
    type FC,
    useState,
    type KeyboardEventHandler,
    type CSSProperties,
    createContext,
    type ReactNode,
} from 'react';
import classnames from 'classnames';
import { type Padding } from './sharedTypes';

import styles from './commonList.scss';

// use Context to enable useTab hook to set values without prop drilling
export const TabIndicatorContext = createContext<
    (position: { left?: number; width?: number }) => void
>(() => {});

type Props = {
    role?: 'tablist';
    container?: 'nav' | 'div';
    animate?: boolean;
    onKeyDown?: KeyboardEventHandler;
    underline?: boolean;
    indicatorPadding?: Padding;
    indicatorHeight?: 'none' | '1px' | '2px';
    contrast?: boolean;
    align?: 'center' | 'left';
    fullWidth?: boolean;
    overflowContainer?: boolean;
    children: ReactNode;
};

const paddingClasses = {
    none: 'nonePadding',
    xSmall: 'xSmallPadding',
    small: 'smallPadding',
} as const;

export const CommonList: FC<Props> = ({
    role,
    container: Container = 'div',
    animate,
    onKeyDown,
    children,
    underline = true,
    indicatorPadding = 'xSmall',
    indicatorHeight = '2px',
    contrast,
    align = 'left',
    fullWidth,
    overflowContainer,
}) => {
    const [{ left, width }, setIndicatorPosition] = useState<{ left?: number; width?: number }>({});
    const showIndicator = !!width && indicatorHeight !== 'none';
    // only add indicator if position values are valid. this prevents a
    // transition from executing on initial indicator render.
    const indicatorInlineStyle: CSSProperties | undefined = showIndicator
        ? {
              display: 'block',
              width: `${width}px`,
              transform: `translate3d(${left}px, 0, 0)`,
              height: indicatorHeight,
          }
        : undefined;

    return (
        <Container>
            <div
                className={classnames(styles.container, styles[align], {
                    [styles.fullWidth]: fullWidth,
                    [styles.overflowContainer]: overflowContainer,
                })}
            >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <ul
                    className={classnames(
                        styles.tabList,
                        styles[align],
                        styles[paddingClasses[indicatorPadding]],
                        { [styles.fullWidth]: fullWidth, [styles.underline]: underline }
                    )}
                    role={role}
                    onKeyDown={onKeyDown}
                >
                    <TabIndicatorContext.Provider value={setIndicatorPosition}>
                        {children}
                    </TabIndicatorContext.Provider>
                </ul>
                {showIndicator && (
                    <div
                        style={indicatorInlineStyle}
                        className={classnames(styles.indicator, {
                            [styles.animate]: animate,
                            [styles.contrast]: contrast,
                        })}
                    />
                )}
            </div>
        </Container>
    );
};
