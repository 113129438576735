// Flow types automatically removed

import { QueryRenderer } from 'react-relay/legacy';
import { ProfileRootQuery } from './ProfileRootQuery';
import { Error } from '../shared';
import { logError } from '../helpers';
import { Loader } from './ProfileLoader';
import { DesignProfileWrapper } from './DesignProfileWrapper';
import { PAGE_TYPE_OVERVIEW, PAGE_TYPE_PROJECTS } from './constants';
import { Provider } from 'react-redux';

/* eslint-disable react/prop-types */
export const generateRenderProp =
    outerProps =>
    ({ props, error }) => {
        if (error) {
            // istanbul ignore next
            logError(error);
            return <Error />;
        } else if (props) {
            return (
                <DesignProfileWrapper
                    adminHost={outerProps.adminHost}
                    isClient
                    isMobile={outerProps.isMobile}
                    isAdminMode={outerProps.isAdminMode}
                    isEditMode={outerProps.isEditMode}
                    viewer={props.viewer}
                    initialPage={outerProps.initialPage}
                    firmUrlLabel={outerProps.firmUrlLabel}
                />
            );
        }

        return <Loader />;
    };
/* eslint-enable react/prop-types */

export function DesignProfileContainer(outerProps) {
    const { initialPage, firmUrlLabel, environment, isEditMode, store } = outerProps; // isTrade prop not used in query, needed to pass down to other components

    return (
        <Provider store={store}>
            <QueryRenderer
                fetchPolicy="store-or-network"
                environment={environment}
                query={ProfileRootQuery}
                variables={{
                    firmUrlLabel,
                    shouldRenderOverview: initialPage === PAGE_TYPE_OVERVIEW,
                    shouldRenderProjects: initialPage === PAGE_TYPE_PROJECTS,
                    isEditMode,
                }}
                render={generateRenderProp(outerProps)}
            />
        </Provider>
    );
}
