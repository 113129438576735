import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ContactDesignerModal } from './ContactDesignerModal';
import ProfileFollowRenderer from './ProfileFollowRenderer';

import styles from './ProfileFollowContact.scss';

import { type ProfileFollowContact_tradeFirm$key } from './__generated__/ProfileFollowContact_tradeFirm.graphql';

type Props = {
    isEditMode: boolean;
    tradeFirm: ProfileFollowContact_tradeFirm$key;
};

const ProfileFollowContact: FC<Props> = ({ isEditMode, tradeFirm: tradeFirmRef }) => {
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileFollowContact_tradeFirm on TradeFirmType {
                ...ContactDesignerModal_tradeFirm
                publicProfile {
                    urlLabel
                }
            }
        `,
        tradeFirmRef
    );

    if (isEditMode) {
        return null;
    }
    return (
        <div className={styles.wrapper}>
            {/* these wrapping divs are necessary because
                - the component is deeply nested
                - is rendered server-side
                - doesn't know at this point if it's mobile or desktop
                so the buttons w/in are all fullWidth, and the width of the container
                is either set to 100% (for desktop) or left at initial (mobile)
            */}
            <div className={styles.buttonWrapper}>
                <ContactDesignerModal tradeFirm={tradeFirm} />
            </div>
            <div className={styles.buttonWrapper}>
                <ProfileFollowRenderer firmUrlLabel={tradeFirm?.publicProfile?.urlLabel || ''} />
            </div>
        </div>
    );
};

export default ProfileFollowContact;
