// Flow types automatically removed

import { Component, Suspense, lazy } from 'react';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import getter from 'dibs-object-getter';
import GearIcon from 'dibs-icons/exports/legacy/GearFilled';
import { ItemTile } from 'dibs-search-product-tile/exports/ItemTile';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { getBuyerId, getUserType } from 'dibs-cookie-jar';
import { HeartWrapper } from 'dibs-portfolio-heart';
import { FolderWrapper } from 'dibs-portfolio-folder';
import FavoritesItemProvider from 'dibs-portfolio-folder/exports/FavoritesItemProvider';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { OverlaySpinner } from 'dibs-elements/exports/OverlaySpinner';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import {
    trackEvent,
    trackingConstants,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
    trackUserEventItemAddToFavorites,
    getUserEventItem,
} from 'dibs-tracking';
import { Button } from 'dibs-elements/exports/Button';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import { favoriteItemLogin } from '../../authInterface';
import { FavoritesProvider } from '../../shared/favorites/TradeFavoritesProvider';
import { loadAddToFolderModalWrapper } from '../../shared/loadAddToFolderModalWrapper';
import { logError } from '../../helpers';
import { Pagination } from '../../shared/Pagination';
import styles from './FavoritesWrapper.scss';
const ConnectFolderModal = lazy(() =>
    import(
        /* webpackChunkName: "ConnectFolderModal" */
        './ConnectFolderModal/ConnectFolderModal'
    )
);
import { trackEcomEvent } from '../../shared/helpers/tracking';
import { createProductImpressionTracking, trackItemTile } from '../helpers/designProfileTracking';
const { ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

const ConnectAnotherFolder = () => (
    <FormattedMessage
        id="abt.designProfile.FavoritesWrapper.connectAnotherFolder"
        defaultMessage="Connect Another Folder"
    />
);

const { EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;
const {
    STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
    STEP_INTERACTION_ITEM_FOLDER_CLICKED,
    STEP_INTERACTION_ITEM_ADDED,
    STEP_INTERACTION_ITEM_REMOVED,
} = stepInteractionConstants;
const folderTrackingHooks = {
    trackFolderClick(event = null) {
        const trigger = 'designer favorites';
        trackEvent(
            {
                category: INTERACTION_TYPE_ITEM_FAVORITING,
                action: STEP_INTERACTION_ITEM_FOLDER_CLICKED,
                label: trigger,
                eventName: EVENT_WISHLIST,
                interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
                step_interaction_name: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
                trigger,
            },
            event
        );
    },

    trackItemAdded() {
        const trigger = 'designer favorites';
        trackEvent(
            {
                category: INTERACTION_TYPE_ITEM_FAVORITING,
                action: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
                label: trigger,
                isInteractiveEvent: true,
                eventName: EVENT_WISHLIST,
                interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
                step_interaction_name: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
                trigger,
            },
            null
        );
    },
};

const trackHeart = wasAdded => {
    const step_interaction_name = wasAdded
        ? STEP_INTERACTION_ITEM_ADDED
        : STEP_INTERACTION_ITEM_REMOVED;
    const trigger = 'designer favorites';
    trackEvent({
        category: INTERACTION_TYPE_ITEM_FAVORITING,
        action: step_interaction_name,
        label: trigger,
        eventName: EVENT_WISHLIST,
        interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
        step_interaction_name: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
        trigger,
    });
};

export class FavoritesWrapperComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            isLoading: false,
            showConnectFolderModal: false,
            showEditMenu: false,
        };
    }

    getTradeFirm = () => {
        const { isEditMode, viewer } = this.props;
        const tradeFirmsKey = isEditMode ? 'tradeFirmsEditMode' : 'tradeFirmsDisplayMode';
        const [tradeFirm] = getter(viewer).value(tradeFirmsKey) || [];
        return {
            ...tradeFirm,
            members: (tradeFirm && tradeFirm.members) || [], // for flow
        };
    };
    refreshFavorites = () => {
        const { relay } = this.props;
        const isTrade = getUserType(document.cookie) === 'trade';

        if (relay) {
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    relay.refetch(
                        fragmentVariables => ({ ...fragmentVariables, isTrade }),
                        null,
                        error => {
                            if (error) {
                                logError(error);
                            }

                            this.setState({
                                isLoading: false,
                            });
                        },
                        {
                            force: true,
                        }
                    );
                }
            );
        }
    };
    handlePageChange = newPage => {
        if (this.props.relay) {
            this.setState(
                {
                    currentPage: newPage,
                    isLoading: true,
                },
                () => {
                    this.props.relay.refetch(
                        fragmentVariables => {
                            return { ...fragmentVariables, favoritesPage: newPage };
                        },
                        null,
                        error => {
                            if (error) {
                                logError(error);
                            }

                            this.setState({
                                isLoading: false,
                            });
                        },
                        {
                            force: true,
                        }
                    );
                }
            );
        }
    };
    launchFolderLogin = item => {
        return new Promise((resolve, reject) => {
            favoriteItemLogin({
                item,
                onSuccess: ({ userId }) => {
                    loadAddToFolderModalWrapper({
                        userId,
                    }).then(resolve);
                    this.refreshFavorites();
                },
                onError: reject,
                shouldPauseAuthFlow: state => state.step === 0,
            });
        });
    };
    launchHeartLogin = (item, cb) => {
        const onSuccess = () => {
            if (cb) {
                cb();
            }

            this.refreshFavorites();
        };

        favoriteItemLogin({
            item,
            onSuccess,
        });
    };
    onFolderSelection = () => {
        this.setState(
            {
                showConnectFolderModal: false,
            },
            this.refreshFavorites
        );
    };
    renderConnectFolderModal = () => {
        const tradeFirm = this.getTradeFirm();
        const firmMembers = (getter(tradeFirm).value('members') || []).map(member =>
            getter(member).value('userId')
        );
        const firmId = getter(tradeFirm).value('serviceId');
        return (
            <Suspense fallback={<OverlaySpinner />}>
                <ConnectFolderModal
                    onClose={() =>
                        this.setState({
                            showConnectFolderModal: false,
                        })
                    }
                    firmMembers={firmMembers}
                    firmId={firmId}
                    onFolderSelection={this.onFolderSelection}
                />
            </Suspense>
        );
    };
    renderEditUI = () => {
        const { showEditMenu } = this.state;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div
                className={styles.connectNewFolder}
                data-tn="open-edit-menu-button"
                onClick={() =>
                    this.setState({
                        showEditMenu: true,
                    })
                }
            >
                <GearIcon />
                {showEditMenu && (
                    <Tooltip
                        align="center"
                        closeOnOutsideClick
                        direction="left"
                        isVisible
                        onClose={() =>
                            this.setState({
                                showEditMenu: false,
                            })
                        }
                    >
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                        <div
                            className={styles.tooltipContent}
                            data-tn="show-connect-folder-modal-cta"
                            onClick={() => {
                                this.setState({
                                    showConnectFolderModal: true,
                                    showEditMenu: false,
                                });
                            }}
                        >
                            <ConnectAnotherFolder />
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    renderNoFolderView() {
        const { isEditMode } = this.props;

        if (!isEditMode) {
            return null;
        }

        return (
            <div className={styles.noItemsContainer}>
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.noItemsTitle}>
                            <FormattedMessage
                                id="abt.designProfile.FavoritesWrapper.noFolderTitle"
                                defaultMessage="Share Your Favorites"
                            />
                        </Heading>
                    )}
                </HeadingLevel>
                <div className={styles.noItemsContent}>
                    <div className={styles.noItemsMessage} data-tn="no-folder-message">
                        <FormattedMessage
                            id="abt.designProfile.FavoritesWrapper.noFolderMessage"
                            defaultMessage="Share your most loved 1stDibs finds by adding one of your Favorites folders to your Portfolio"
                        />
                    </div>
                    <div className={styles.connectDifferentFolderButton}>
                        <Button
                            dataTn="link-different-folder-button-no-folder"
                            onClick={() => {
                                this.setState({
                                    showConnectFolderModal: true,
                                    showEditMenu: false,
                                });
                            }}
                        >
                            <FormattedMessage
                                id="abt.designProfile.FavoritesWrapper.noFolderButton"
                                defaultMessage="add favorites"
                            />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderNoItemsView = () => {
        const { isEditMode } = this.props;
        const folderName =
            getter(this.getTradeFirm())
                .at('publicProfile')
                .at('portfoliosInflated')
                .at(0)
                .value('name') || '';
        const message = isEditMode ? (
            <FormattedMessage
                id="abt.designProfile.FavoritesWrapper.noItemsEditMode"
                defaultMessage='Folder "{folderName}" contains no items'
                values={{
                    folderName,
                }}
            />
        ) : (
            <FormattedMessage
                id="abt.designProfile.FavoritesWrapper.noItemsDisplayMode"
                defaultMessage="Folder contains no items"
            />
        );
        return (
            <div className={styles.noItemsContainer}>
                <div className={styles.noItemsContent}>
                    <div className={styles.noItemsMessage} data-tn="no-items-message">
                        {message}
                    </div>
                    {isEditMode && (
                        <div className={styles.connectDifferentFolderButton}>
                            <Button
                                dataTn="link-different-folder-button-no-items"
                                onClick={() => {
                                    this.setState({
                                        showConnectFolderModal: true,
                                        showEditMenu: false,
                                    });
                                }}
                            >
                                <ConnectAnotherFolder />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    getPortfolioInfo() {
        const [portfolio] =
            getter(this.getTradeFirm()).at('publicProfile').value('portfoliosInflated') || [];
        const portfolioItems = (getter(portfolio).at('items').value('edges') || [])
            .map(edge => getter(edge).at('node').value('item'))
            .filter(Boolean);
        return {
            portfolio,
            portfolioItems,
        };
    }

    render() {
        const { isEditMode, isTrade, relay, isMobile } = this.props;
        const { currentPage, isLoading, showConnectFolderModal } = this.state;

        if (!relay) {
            const messages = [
                'Tried to render FavoritesWrapper without relay and/or relay environment',
                `relay = ${JSON.stringify(relay)}`,
                relay ? `environment = ${JSON.stringify(relay.environment)}` : '',
            ];
            logError(messages.join('. '));
            return null;
        }

        const { portfolio, portfolioItems } = this.getPortfolioInfo();
        const numberOfPages = getter(portfolio).at('items').value('numberOfPages') || 0;
        const totalResults = getter(portfolio).at('items').value('totalResults') || 0;
        const itemIds = portfolioItems.map(item => getter(item).value('serviceId')).filter(Boolean);
        const userId = getBuyerId(document.cookie);
        let mainContent;

        if (!portfolio && isEditMode) {
            mainContent = this.renderNoFolderView();
        } else if (!portfolioItems.length) {
            mainContent = this.renderNoItemsView();
        } else {
            mainContent = (
                <FavoritesProvider
                    environment={getter(relay).value('environment')}
                    fetchFolder
                    fetchHeart
                    itemIds={itemIds}
                    userId={userId}
                >
                    {({ props: providedProps }) => {
                        return (
                            <QuickViewProvider>
                                {portfolioItems.filter(Boolean).map((item, index) => {
                                    const userEventItem = getUserEventItem({
                                        id: item.serviceId,
                                        amount: item.ecommerceTrackingParams?.price,
                                    });
                                    const getFavoritesComponent = ({
                                        isFolderVisible,
                                        showFolderPlusIcon,
                                        onFavorited,
                                        onUnFavorited,
                                        onFolderActionStart,
                                        onFolderActionEnd,
                                    }) => (
                                        <div className={styles.favoritesIconsWrap}>
                                            <div className={styles.favoritesIconContainer}>
                                                <HeartWrapper
                                                    authModalShow={cb =>
                                                        this.launchHeartLogin(item, cb)
                                                    }
                                                    itemId={item.serviceId}
                                                    onFavorited={() => {
                                                        trackHeart(true);
                                                        onFavorited?.();
                                                        if (userEventItem) {
                                                            trackUserEventItemAddToFavorites(
                                                                relay.environment,
                                                                [userEventItem]
                                                            );
                                                        }
                                                    }}
                                                    onUnFavorited={() => {
                                                        trackHeart(false);
                                                        onUnFavorited?.();
                                                    }}
                                                    userId={userId}
                                                    viewer={providedProps.viewer}
                                                    theme="dark"
                                                />
                                            </div>
                                            {!isMobile && isFolderVisible && (
                                                <div className={styles.favoritesIconContainer}>
                                                    <FolderWrapper
                                                        environment={relay.environment}
                                                        getModalComponent={
                                                            userId
                                                                ? loadAddToFolderModalWrapper
                                                                : this.launchFolderLogin
                                                        }
                                                        loadPortfolioItemData
                                                        primaryItemPk={item.serviceId}
                                                        selectedItemIds={[item.serviceId]}
                                                        shouldFillInFolder={!!userId}
                                                        singlePortfolioItem
                                                        userId={userId}
                                                        userIds={[userId]}
                                                        viewer={providedProps.viewer}
                                                        trackingHooks={folderTrackingHooks}
                                                        onAddItemSuccess={() => {
                                                            if (authModalLoader.isPaused()) {
                                                                authModalLoader.resume();
                                                            }

                                                            if (userEventItem) {
                                                                trackUserEventItemAddToFavorites(
                                                                    relay.environment,
                                                                    [userEventItem]
                                                                );
                                                            }

                                                            onFolderActionEnd?.();
                                                        }}
                                                        theme="dark"
                                                        showPlusIcon={showFolderPlusIcon}
                                                        onFolderSave={onFolderActionStart}
                                                        onFolderRemove={onFolderActionStart}
                                                        onNewFolderSave={onFolderActionStart}
                                                        onRemoveItemSuccess={onFolderActionEnd}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );

                                    return (
                                        <div className={styles.tileWrapper} key={item.serviceId}>
                                            <CurrencyProvider
                                                render={({ currency }) => (
                                                    <FavoritesItemProvider
                                                        item={item}
                                                        user={null}
                                                        userId={userId}
                                                        applyMouseEvents={!isMobile}
                                                    >
                                                        {favoritesItemProps => (
                                                            <ItemTile
                                                                index={index}
                                                                item={item}
                                                                itemsPerRow={4}
                                                                showSellerName={false}
                                                                showCreatorName={false}
                                                                currency={currency}
                                                                isMobile={isMobile}
                                                                onContentVisible={() => {
                                                                    trackEcomEvent({
                                                                        type: ECOM_PRODUCT_IMPRESSION,
                                                                        products:
                                                                            createProductImpressionTracking(
                                                                                {
                                                                                    isTrade,
                                                                                    items: [item],
                                                                                    positionNum:
                                                                                        index,
                                                                                }
                                                                            ),
                                                                        eventName:
                                                                            EVENT_VIEW_ITEM_LIST,
                                                                    });
                                                                }}
                                                                onClick={() => {
                                                                    const products =
                                                                        createProductImpressionTracking(
                                                                            {
                                                                                isTrade,
                                                                                items: [item],
                                                                                positionNum: index,
                                                                            }
                                                                        );

                                                                    if (products.length) {
                                                                        trackItemTile({
                                                                            ...products[0],
                                                                            eventName:
                                                                                EVENT_SELECT_ITEM,
                                                                        });
                                                                    }
                                                                }}
                                                                disableBorder
                                                                showMeasurements={false}
                                                                showMSKUSwatch={false}
                                                                viewer={null}
                                                                favoritesComponent={getFavoritesComponent(
                                                                    favoritesItemProps
                                                                )}
                                                            />
                                                        )}
                                                    </FavoritesItemProvider>
                                                )}
                                            />
                                        </div>
                                    );
                                })}
                            </QuickViewProvider>
                        );
                    }}
                </FavoritesProvider>
            );
        }

        return (
            <div className={`${styles.tilesContainer} rowFlex primary-page-width`}>
                {isEditMode && portfolio && this.renderEditUI()}
                {mainContent}
                <div className={styles.paginationRow}>
                    <Pagination
                        handlePaginate={this.handlePageChange}
                        numberOfPages={numberOfPages}
                        page={currentPage}
                        totalResults={totalResults}
                    />
                </div>
                {showConnectFolderModal && this.renderConnectFolderModal()}
                {isLoading && <OverlaySpinner />}
            </div>
        );
    }
}
export const FavoritesWrapper = createRefetchContainer(
    FavoritesWrapperComponent,
    {
        viewer: graphql`
            fragment FavoritesWrapper_viewer on Viewer {
                tradeFirmsDisplayMode: tradeFirms(urlLabel: $firmUrlLabel) @skip(if: $isEditMode) {
                    serviceId
                    publicProfile {
                        portfoliosInflated {
                            items(first: 60, page: $favoritesPage) {
                                numberOfPages
                                totalResults
                                edges {
                                    node {
                                        item {
                                            ...FavoritesItemProvider_item
                                            ...ItemTile_item @arguments(isTrade: $isTrade)
                                            serviceId
                                            firstPhotoWebPath(size: thumb)
                                            title
                                            seller {
                                                serviceId
                                            }
                                            isContemporary
                                            isCustomizable
                                            isNewAndMadeToOrder
                                            classification {
                                                categories {
                                                    category {
                                                        key
                                                        browseUrl
                                                    }
                                                }
                                            }
                                            ecommerceTrackingParams
                                            pricing {
                                                amount {
                                                    amount
                                                }
                                                netPrice {
                                                    amount
                                                }
                                                salePrice {
                                                    amount
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            name
                        }
                    }
                }
                tradeFirmsEditMode: tradeFirms(urlLabel: $firmUrlLabel) @include(if: $isEditMode) {
                    serviceId
                    members {
                        userId
                    }
                    publicProfile {
                        portfoliosInflated {
                            items(first: 60, page: $favoritesPage) {
                                numberOfPages
                                totalResults
                                edges {
                                    node {
                                        item {
                                            ...FavoritesItemProvider_item
                                            ...ItemTile_item @arguments(isTrade: $isTrade)
                                            serviceId
                                            firstPhotoWebPath(size: thumb)
                                            title
                                            seller {
                                                serviceId
                                            }
                                            isContemporary
                                            isCustomizable
                                            isNewAndMadeToOrder
                                            classification {
                                                categories {
                                                    category {
                                                        key
                                                        browseUrl
                                                    }
                                                }
                                            }
                                            ecommerceTrackingParams
                                            pricing {
                                                amount {
                                                    amount
                                                }
                                                netPrice {
                                                    amount
                                                }
                                                salePrice {
                                                    amount
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            name
                        }
                    }
                }
            }
        `,
    },
    graphql`
        query FavoritesWrapperRefetchQuery(
            $firmUrlLabel: String!
            $isEditMode: Boolean!
            $isTrade: Boolean!
            $favoritesPage: Int!
        ) {
            viewer {
                ...FavoritesWrapper_viewer
            }
        }
    `
);
