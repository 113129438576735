// Flow types automatically removed

import { Component } from 'react';
import { graphql } from 'react-relay/legacy';
import TradeSharedModifyPortfolioItems from './mutations/TradeSharedModifyPortfolioItems';
import { SharedParallelizedQueryRenderer } from '../SharedParallelizedQueryRenderer';
import { getQueryParam } from '../uriUtils';
export class FavoritesProvider extends Component {
    static defaultProps = {
        fetchHeart: true,
        fetchFolder: true,
        shouldAutoFavorite: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            variables: {
                userIds: [],
                selectedItemIds: [],
                loadPortfolioData: false,
                shouldFillInFolder: false,
            },
        };
    }

    componentDidMount() {
        if (this.shouldPerformAutoFavorite()) {
            this.handleAutoFavorite();
        } else if (this.props.userId) {
            this.setVariables();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.shouldPerformAutoFavorite(prevProps)) {
            this.handleAutoFavorite();
        } else if (
            (!prevProps.userId && this.props.userId) ||
            (this.props.userId && prevProps.itemIds.toString() !== this.props.itemIds.toString())
        ) {
            this.setVariables();
        }
    }

    shouldPerformAutoFavorite = prevProps => {
        const compareToProps = prevProps || {};
        return (
            this.props.shouldAutoFavorite &&
            getQueryParam(location.href, 'follow') === 'item' &&
            !compareToProps.userId &&
            this.props.userId
        );
    };
    handleAutoFavorite = () => {
        const { itemIds, environment, userId } = this.props;
        const atLeastOneItem = itemIds && itemIds.length;

        if (!environment || !userId || !atLeastOneItem) {
            return;
        }

        TradeSharedModifyPortfolioItems.commit(environment, {
            action: 'ADD',
            itemId: itemIds[0],
            portfolioType: 'HEART',
            userId,
            onCompleted: () => this.setVariables(),
        });
    };
    setVariables = () => {
        const { userId, itemIds } = this.props;
        this.setState({
            variables: {
                userIds: [userId],
                selectedItemIds: itemIds,
                loadPortfolioData: true,
                shouldFillInFolder: true,
            },
        });
    };

    render() {
        const { environment, renderVariables = {} } = this.props;

        if (!environment) {
            return null;
        }

        return (
            <SharedParallelizedQueryRenderer
                query={graphql`
                    query TradeFavoritesProviderQuery(
                        $loadPortfolioData: Boolean = false
                        $selectedItemIds: [String] = []
                        $userIds: [String] = []
                        $shouldFillInFolder: Boolean = false
                    ) {
                        viewer {
                            ...FolderWrapper_viewer
                                @arguments(
                                    selectedItemIds: $selectedItemIds
                                    shouldFillInFolder: $shouldFillInFolder
                                    userIds: $userIds
                                )
                            ...HeartWrapper_viewer
                                @arguments(
                                    loadPortfolioData: $loadPortfolioData
                                    selectedItemIds: $selectedItemIds
                                    userIds: $userIds
                                )
                        }
                    }
                `}
                environment={environment}
                variables={this.state.variables}
                renderVariables={renderVariables}
                render={({ data }) => {
                    const { viewer = null } = data || {};
                    const { userId, itemIds, disable } = this.props;
                    const userIds = userId ? [userId] : [];
                    const childrenProps = {
                        userId,
                        itemIds,
                        userIds,
                        disable,
                        location: this.props.location,
                        environment,
                        fetchHeart: this.props.fetchHeart,
                        fetchFolder: this.props.fetchFolder,
                        singlePortfolioItem: true,
                        loadPortfolioItemData: true,
                        selectedItemIds: itemIds,
                        loadPortfolioData: !!userId,
                        shouldFillInFolder: !!userId,
                        viewer,
                    };
                    return this.props.children({
                        props: childrenProps,
                    });
                }}
            />
        );
    }
}
