// Flow types automatically removed

import { Component } from 'react';
import { fetchQuery_DEPRECATED as fetchQuery } from 'react-relay/legacy';

class SharedParallelizedQueryRenderer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            data: {},
        };
        this.getData = this.getData.bind(this);
        this.fetchQueryInProgress = false;
        this.queuedFetchQueryVariables = null;
        this._relayContext = {
            environment: this.props.environment,
            variables: { ...(props.renderVariables || {}), ...(props.variables || {}) },
        };
    }

    getChildContext() {
        return {
            relay: this._relayContext,
        };
    }

    componentDidMount() {
        this.getData();
    }
    /**
     * NOTE: `componentDidUpdate` checks that the "instance" of the variables object in props passed has changed to
     * determine whether to fetch more data. Even if the values of the keys of the variables object are the same, if
     * the variables object is a different reference this will trigger a fetch.
     */

    componentDidUpdate(prevProps) {
        if (this.fetchQueryInProgress) {
            // Is there a fetchQuery that has not yet completed?
            if (prevProps.variables !== this.props.variables) {
                // If previous fetchQuery has not resolved but we detect change in variables, stash variables
                this.queuedFetchQueryVariables = this.props.variables;
            } else if (prevProps.variables !== this.props.variables) {
                // If there is a change in variables and there is no fetchQuery in progress, get moar data!
                this.getData();
            }
        } else if (
            // Are there queued variables from a previous update?
            // If queued variables are the same as those in props, get data
            this.queuedFetchQueryVariables &&
            this.queuedFetchQueryVariables === this.props.variables
        ) {
            this.queuedFetchQueryVariables = null;
            this.getData();
        } else if (
            // If queued variables are not same as those in props, assume queued variables are stale, get data not needed
            this.queuedFetchQueryVariables &&
            this.queuedFetchQueryVariables !== this.props.variables
        ) {
            this.queuedFetchQueryVariables = null;
        }
    }

    getData() {
        const { environment, query, variables } = this.props;
        this.fetchQueryInProgress = true;

        if (!environment) {
            return;
        }

        fetchQuery(environment, query, variables)
            .then(data => {
                this.fetchQueryInProgress = false;
                this._relayContext.variables = {
                    ...(this.props.renderVariables || {}),
                    ...(this.props.variables || {}),
                };
                this.setState({
                    data,
                });
            })
            .catch(() => {
                this.fetchQueryInProgress = false;
            });
    }

    render() {
        const { data } = this.state;
        return this.props.render({
            data,
        });
    }
}

export { SharedParallelizedQueryRenderer };
