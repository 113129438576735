import { type FC } from 'react';
import classNames from 'classnames';
import { useServerVars } from '../../../shared/ServerVarsContext';

import styles from './DesignProjectTileBgImage.scss';

type Props = {
    coverPhotoPath: string | null;
    size?: 'small' | 'medium' | 'large';
};

export const DesignProjectTileBgImage: FC<Props> = ({ coverPhotoPath, size = 'small' }) => {
    const { isMobile } = useServerVars();

    if (coverPhotoPath && coverPhotoPath.length) {
        coverPhotoPath = `${coverPhotoPath}${size === 'large' && !isMobile ? '' : '?width=594'}`; // iphone + model landscape (736px) * 2
    }
    const imageBgStyle = coverPhotoPath ? { backgroundImage: `url(${coverPhotoPath})` } : undefined;

    return <div className={classNames(styles.imageWrapper, styles[size])} style={imageBgStyle} />;
};
