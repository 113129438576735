import { type FC, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';

import { PROFILE_STATUS_ACTIVE } from '../constants';

import styles from './ProgressStatus.scss';

import { type ProgressStatus_tradeFirm$key } from './__generated__/ProgressStatus_tradeFirm.graphql';

const TOTAL_STEPS = 5;

const ProgressStatus: FC<{
    tradeFirm: ProgressStatus_tradeFirm$key;
}> = ({ tradeFirm: tradeFirmRef }) => {
    const tradeFirm = useFragment(
        graphql`
            fragment ProgressStatus_tradeFirm on TradeFirmType {
                serviceId
                profileStatus
                publicProfile {
                    urlLabel
                    about
                    splashPhoto
                    profilePhoto
                    designProjectsCover {
                        status
                    }
                    address {
                        country
                    }
                    email
                    portfolios {
                        serviceId
                    }
                }
            }
        `,
        tradeFirmRef
    );
    const percentageComplete = useMemo(() => {
        const steps: { [key: string]: boolean } = {
            profilePhoto: !!tradeFirm?.publicProfile?.profilePhoto,
            location: false,
            email: !!tradeFirm?.publicProfile?.email,
            about: false,
            projects:
                (tradeFirm?.publicProfile?.designProjectsCover || [])
                    .filter(filterNulls)
                    .filter(project => project.status === PROFILE_STATUS_ACTIVE).length >= 1,
        };

        let about = tradeFirm?.publicProfile?.about || '';
        try {
            about = JSON.parse(about);
        } catch (e) {
            /* swallow error -- about can be a plain string */
        }

        steps.about = about.length >= 1;

        steps.location = !!tradeFirm?.publicProfile?.address?.country;

        const completedSteps = Object.keys(steps).filter(key => steps[key]).length;

        return Math.round((completedSteps / TOTAL_STEPS) * 100);
    }, [tradeFirm]);

    // if the firm is 0% complete, fake a 1% completion rate so a bit of the progress bar is filled in
    const displayPercentage = percentageComplete || 1;

    return (
        <div className={styles.wrapper}>
            <div className={styles.topLine}>
                <div className={styles.label}>
                    <FormattedMessage
                        id="abt.designProfile.header.progress.label"
                        defaultMessage="Your Profile Progress"
                    />
                </div>
                <div className={styles.ticker}>
                    <FormattedMessage
                        id="abt.desginProfile.header.progress.percentageComplete"
                        defaultMessage="{percentageComplete}% Complete"
                        values={{ percentageComplete }}
                    />
                </div>
            </div>
            <div className={styles.graph}>
                <div className={styles.graphFill} style={{ width: `${displayPercentage}%` }} />
            </div>
            {tradeFirm?.profileStatus === PROFILE_STATUS_ACTIVE && (
                <div className={styles.viewLiveLink}>
                    <Link
                        dataTn="live-profile-link"
                        href={`/design-firms/${tradeFirm?.publicProfile?.urlLabel || ''}`}
                        target="_blank"
                    >
                        <FormattedMessage
                            id="abt.designProfile.header.progress.viewLive"
                            defaultMessage="View Live Profile"
                        />
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ProgressStatus;
