import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { localStorage } from 'dibs-browser-storage';
import CirclePlus from 'dibs-icons/exports/legacy/CirclePlus';
import classNames from 'classnames';
import Tooltip from 'dibs-coachmark-tooltip';
import { type NewProjectTile_tradeFirm$key } from './__generated__/NewProjectTile_tradeFirm.graphql';
import { HAS_SEEN_PROFILE_PROJECTS_TOOLTIP } from '../../constants';

import styles from './NewProjectTile.scss';

type Props = {
    createDesignProject: () => void;
    size?: 'small' | 'medium' | 'large';
    tradeFirm: NewProjectTile_tradeFirm$key | null | undefined;
};

export const NewProjectTile: FC<Props> = ({
    createDesignProject,
    size = 'small',
    tradeFirm: tradeFirmRef,
}) => {
    const tradeFirm = useFragment(
        graphql`
            fragment NewProjectTile_tradeFirm on TradeFirmType {
                publicProfile {
                    liveDesignProjectsCount: designProjects @include(if: $isEditMode) {
                        serviceId
                    }
                }
            }
        `,
        tradeFirmRef
    );
    const hasSeenProjectsTooltip = localStorage.getItem(HAS_SEEN_PROFILE_PROJECTS_TOOLTIP);
    const projectCount = (tradeFirm?.publicProfile?.liveDesignProjectsCount || []).length;
    return (
        <div className={classNames({ [styles.wrapper]: size === 'small' })}>
            <div
                className={classNames(styles.container, styles[size])}
                onKeyPress={createDesignProject}
                onClick={createDesignProject}
                data-tn="create-new-design-project"
                role="button"
                tabIndex={0}
            >
                <div className={styles.innerContainer}>
                    {size === 'large' && projectCount === 0 && !hasSeenProjectsTooltip && (
                        <Tooltip
                            onClose={() =>
                                localStorage.setItem(HAS_SEEN_PROFILE_PROJECTS_TOOLTIP, true)
                            }
                            title={
                                <FormattedMessage
                                    id="abt.designProfile.overview.projects.newTile.tooltipHeader"
                                    defaultMessage="Showcase Your Work"
                                />
                            }
                            align="right"
                            direction="top"
                        >
                            <FormattedMessage
                                id="abt.designProfile.overview.projects.newTile.tooltip"
                                defaultMessage="Submit professional photos of your most recent work and add relevant details to inspire our audience and your potential new clients - plus catch the eye of our editorial and social teams for potential coverage on 1stDibs."
                            />
                        </Tooltip>
                    )}
                    <CirclePlus
                        className={classNames(styles.addProjectIcon, {
                            [styles.large]: size !== 'small',
                        })}
                    />
                    <div className={styles.addProject}>
                        <FormattedMessage
                            id="abt.designProfile.NewProjectTile.addProjectCta"
                            defaultMessage="Add a new project"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
