/**
 * @generated SignedSource<<f62dc4d992d0fa5f148bb1aaacd592e5>>
 * @relayHash d987905f2d3a2e8179fbee0cbf6491fb
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/articleScraperQuery

import { ConcreteRequest } from 'relay-runtime';
export type articleScraperQuery$variables = {
  url: string;
};
export type articleScraperQuery$data = {
  readonly viewer: {
    readonly scrapePortfolioArticle: {
      readonly canonicalUrl: string | null;
      readonly image: string | null;
      readonly title: string | null;
    } | null;
  };
};
export type articleScraperQuery = {
  response: articleScraperQuery$data;
  variables: articleScraperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "url",
      "variableName": "url"
    }
  ],
  "concreteType": "ScrapedWebsiteContent",
  "kind": "LinkedField",
  "name": "scrapePortfolioArticle",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canonicalUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "articleScraperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "articleScraperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/articleScraperQuery",
    "metadata": {},
    "name": "articleScraperQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1ecefa5443668a3ca7cf936d3153c8c1";

export default node;
