/**
 * @generated SignedSource<<9893649f3d348fcb77219c394c1e1543>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesignProfileWrapper_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderRoot_viewer" | "OverviewRoot_viewer" | "ProjectsRoot_viewer">;
  readonly " $fragmentType": "DesignProfileWrapper_viewer";
};
export type DesignProfileWrapper_viewer$key = {
  readonly " $data"?: DesignProfileWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesignProfileWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesignProfileWrapper_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderRoot_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewRoot_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectsRoot_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "479f9cedcebdf1a077aaf89936d77dc8";

export default node;
