/**
 * @generated SignedSource<<0be0aaaee511feebc27c86e8f4dea6dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardStatusType = "ACTIVE" | "DELETED" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OverviewAwards_tradeFirm$data = {
  readonly publicProfile: {
    readonly awards: ReadonlyArray<{
      readonly name: string | null;
      readonly serviceId: string | null;
      readonly status: AwardStatusType | null;
    } | null> | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "OverviewAwards_tradeFirm";
};
export type OverviewAwards_tradeFirm$key = {
  readonly " $data"?: OverviewAwards_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewAwards_tradeFirm">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewAwards_tradeFirm",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmPublicProfileType",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AwardType",
          "kind": "LinkedField",
          "name": "awards",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};
})();

(node as any).hash = "e22ca3fb0d46abf3d63bcdb3d33db397";

export default node;
