// Flow types automatically removed

import { createRoot, hydrateRoot } from 'react-dom/client';
import { IntlProvider } from 'dibs-react-intl';
import { combineReducers, createStore } from 'redux';
import { initializeDBL } from 'dibs-buyer-layout/src/entries/index';
import dblReducers from 'dibs-buyer-layout/src/reducers';
import SV from 'server-vars';
import { getEnvironment } from '../relayModernEnvironment';
import { ServerVarsContextProvider } from '../shared/ServerVarsContext';
import { DesignProfileContainer } from '../designProfile/DesignProfileContainer';
const locale = SV.get('locale');
const isAdminMode = SV.get('isAdminMode');
const initialPage = SV.get('initialPage');
const firmUrlLabel = SV.get('firmUrlLabel');
const isEditMode = SV.get('isEditMode');
const isTrade = SV.get('userType') === 'trade';
const isMobile = SV.get('settings.isMobile');
const adminHost = SV.get('adminHost');

function createReduxStore(additionalReducers = {}, state = {}) {
    const reducers = combineReducers({ ...dblReducers, ...additionalReducers });
    return createStore(reducers, state);
}

new Promise(resolve => {
    if (isAdminMode) {
        return resolve({
            store: createReduxStore(),
        });
    }

    return resolve(initializeDBL());
}).then(({ store }) => {
    // Because the edit call requires a valid userToken, the standard `relayEnvironment` can't be used;
    // to populate the result of the edit SSR, use a different namespace to avoid clobbering the
    // existing `dbl.relayData` result (which will contain data for the header & footer, among other things)
    const environment = getEnvironment(isEditMode ? false : true);
    const designProfileDOMNode = document.getElementById('designProfilesContainer');
    const root = createRoot(designProfileDOMNode);
    const designProfile = (
        <ServerVarsContextProvider
            isMobile={SV.get('settings.isMobile')}
            isTablet={SV.get('settings.isTablet')}
            isDesktop={SV.get('settings.isDesktop')}
        >
            <DesignProfileContainer
                store={store}
                adminHost={adminHost}
                initialPage={initialPage}
                firmUrlLabel={firmUrlLabel}
                isEditMode={isEditMode}
                isAdminMode={isAdminMode}
                isTrade={isTrade}
                isMobile={isMobile}
                environment={environment}
            />
        </ServerVarsContextProvider>
    );

    if (designProfileDOMNode !== null) {
        if (isAdminMode) {
            root.render(
                <IntlProvider locale={locale} disableTranslations>
                    {designProfile}
                </IntlProvider>,
                designProfileDOMNode
            );
        } else {
            hydrateRoot(
                designProfileDOMNode,
                <IntlProvider locale={locale} disableTranslations>
                    {designProfile}
                </IntlProvider>
            );
        }
    }
});
