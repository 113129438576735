import commitMutation from 'relay-commit-mutation-promise';
import { graphql, type Environment } from 'react-relay/legacy';

import {
    type profileArticlesMutation as MutationType,
    type FirmDesignProfileArticleMutationInput,
    type profileArticlesMutation$data,
} from './__generated__/profileArticlesMutation.graphql';

const mutation = graphql`
    mutation profileArticlesMutation($input: FirmDesignProfileArticleMutationInput!) {
        firmDesignProfileArticleMutation(input: $input) {
            tradeFirm {
                publicProfile {
                    articles {
                        photo {
                            path
                            serviceId
                        }
                        title
                        description
                        webLink {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export const profileArticlesMutation = async (
    environment: Environment,
    variables: FirmDesignProfileArticleMutationInput
): Promise<profileArticlesMutation$data> => {
    return await commitMutation<MutationType>(environment, {
        mutation,
        variables: { input: variables },
    });
};
