import { type FC } from 'react';

import { Spinner } from 'dibs-elements/exports/Spinner';
import overlayStyles from './OverlaySpinnerAdditional.scss';

const Loader: FC = () => (
    <div className={overlayStyles.container}>
        <Spinner />
    </div>
);

export default Loader;
export { Loader };
