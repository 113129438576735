import { type FC, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';

import styles from './ProfileHeaderData.scss';

import { Header } from 'dibs-elements/exports/Header';
import GetHelpModalLazy from 'dibs-contact-1stdibs/exports/GetHelpModalLazy';

import ProfileIcon from './ProfileIcon';
import ProfileLocation from './ProfileLocation';
import ProfileTabs from './ProfileTabs';
import ProfileContactInfo from './ProfileContactInfo';
import ProgressStatus from './ProgressStatus';
import ProfileFollowContact from './ProfileFollowContact';

import { type ProfileHeaderData_tradeFirm$key } from './__generated__/ProfileHeaderData_tradeFirm.graphql';
import { type PageType } from '../constants';

type Props = {
    tradeFirm: ProfileHeaderData_tradeFirm$key;
    isEditMode: boolean;
    onChangeTab: (pageType: PageType) => void;
    currentPage: PageType;
};

export const ProfileHeaderData: FC<Props> = ({
    tradeFirm: tradeFirmRef,
    isEditMode,
    onChangeTab,
    currentPage,
}) => {
    const tradeFirm = useFragment(
        graphql`
            fragment ProfileHeaderData_tradeFirm on TradeFirmType {
                ...ProfileFollowContact_tradeFirm @skip(if: $isEditMode)
                ...ProgressStatus_tradeFirm @include(if: $isEditMode)
                ...ProfileLocation_tradeFirm
                ...ProfileTabs_tradeFirm
                ...ProfileContactInfo_tradeFirm
                ...ProfileIcon_tradeFirm
                name
            }
        `,
        tradeFirmRef
    );
    const [isModalOpen, setModalOpen] = useState(false);
    return (
        <>
            <div className={styles.wrapper}>
                <ProfileIcon isEditMode={isEditMode} tradeFirm={tradeFirm} />
                <div className={styles.profileDataWrapper}>
                    <div className={styles.nameWrapper}>
                        <div className={styles.name}>
                            <Header title={tradeFirm?.name} size="large" htmlElementType="h1" />
                        </div>
                        {isEditMode && (
                            <Link onClick={() => setModalOpen(true)} dataTn="update-name">
                                <FormattedMessage
                                    id="abt.designProfile.header.updateNameCta"
                                    defaultMessage="Update Name"
                                />
                                <GetHelpModalLazy
                                    isOpen={isModalOpen}
                                    onClose={() => setModalOpen(false)}
                                    topicValue="trade1stProgram"
                                    placement="tradeProfile"
                                />
                            </Link>
                        )}
                    </div>
                    <ProfileLocation tradeFirm={tradeFirm} isEditMode={isEditMode} />
                    {isEditMode && (
                        <ProfileContactInfo tradeFirm={tradeFirm} isEditMode={isEditMode} />
                    )}
                </div>
                <div className={styles.desktopSpacer} />
                {isEditMode ? (
                    <ProgressStatus tradeFirm={tradeFirm} />
                ) : (
                    <ProfileFollowContact isEditMode={isEditMode} tradeFirm={tradeFirm} />
                )}
            </div>
            <ProfileTabs
                isEditMode={isEditMode}
                onChangeTab={onChangeTab}
                currentTab={currentPage}
                tradeFirm={tradeFirm}
            />
        </>
    );
};

export default ProfileHeaderData;
