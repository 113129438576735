import { noticeError } from './shared/helpers/newrelic';

/**
 * @deprecated
 * refactor everything to use ./shared/helpers/logError - only doing this so tests pass
 * Log an error to NewRelic (if available); otherwise, log to console.error.
 * @param {Error | string} error
 * @param [string] message Optional prefix to console log.
 */

export function logError(error: Error | string, message?: string): void {
    if (typeof error === 'string') {
        error = new Error(error);
    }

    noticeError(error, message || 'Exception caught:');
}

export type DeepLinkHandlers = {
    onCallCompleted: () => void;
    registerDeepLink: (node: HTMLElement, moduleHash: string) => void;
};

type DeepLinkHandlerState = {
    hash: string;
    scrolled: boolean;
    elements: { [key: string]: HTMLElement };
    callCount: number;
    callsCompleted: number;
};

export function setupDeepLinkHandler(callCount: number): DeepLinkHandlers {
    const hash = window.location.hash;
    if (!hash) {
        return {
            onCallCompleted: () => {},
            registerDeepLink: () => {},
        };
    }

    const state: DeepLinkHandlerState = {
        hash,
        scrolled: false,
        elements: {},
        callCount,
        callsCompleted: 0,
    };

    return {
        onCallCompleted: () => {
            state.callsCompleted++;
            const node = state.elements[state.hash];

            if (node && !state.scrolled && state.callsCompleted >= state.callCount) {
                node.scrollIntoView();
                state.scrolled = true;
            }
        },
        registerDeepLink: (node: HTMLElement, moduleHash: string) => {
            state.elements[moduleHash] = node;
        },
    };
}

/**
 * GraphQL objects that we expect somewhere but came back null. Unexpected
 * because typically they're only marked as nullable because of generated
 * flow types
 */
type UnexpectedNullGraphQLObjectName = 'project' | 'tradeFirm';

/**
 * Checks if a value is null that is actually not expected to be null but was
 * generated as such from the GraphQL schema. Can check fields as well as
 * objects.
 */
export function logUnexpectedNullGraphQLError(
    objectName: UnexpectedNullGraphQLObjectName,
    fieldName: string | null
): void {
    if (fieldName) {
        logError(`Expected field "${fieldName}" on GraphQL object "${objectName}" to not be null`);
        return;
    }
    logError(`Expected GraphQL object "${objectName}", got null instead`);
}
