import { graphql } from 'react-relay';
import { type ProfileRootQuery as Type } from './__generated__/ProfileRootQuery.graphql';

export const ProfileRootQuery = graphql`
    query ProfileRootQuery(
        $firmUrlLabel: String!
        $shouldRenderOverview: Boolean!
        $shouldRenderProjects: Boolean!
        $isEditMode: Boolean!
    ) {
        viewer {
            ...DesignProfileWrapper_viewer
        }
    }
`;

export type ProfileRootQueryType = Type;
