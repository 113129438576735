/**
 * @generated SignedSource<<83bab274c8b825ee1882966e44800a88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewProjectTile_tradeFirm$data = {
  readonly publicProfile: {
    readonly liveDesignProjectsCount?: ReadonlyArray<{
      readonly serviceId: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "NewProjectTile_tradeFirm";
};
export type NewProjectTile_tradeFirm$key = {
  readonly " $data"?: NewProjectTile_tradeFirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewProjectTile_tradeFirm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEditMode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewProjectTile_tradeFirm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmPublicProfileType",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        {
          "condition": "isEditMode",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "liveDesignProjectsCount",
              "args": null,
              "concreteType": "designProject",
              "kind": "LinkedField",
              "name": "designProjects",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeFirmType",
  "abstractKey": null
};

(node as any).hash = "21e1a809047c5413d7bf2aa7ac83566c";

export default node;
