import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type InstagramProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const Instagram: FunctionComponent<InstagramProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'instagram'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
        >
            <path d="M125 22.5c33.4 0 37.3.1 50.5.7 12.2.6 18.8 2.6 23.2 4.3 5.8 2.3 10 5 14.4 9.4s7.1 8.5 9.4 14.4c1.7 4.4 3.7 11 4.3 23.2.6 13.2.7 17.1.7 50.5s-.1 37.3-.7 50.5c-.6 12.2-2.6 18.8-4.3 23.2-2.3 5.8-5 10-9.4 14.4s-8.5 7.1-14.4 9.4c-4.4 1.7-11 3.7-23.2 4.3-13.2.6-17.1.7-50.5.7s-37.3-.1-50.5-.7c-12.2-.6-18.8-2.6-23.2-4.3-5.8-2.3-10-5-14.4-9.4s-7.1-8.5-9.4-14.4c-1.7-4.4-3.7-11-4.3-23.2-.6-13.2-.7-17.1-.7-50.5s.1-37.3.7-50.5c.6-12.2 2.6-18.8 4.3-23.2 2.3-5.8 5-10 9.4-14.4s8.5-7.1 14.4-9.4c4.4-1.7 11-3.7 23.2-4.3 13.2-.6 17.1-.7 50.5-.7M125 0C91.1 0 86.8.1 73.5.8c-13.3.6-22.4 2.7-30.4 5.8C34.9 9.8 27.9 14 21 21 14 27.9 9.8 34.9 6.6 43.1c-3.1 8-5.2 17-5.8 30.3C.1 86.8 0 91.1 0 125c0 33.9.1 38.2.8 51.5.6 13.3 2.7 22.4 5.8 30.3C9.8 215.1 14 222.1 21 229c6.9 6.9 13.9 11.2 22.1 14.4 8 3.1 17 5.2 30.3 5.8 13.3.6 17.6.8 51.5.8s38.2-.1 51.5-.8c13.3-.6 22.4-2.7 30.3-5.8 8.2-3.2 15.2-7.5 22.1-14.4 6.9-6.9 11.2-13.9 14.4-22.1 3.1-8 5.2-17 5.8-30.3.6-13.3.8-17.6.8-51.5s-.1-38.2-.8-51.5c-.6-13.3-2.7-22.4-5.8-30.3-3-8.4-7.2-15.4-14.2-22.3-6.9-6.9-13.9-11.2-22.1-14.4-8-3.1-17-5.2-30.3-5.8C163.2.1 158.9 0 125 0z" />
            <path d="M125 60.8c-35.5 0-64.2 28.7-64.2 64.2s28.7 64.2 64.2 64.2 64.2-28.7 64.2-64.2-28.7-64.2-64.2-64.2zm0 105.9c-23 0-41.7-18.7-41.7-41.7S102 83.3 125 83.3s41.7 18.7 41.7 41.7-18.7 41.7-41.7 41.7z" />
            <circle cx="191.7" cy="58.3" r="15" />
        </svg>
    );
};
export default Instagram;
