/**
 * @generated SignedSource<<4307a5e849ac529dda2e66bf08b142eb>>
 * @relayHash 636cdf0c50b58df8876c6650789b941d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/profileWeblinksMutation

import { ConcreteRequest } from 'relay-runtime';
export type FirmDesignProfileWeblinksMutationInput = {
  clientMutationId?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  pinterest?: string | null;
  serviceId: string;
  website?: string | null;
};
export type profileWeblinksMutation$variables = {
  input: FirmDesignProfileWeblinksMutationInput;
};
export type profileWeblinksMutation$data = {
  readonly firmDesignProfileWeblinksMutation: {
    readonly tradeFirm: {
      readonly publicProfile: {
        readonly webLinks: {
          readonly facebook: string | null;
          readonly instagram: string | null;
          readonly pinterest: string | null;
          readonly website: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type profileWeblinksMutation = {
  response: profileWeblinksMutation$data;
  variables: profileWeblinksMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TradeFirmPublicProfileType",
  "kind": "LinkedField",
  "name": "publicProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebLinkType",
      "kind": "LinkedField",
      "name": "webLinks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebook",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instagram",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinterest",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileWeblinksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileWeblinksMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileWeblinksMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileWeblinksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileWeblinksMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileWeblinksMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/504.0.0-2025-04-01T14:44:32.787Z/profileWeblinksMutation",
    "metadata": {},
    "name": "profileWeblinksMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3ec3613f2ca5840f93342418bc819322";

export default node;
