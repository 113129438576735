import commitMutation from 'relay-commit-mutation-promise';
import { graphql, type Environment } from 'react-relay/legacy';
import { deleteDesignProjectUpdater } from './deleteDesignProjectUpdater';

import {
    type deleteDesignProjectMutation,
    type DeleteDesignProjectMutationInput,
    type deleteDesignProjectMutation$data,
} from './__generated__/deleteDesignProjectMutation.graphql';

type DeleteDesignProjectMutationArgs = DeleteDesignProjectMutationInput & {
    urlLabel: string;
};

const mutation = graphql`
    mutation deleteDesignProjectMutation($input: DeleteDesignProjectMutationInput!) {
        deleteDesignProjectMutation(input: $input) {
            projectId
        }
    }
`;

export const deleteDesignProject = async (
    environment: Environment,
    args: DeleteDesignProjectMutationArgs
): Promise<deleteDesignProjectMutation$data> => {
    return await commitMutation<deleteDesignProjectMutation>(environment, {
        updater: store => deleteDesignProjectUpdater(store, args),
        mutation,
        variables: {
            input: {
                projectId: args.projectId,
            },
        },
    });
};
