import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import classNames from 'classnames';

import ProfileHeaderBreadcrumbs from './Header/ProfileHeaderBreadcrumbs';
import ProfileHeaderData from './Header/ProfileHeaderData';

import { type HeaderRoot_viewer$key } from './__generated__/HeaderRoot_viewer.graphql';
import { type PageType } from './constants';

import styles from './HeaderRoot.scss';

type Props = {
    viewer: HeaderRoot_viewer$key;
    currentPage: PageType;
    onChangePage: (pageType: PageType) => void;
    isEditMode: boolean;
};

export const HeaderRoot: FC<Props> = ({
    viewer: viewerRef,
    currentPage,
    onChangePage,
    isEditMode,
}) => {
    const viewer = useFragment(
        graphql`
            fragment HeaderRoot_viewer on Viewer {
                tradeFirms(urlLabel: $firmUrlLabel) {
                    ...ProfileHeaderBreadcrumbs_tradeFirm
                    ...ProfileHeaderData_tradeFirm
                    serviceId
                    profileStatus
                    publicProfile {
                        profilePhotoId
                        designProjectsCount
                    }
                }
            }
        `,
        viewerRef
    );
    const [tradeFirm] = viewer?.tradeFirms || [];

    if (!tradeFirm) {
        return null;
    }

    return (
        <div className={classNames(styles.wrapper, { [styles.isEditMode]: isEditMode })}>
            <div className={styles.firmInfoContainer}>
                <div className={styles.headerContent}>
                    <ProfileHeaderBreadcrumbs tradeFirm={tradeFirm} />
                    <ProfileHeaderData
                        tradeFirm={tradeFirm}
                        isEditMode={isEditMode}
                        currentPage={currentPage}
                        onChangeTab={onChangePage}
                    />
                </div>
            </div>
        </div>
    );
};

export default HeaderRoot;
