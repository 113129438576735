// Flow types automatically removed

import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import getter from 'dibs-object-getter';
import { Input } from 'dibs-elements/exports/Input';
import { Spinner } from 'dibs-elements/exports/Spinner';
import Facebook from 'dibs-icons/exports/legacy/Facebook';
import Globe from 'dibs-icons/exports/legacy/Globe';
import Pinterest from 'dibs-icons/exports/legacy/Pinterest';
import Instagram from 'dibs-icons/exports/legacy/Instagram';
import { Link } from 'dibs-elements/exports/Link';
import Pen from 'dibs-icons/exports/legacy/Pen';
import { profileWeblinksMutation } from '../mutations/profileWeblinks';
import styles from './OverviewWeblinks.scss';
import { getWebPath } from '../helpers/getWebPath';
import {
    WEBLINK_FIELD_TYPE_FACEBOOK,
    WEBLINK_FIELD_TYPE_WEBSITE,
    WEBLINK_FIELD_TYPE_INSTAGRAM,
    WEBLINK_FIELD_TYPE_PINTEREST,
} from '../constants';
import classnames from 'classnames';
import { noticeError } from '../../shared/helpers/newrelic';
export class OverviewWeblinksComponent extends Component {
    constructor(props) {
        super(props);
        const weblinks = getter(props.tradeFirm).at('publicProfile').value('webLinks') || {};
        this.state = {
            website: weblinks.website,
            facebook: weblinks.facebook,
            pinterest: weblinks.pinterest,
            instagram: weblinks.instagram,
            isSaving: false,
            hasError: false,
            editField: null,
        };
        this.saveWeblinks = this.saveWeblinks.bind(this);
    }

    static getDerivedStateFromProps(props) {
        const weblinks = getter(props.tradeFirm).at('publicProfile').value('webLinks') || {};
        return {
            website: weblinks.website,
            facebook: weblinks.facebook,
            pinterest: weblinks.pinterest,
            instagram: weblinks.instagram,
        };
    }

    getIcon = fieldName => {
        let IconType = Globe;

        switch (fieldName) {
            case WEBLINK_FIELD_TYPE_FACEBOOK:
                IconType = Facebook;
                break;

            case WEBLINK_FIELD_TYPE_PINTEREST:
                IconType = Pinterest;
                break;

            case WEBLINK_FIELD_TYPE_INSTAGRAM:
                IconType = Instagram;
                break;

            case WEBLINK_FIELD_TYPE_WEBSITE:
            default:
                break;
        }

        return <IconType className={styles.icon} />;
    };
    getPlaceholder = fieldName => {
        switch (fieldName) {
            case WEBLINK_FIELD_TYPE_FACEBOOK:
                return (
                    <FormattedMessage
                        id="abt.overview.weblinks.placeholders.faceboo"
                        defaultMessage="Add your Facebook"
                    />
                );

            case WEBLINK_FIELD_TYPE_PINTEREST:
                return (
                    <FormattedMessage
                        id="abt.overview.weblinks.placeholders.pinterest"
                        defaultMessage="Add your Pinterest"
                    />
                );

            case WEBLINK_FIELD_TYPE_INSTAGRAM:
                return (
                    <FormattedMessage
                        id="abt.overview.weblinks.placeholders.instagram"
                        defaultMessage="Add your Instagram"
                    />
                );

            case WEBLINK_FIELD_TYPE_WEBSITE:
            default:
                return (
                    <FormattedMessage
                        id="abt.overview.weblinks.placeholders.website"
                        defaultMessage="Add your Website"
                    />
                );
        }
    };
    onStartEdit = fieldName => () => {
        // istanbul ignore else
        if (this.props.isEditMode && !this.state.editField) {
            this.setState({
                editField: fieldName,
            });
        }
    };
    onBlurWeblink = fieldName => event => {
        const value = event.target.value;
        this.setState(
            {
                [fieldName]: value,
            },
            () => {
                this.saveWeblinks(fieldName, value);
            }
        );
    };
    saveWeblinks = (editedField, newValue) => {
        // istanbul ignore next
        const webLinks = getter(this.props.tradeFirm).at('publicProfile').value('webLinks') || {
            facebook: '',
            pinterest: '',
            instagram: '',
            website: '',
        };

        const firmId = getter(this.props.tradeFirm).value('serviceId') || '';

        const output = Object.assign({}, webLinks, {
            [editedField]: newValue || '',
            firmId,
        });
        this.setState(
            {
                isSaving: true,
                hasError: false,
            },
            () => {
                if (!firmId) {
                    // placeholder
                    this.setState({
                        isSaving: false,
                        hasError: true,
                    });
                    return;
                }

                if (!this.props.relay) {
                    // placeholder
                    this.setState({
                        isSaving: false,
                        hasError: true,
                    });
                    return;
                }

                profileWeblinksMutation(this.props.relay.environment, output)
                    .then(() => {
                        this.setState({
                            isSaving: false,
                            hasError: false,
                            editField: null,
                        });
                    })
                    .catch(e => {
                        // istanbul ignore next
                        const errorString = `Error on saving ${editedField} with value "${
                            newValue || ''
                        }"`;

                        noticeError(e, errorString);
                        this.setState({
                            isSaving: false,
                            hasError: true,
                        });
                        return;
                    });
            }
        );
    };
    renderField = fieldName => {
        const icon = this.getIcon(fieldName);
        const rawPath = this.state[fieldName] || '';
        const path = getWebPath(rawPath, fieldName);

        if (!this.props.isEditMode && (!path || !path.length)) {
            return null;
        } else if (!this.props.isEditMode) {
            return (
                <Link href={path} className={styles.link} ariaLabel={fieldName}>
                    {icon}
                </Link>
            );
        } else if (this.state.editField === fieldName) {
            return (
                <div className={styles.editWrapper}>
                    <Input
                        disabled={this.state.isSaving}
                        dataTn={`overview-weblink-${fieldName}`}
                        defaultValue={rawPath}
                        size="medium"
                        autoFocus
                        onBlur={this.onBlurWeblink(fieldName)}
                        rightDecorator={this.state.isSaving ? <Spinner size="tiny" /> : ''}
                        hasError={this.state.hasError}
                    />
                </div>
            );
        }

        return (
            <div
                className={classnames(styles.link, styles.linkEdit, {
                    [styles.isPlaceholder]: !rawPath,
                })}
                onClick={this.onStartEdit(fieldName)}
            >
                {icon}
                <div className={styles.linkLabel} data-tn={`weblink-${fieldName}`}>
                    {rawPath || this.getPlaceholder(fieldName)}
                </div>
                <Pen className={styles.pen} />
            </div>
        );
    };

    render() {
        let itemWrapperClass = styles.itemWrapper;

        if (this.props.isEditMode) {
            itemWrapperClass = `${itemWrapperClass} ${styles.itemEditWrapper}`;
        }

        const webLinks = getter(this.props.tradeFirm).at('publicProfile').value('webLinks') || {};

        // special case -- do not display anything on the view page if there are no links
        if (
            !this.props.isEditMode &&
            !webLinks.website &&
            !webLinks.facebook &&
            !webLinks.pinterest &&
            !webLinks.instagram
        ) {
            return null;
        }

        return (
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <FormattedMessage id="abt.overview.weblinks.title" defaultMessage="Links" />
                </div>
                <div className={itemWrapperClass}>
                    {this.renderField(WEBLINK_FIELD_TYPE_WEBSITE)}
                    {this.renderField(WEBLINK_FIELD_TYPE_PINTEREST)}
                    {this.renderField(WEBLINK_FIELD_TYPE_FACEBOOK)}
                    {this.renderField(WEBLINK_FIELD_TYPE_INSTAGRAM)}
                </div>
            </div>
        );
    }
}
export const OverviewWeblinks = createFragmentContainer(OverviewWeblinksComponent, {
    tradeFirm: graphql`
        fragment OverviewWeblinks_tradeFirm on TradeFirmType {
            serviceId
            publicProfile {
                webLinks {
                    facebook
                    instagram
                    pinterest
                    website
                }
            }
        }
    `,
});
