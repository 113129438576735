// Flow types automatically removed

import styles from './ProfileLoader.scss';
import { Spinner } from 'dibs-elements/exports/Spinner';
export function Loader() {
    return (
        <div className={styles.wrapper}>
            <Spinner dark />
        </div>
    );
}
