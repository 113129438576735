export const loadingIntents = {
    /**
     * Marks the initial load. The reason it has it's own intent is so that we
     * know not to turn off the loading once the initial query is complete if the
     * user has a pending refetch to trigger.
     */
    INITIAL_LOAD: 'INITIAL_LOAD',

    /**
     * The intent that gets set when the intial load is complete and the user
     * has a pending refetch to trigger. This allows the component to know to
     * actually trigger that refetch.
     */
    PENDING_REFETCH: 'PENDING_REFETCH',

    /**
     * Default refetch intent, considers all the data to be invalidated and will
     * hide everything while loading
     */
    HARD_REFRESH: 'HARD_REFRESH',

    /**
     * Refetch intent for when changing pages. This will keep the pagination
     * visible, allowing pages to be changed in succession and not blocked
     */
    PAGE_CHANGE: 'PAGE_CHANGE',
};
