import { type FC, Fragment } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import styles from './DesignProjectTileDetails.scss';

type Props = {
    addressDisplayString: string | null;
    name: string | null;
};

export const DesignProjectTileDetails: FC<Props> = ({ addressDisplayString, name }) => {
    return (
        <Fragment>
            <p className={styles.projectName} data-tn="project-name">
                {name || (
                    <FormattedMessage
                        id="abt.designProfile.DesignProjectTileDetails.untitledLabel"
                        defaultMessage="Untitled"
                    />
                )}
            </p>
            <p className={styles.projectLocation} data-tn="project-address">
                {addressDisplayString || (
                    <FormattedMessage
                        id="abt.designProfile.DesignProjectTileDetails.noLocationProvidedLabel"
                        defaultMessage="No location provided"
                    />
                )}
            </p>
        </Fragment>
    );
};
