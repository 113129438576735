import { type FC } from 'react';
import PenIcon from 'dibs-icons/exports/legacy/Pen';

import styles from './Pen.scss';

const Pen: FC = () => {
    return <PenIcon className={styles.icon} />;
};

export default Pen;
